import { Notification, NotificationType } from '../models/notification';
import { UserRole } from '../models/user';
import { AppRoutePaths } from './enums';

export function notificationsRedirectTo(
  notification: Notification,
  userRole: UserRole,
) {
  let redirectUrl = '/home';
  const jsonData = notification?.data ?? {};

  if (notification.type === NotificationType.ART_ACTIVITY) {
    if (userRole === UserRole.FINAL_ART) {
      redirectUrl = `${AppRoutePaths.ART_ACTIVITY}`.replace(
        ':id',
        `${jsonData['activityId']}`,
      );
    }

    if (userRole === UserRole.VENDOR) {
      redirectUrl = `${AppRoutePaths.ACTIVITIES}`;
    }
  } else if (notification.type === NotificationType.LAYOUT) {
    if (userRole === UserRole.FINAL_ART) {
      redirectUrl = `${AppRoutePaths.ART_ACTIVITY}`.replace(
        ':id',
        `${jsonData['activityId']}`,
      );
    }

    if (userRole === UserRole.VENDOR) {
      redirectUrl = `${AppRoutePaths.ACTIVITY_LAYOUTS}`;
    }
  } else if (notification.type === NotificationType.PAYMENT_SLIP) {
    redirectUrl = `${AppRoutePaths.PAYMENT_SLIP_LIST}`;
  }

  return redirectUrl;
}
