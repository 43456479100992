import { NavLink, ThemeIcon } from '@mantine/core';
import { IconReceipt2 } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface PaymentSlipLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function PaymentSlipLink({
  navigate,
  themeColor,
}: PaymentSlipLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Comprov."
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="blue" variant="light">
          <IconReceipt2 size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Listagem"
        onClick={() => navigate(AppRoutePaths.PAYMENT_SLIP_LIST)}
      />
    </NavLink>
  );
}
