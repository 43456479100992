import {
  Button,
  Checkbox,
  Collapse,
  Flex,
  Group,
  MultiSelect,
  Paper,
  Select,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconFilter, IconFilterOff } from '@tabler/icons';
import { ReactNode } from 'react';
import { FormFilterType } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';
import { statusList } from '../../../utils/constants';
import {
  orderFinancialStatusList,
  orderPaymentTypeList,
} from '../../../utils/constants/order';
import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
} from '../../../utils/types/data/services/filters';

type OrderListPageFilterProps = {
  isCollapsed: boolean;
  formFilter: UseFormReturnType<
    FormFilterType,
    (values: FormFilterType) => FormFilterType
  >;
  customInputs?: () => ReactNode;
  handleSubmit(values: FormFilterType): void;
  companyList: GetFilterCompaniesListResponse[];
  serviceList: GetFilterServicesListResponse[];
  handleClear: () => void;
  showReworkFilter?: boolean;
};

type OrderListFilterButtonProps = {
  onClick: () => void;
  isCollapsed: boolean;
};

const FilterButton = ({
  isCollapsed,
  ...props
}: OrderListFilterButtonProps) => {
  return (
    <Button
      variant="light"
      color="blue"
      leftIcon={isCollapsed ? <IconFilterOff /> : <IconFilter />}
      {...props}
    >
      Filtros
    </Button>
  );
};

const OrderListPageFilter = ({
  isCollapsed,
  formFilter,
  handleSubmit,
  companyList,
  serviceList,
  handleClear,
  customInputs,
  showReworkFilter,
}: OrderListPageFilterProps) => {
  return (
    <Collapse in={isCollapsed}>
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} withBorder>
          <Flex wrap="wrap">
            {customInputs ? (
              customInputs()
            ) : (
              <>
                <TextInput
                  label="Identificador"
                  placeholder="código interno do pedido"
                  mb={16}
                  mr={8}
                  type="text"
                  name="internalNumber"
                  {...formFilter.getInputProps('internalNumber')}
                />
                <TextInput
                  label="OS"
                  placeholder="numero da OS"
                  mb={16}
                  mr={8}
                  type="text"
                  name="os"
                  {...formFilter.getInputProps('os')}
                />
                <TextInput
                  label="Produto"
                  placeholder="nome do produto"
                  mb={16}
                  mr={8}
                  type="text"
                  name="os"
                  {...formFilter.getInputProps('product')}
                />
                <Select
                  required
                  name="paymentType"
                  withAsterisk
                  label="Forma de pagamento"
                  placeholder="selecione uma forma de pagamento"
                  data={orderPaymentTypeList}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('paymentType')}
                />
                <Select
                  searchable
                  name="company"
                  label="Clientes"
                  placeholder="selecione o cliente"
                  data={companyList?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('company')}
                />
                <Select
                  searchable
                  clearable
                  name="service"
                  label="Serviço"
                  placeholder="selecione um serviço"
                  data={serviceList?.map((item) => ({
                    label: item.name,
                    value: String(item.id),
                  }))}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('service')}
                />
                <CustomDatePicker
                  placeholder="selecione uma data"
                  label="Data de Retirada"
                  lang="br"
                  name="receiptForecast"
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('receiptForecast')}
                />
                <CustomDatePicker
                  placeholder="selecione uma data"
                  label="Prev. de Finalização"
                  name="finishForecast"
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('finishForecast')}
                />
                <CustomDateRangePicker
                  allowSingleDateInRange
                  label="Criado em"
                  placeholder="selecione um intervalo"
                  mr={8}
                  {...formFilter.getInputProps('createdAt')}
                />
                <MultiSelect
                  maw={240}
                  clearable
                  searchable
                  name="status"
                  label="Status"
                  placeholder="selecione o status do pedido"
                  data={statusList}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('status')}
                />
                <MultiSelect
                  clearable
                  name="financialStatus"
                  label="Status Fiscal"
                  placeholder="selecione o status de fiscal"
                  data={orderFinancialStatusList}
                  mb={16}
                  mr={8}
                  {...formFilter.getInputProps('financialStatus')}
                />
              </>
            )}
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
            {showReworkFilter && (
              <Checkbox
                mt={30}
                label="Mostrar retrabalhos"
                checked={formFilter.values.showRework}
                {...formFilter.getInputProps('showRework')}
              />
            )}
          </Group>
        </Paper>
      </form>
    </Collapse>
  );
};

OrderListPageFilter.Button = FilterButton;

export default OrderListPageFilter;
