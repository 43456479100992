import {
  Badge,
  Button,
  Flex,
  Group,
  Menu,
  NumberInput,
  Paper,
  TextInput,
} from '@mantine/core';
import { IconBuilding, IconDotsVertical, IconEye } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { PageModalType } from '..';

import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { OrderMaterial } from '../../../models/order-material';
import {
  orderMaterialStatusColor,
  orderMaterialStatusHumanized,
} from '../../../utils/constants/order-material';
import { formatDateTime } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';

interface ReceptionistOrderMaterialListProps {
  formFilter: any;
  handleFilter(): Promise<void>;
  handleClearFilter(): Promise<void>;
  loading: boolean;
  page: React.MutableRefObject<number>;
  pageMeta: PageMeta | undefined;
  handlePaginate(newPage: number): void;
  orderMaterialList: OrderMaterial[];
  setSelectedOrderMaterial: React.Dispatch<
    React.SetStateAction<OrderMaterial | undefined>
  >;
  setPageModalVisible: React.Dispatch<React.SetStateAction<PageModalType>>;
  handleChangePageLimit(limit: number): void;
}

export function ReceptionistOrderMaterialPendingList({
  formFilter,
  handleClearFilter,
  handleFilter,
  handlePaginate,
  loading,
  orderMaterialList,
  page,
  pageMeta,
  setSelectedOrderMaterial,
  setPageModalVisible,
  handleChangePageLimit,
}: ReceptionistOrderMaterialListProps) {
  return (
    <>
      <Flex direction="column" w="99%">
        <form onSubmit={formFilter.onSubmit(() => handleFilter())}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="Código"
                placeholder="código da AR"
                mb={16}
                mr={8}
                type="text"
                name="code"
                {...formFilter.getInputProps('code')}
              />
              <TextInput
                label="Criado por"
                placeholder="criador da AR"
                mb={16}
                mr={8}
                type="text"
                name="username"
                {...formFilter.getInputProps('username')}
              />
              <TextInput
                label="Produto"
                placeholder="nome do produto"
                mb={16}
                mr={8}
                type="text"
                name="product"
                {...formFilter.getInputProps('product')}
              />
              <TextInput
                label="Cor"
                placeholder="cor do produto"
                mb={16}
                mr={8}
                type="text"
                name="productColor"
                {...formFilter.getInputProps('productColor')}
              />
              <TextInput
                label="Material"
                placeholder="material do produto"
                mb={16}
                mr={8}
                type="text"
                name="productMaterial"
                {...formFilter.getInputProps('productMaterial')}
              />
              <NumberInput
                label="Quantidade"
                placeholder="Qtde"
                w={100}
                min={0}
                mb={16}
                mr={8}
                type="number"
                name="quantity"
                {...formFilter.getInputProps('quantity')}
              />
              <NumberInput
                label="Volumes"
                placeholder="Qtde"
                w={100}
                min={0}
                mb={16}
                mr={8}
                type="number"
                name="quantityVolume"
                {...formFilter.getInputProps('quantityVolume')}
              />
              <CustomDatePicker
                placeholder="selecione uma data"
                label="Data de recebimento"
                lang="br"
                name="receivedAt"
                mb={16}
                mr={8}
                {...formFilter.getInputProps('receivedAt')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={handleClearFilter}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
            </Group>
          </Paper>
        </form>
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 300, 500]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          fetching={loading}
          minHeight={500}
          noRecordsText="Sem usuários"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={page.current}
          totalRecords={pageMeta?.totalItems}
          recordsPerPage={pageMeta?.itemsPerPage ?? 10}
          onPageChange={handlePaginate}
          records={orderMaterialList}
          columns={[
            {
              accessor: 'code',
              title: 'Código',
            },
            {
              accessor: 'username',
              title: 'Criado por',
            },
            {
              accessor: '',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'quantity',
              title: 'Quantidade',
            },
            {
              accessor: 'quantityVolume',
              title: 'Volumes',
            },
            {
              accessor: 'company.name',
              title: 'Cliente',
            },
            {
              accessor: 'receivedAt',
              title: 'Recebido em',
              render: ({ receivedAt }) => formatDateTime(receivedAt ?? ''),
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderMaterialStatusColor[status]}>
                  {orderMaterialStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'menu',
              title: '',
              render: (orderMaterialItem) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => {
                        setPageModalVisible('order-material-view');
                        setSelectedOrderMaterial(orderMaterialItem);
                      }}
                      icon={<IconEye size={14} />}
                    >
                      Ver
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setPageModalVisible('order-company-associate');
                        setSelectedOrderMaterial(orderMaterialItem);
                      }}
                      icon={<IconBuilding size={14} />}
                    >
                      Associar cliente
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
    </>
  );
}
