import { showNotification } from '@mantine/notifications';

type MantineNotificationParams = {
  title: string;
  message: string;
};

export function successNotification(params: MantineNotificationParams) {
  showNotification({
    title: params.title,
    message: params.message,
    color: 'green',
  });
}

export function errorNotification(params: MantineNotificationParams) {
  showNotification({
    title: params.title,
    message: params.message,
    color: 'red',
  });
}
