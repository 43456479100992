/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Grid,
  Modal,
  ScrollArea,
  Select,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconRefreshDot, IconTestPipe2 } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';

import { CustomDateTimePicker } from '../../components/CustoDateTimePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigate } from '../../components/CustomRouter';
import { OrderDetails } from '../../components/OrderDetails';
import { Page } from '../../components/Page';
import { useReleaseToProduction } from '../../data/hooks/orders';
import { Order, OrderStatus } from '../../models/order';
import { UserRole, UserType } from '../../models/user';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  getOrdersLatePaginatedDispatcher,
  setOrderDispatchmentDispatcher,
  setOrderFinishDispatcher,
  setOrderPausedDispatcher,
  setOrderReceivementDispatcher,
  updateOrderStatusDispatcher,
} from '../../store/orders';
import { statusList, statusOrder } from '../../utils/constants';
import { AdminHomeWeb } from './admin/web';
import { VendorHomeWeb } from './vendor/web';

export type FormFilterType = {
  company?: number | undefined;
  limit: number;
  page: number;
  services?: string | null;
};

export function OrderLateList() {
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.orders,
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const currentPageLimit = useRef(10);
  const currentPage = useRef(1);
  const dispatch = useDispatch<any>();

  const [orderViewModalVisible, setOrderViewModalVisible] = useState(false);
  const [changeStatusModalVisible, setChangeStatusModalVisible] =
    useState(false);

  const {
    fetch: releaseToProductionFetcher,
    loading: releaseToProductionLoader,
  } = useReleaseToProduction();

  const formStatusChange = useForm({
    initialValues: {
      status: '',
      quantityVolume: '',
      description: '',
      carrierName: '',
      incorrectQuantity: '',
      isInconrrect: false,
      finishVendorForecast: new Date(),
    },
  });

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      services: undefined,
      page: 1,
      company: undefined,
    },
  });

  function handleSubmit() {
    currentPage.current = 1;
    getOrderPaginated();
  }

  function handleClearFilter() {
    Object.entries(formFilter.values).forEach(([key]) => {
      if (key !== 'page' && key !== 'limit') {
        formFilter.setFieldValue(key, '');
      }
    });
    currentPage.current = 1;
    currentPageLimit.current = 10;
    getOrderPaginated(true);
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getOrderPaginated();
  }

  async function getOrderPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(
        getOrdersLatePaginatedDispatcher({
          page: 1,
          limit: 10,
        }),
      );
    } else {
      dispatch(
        getOrdersLatePaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
        }),
      );
    }
  }

  async function handleReleaseToProduction() {
    if (selectedOrder) {
      await releaseToProductionFetcher({
        id: selectedOrder?.id,
        data: {
          finishVendorForecast: formStatusChange.values.finishVendorForecast,
        },
        onSuccess: () => {
          formStatusChange.reset();
          getOrderPaginated();
          setChangeStatusModalVisible(false);
        },
      });
    }
  }

  function handleSubmitChangeStatus(values: typeof formStatusChange.values) {
    if (!selectedOrder) return;

    if (
      values.status === OrderStatus.RELEASED_PRODUCTION &&
      Number(selectedOrder.price) <= 0
    ) {
      errorNotification({
        title: 'Ops!',
        message: 'não é possível liberar produção sem Preço (total gravação).',
      });
      return;
    }

    if (
      values.status === OrderStatus.RELEASED_PRODUCTION &&
      selectedOrder.paymentType === null
    ) {
      errorNotification({
        title: 'não é possível liberar produção sem forma de pagamento.',
        message: 'edite o pedido',
      });
      return;
    }

    if (
      values.status === OrderStatus.RELEASED_PRODUCTION &&
      (selectedOrder.paymentType === 'virtual' ||
        selectedOrder.paymentType === 'service_sample')
    ) {
      errorNotification({
        title: 'não é possível liberar produção de virtual ou amostra.',
        message: 'fale com a gerencia',
      });
      return;
    }

    if (values.status === OrderStatus.PAUSED) {
      dispatch(
        setOrderPausedDispatcher(selectedOrder.id, {
          description: values.description,
        }),
      );
      formStatusChange.reset();
      setChangeStatusModalVisible(false);
      return;
    }

    if (
      values.status &&
      statusOrder[values.status as OrderStatus] >
        statusOrder[selectedOrder.status]
    ) {
      if (values.status === OrderStatus.FINISHED) {
        dispatch(
          setOrderFinishDispatcher(selectedOrder.id, {
            quantityVolume: Number(values.quantityVolume),
          }),
        );
      } else if (values.status === OrderStatus.MATERIAL_RECEIVED) {
        dispatch(
          setOrderReceivementDispatcher(selectedOrder.id, {
            carrierName: values.carrierName,
            quantityVolume: Number(values.quantityVolume),
            incorrectQuantity: Number(values.incorrectQuantity),
            orderPreviusQuantity: Number(selectedOrder.quantity),
          }),
        );
      } else if (values.status === OrderStatus.DISPATCHED) {
        dispatch(
          setOrderDispatchmentDispatcher(selectedOrder.id, {
            carrierName: values.carrierName,
            quantityVolume: Number(values.quantityVolume),
          }),
        );
      } else if (values.status === OrderStatus.RELEASED_PRODUCTION) {
        handleReleaseToProduction();
      } else {
        dispatch(
          updateOrderStatusDispatcher(selectedOrder.id, {
            status: values.status as OrderStatus,
          }),
        );
      }
      formStatusChange.reset();
      setChangeStatusModalVisible(false);
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'você nao pode alterar para um status anterior ao atual',
      });
    }
  }

  function handleChangeOrderStatus(order: Order) {
    setSelectedOrder(order);
    setChangeStatusModalVisible(true);
  }

  useEffect(() => {
    getOrderPaginated();
  }, []);

  return (
    <Page title="Pedidos [Pendência Financeira]">
      <CustomLoader loading={loading || releaseToProductionLoader} />
      {user?.role === UserRole.VENDOR && (
        <VendorHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
          handleChangeOrderStatus={handleChangeOrderStatus}
        />
      )}
      {user?.role === UserRole.FINANCIAL && (
        <VendorHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
          handleChangeOrderStatus={handleChangeOrderStatus}
        />
      )}
      {user?.type === UserType.MASTER && (
        <AdminHomeWeb
          handleChangePageLimit={handleChangePageLimit}
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          navigation={rootNavigate as NavigateFunction}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
          handleChangeOrderStatus={handleChangeOrderStatus}
        />
      )}
      <Modal
        opened={orderViewModalVisible}
        onClose={() => setOrderViewModalVisible(false)}
        title={
          <Text fw="bold">
            Detalhes do Pedido
            {selectedOrder?.rework && user?.role !== UserRole.COMPANY ? (
              <ThemeIcon ml={10} color="grape" variant="light">
                <IconRefreshDot size={24} />
              </ThemeIcon>
            ) : (
              ''
            )}
            {selectedOrder?.isSample && (
              <ThemeIcon ml={10} color="green" variant="light">
                <IconTestPipe2 size={24} />
              </ThemeIcon>
            )}
          </Text>
        }
        size="lg"
      >
        <ScrollArea h={600}>
          {selectedOrder && (
            <OrderDetails
              sections={[
                'basic',
                'prices',
                'service-details',
                'historic',
                'dates',
                'payment',
                'order-receipt-files',
              ]}
              orderId={selectedOrder?.id}
            />
          )}
        </ScrollArea>
      </Modal>
      <Modal
        opened={changeStatusModalVisible}
        onClose={() => setChangeStatusModalVisible(false)}
        title="Alterar status do pedido"
        size={
          formStatusChange.values.status === OrderStatus.RELEASED_PRODUCTION
            ? 'lg'
            : 'md'
        }
      >
        <form
          onSubmit={formStatusChange.onSubmit((values) =>
            handleSubmitChangeStatus(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <Select
                required
                withAsterisk
                name="status"
                label="Status"
                placeholder="selecione o novo status do pedido"
                data={
                  user?.role === UserRole.VENDOR
                    ? statusList.filter((obj) => obj.value != 'finished')
                    : statusList
                }
                mb={16}
                {...formStatusChange.getInputProps('status')}
              />
            </Grid.Col>
            {formStatusChange.values.status === OrderStatus.FINISHED && (
              <Grid.Col span={1}>
                <TextInput
                  withAsterisk
                  required
                  label="Volumes"
                  placeholder="digite os volumes"
                  mb={16}
                  type="number"
                  name="quantityVolume"
                  {...formStatusChange.getInputProps('quantityVolume')}
                />
              </Grid.Col>
            )}
            {formStatusChange.values.status === OrderStatus.PAUSED && (
              <Grid.Col span={2} mb={16}>
                <Textarea
                  maxLength={255}
                  withAsterisk
                  required
                  label="Observações"
                  placeholder="escreva aqui qualquer observação sobre o status pausado"
                  name="description"
                  {...formStatusChange.getInputProps('description')}
                />
              </Grid.Col>
            )}
          </Grid>
          {formStatusChange.values.status === OrderStatus.MATERIAL_RECEIVED && (
            <Grid gutter="xs" columns={2}>
              <Grid.Col span={2}>
                <TextInput
                  withAsterisk
                  required
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  {...formStatusChange.getInputProps('carrierName')}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Checkbox
                  label="Quantidade esta incorreta"
                  color="green"
                  name="isInconrrect"
                  mb={16}
                  {...formStatusChange.getInputProps('isInconrrect')}
                />
              </Grid.Col>
              {formStatusChange.values.isInconrrect && (
                <>
                  <Grid.Col span={1}>
                    <TextInput
                      required
                      withAsterisk
                      label="Quantidade"
                      placeholder="digite a quantidade"
                      mb={16}
                      type="number"
                      name="incorrectQuantity"
                      {...formStatusChange.getInputProps('incorrectQuantity')}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <TextInput
                      required
                      withAsterisk
                      label="Volumes"
                      placeholder="digite os volumes"
                      mb={16}
                      type="number"
                      name="quantityVolume"
                      {...formStatusChange.getInputProps('quantityVolume')}
                    />
                  </Grid.Col>
                </>
              )}
            </Grid>
          )}
          {formStatusChange.values.status === OrderStatus.DISPATCHED && (
            <Grid gutter="xs" columns={2}>
              <Grid.Col span={2}>
                <TextInput
                  required
                  withAsterisk
                  label="Portador"
                  placeholder="nome do portador"
                  mb={16}
                  type="text"
                  name="carrierName"
                  {...formStatusChange.getInputProps('carrierName')}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <TextInput
                  withAsterisk
                  required
                  label="Volumes"
                  placeholder="digite os volumes"
                  mb={16}
                  type="number"
                  name="quantityVolume"
                  {...formStatusChange.getInputProps('quantityVolume')}
                />
              </Grid.Col>
            </Grid>
          )}
          {formStatusChange.values.status ===
            OrderStatus.RELEASED_PRODUCTION && (
            <>
              <Grid gutter="xs" columns={2}>
                <Grid.Col span={1}>
                  <CustomDateTimePicker
                    date={formStatusChange.values.finishVendorForecast}
                    label="Prazo de finalização"
                    onChange={(date) =>
                      formStatusChange.setValues({ finishVendorForecast: date })
                    }
                  />
                </Grid.Col>
              </Grid>
              <Card withBorder mb={16}>
                <ScrollArea h={400}>
                  <Text fw="bold" mb={16}>
                    Confirme as informações abaixo antes de liberar para
                    produção:{' '}
                  </Text>
                  {selectedOrder && (
                    <OrderDetails
                      sections={['basic', 'prices', 'service-details']}
                      orderId={selectedOrder?.id}
                    />
                  )}
                </ScrollArea>
              </Card>
            </>
          )}
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
    </Page>
  );
}
