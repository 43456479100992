/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Accordion,
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Text,
  Textarea,
  ThemeIcon,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconArrowLeft,
  IconClock,
  IconHistory,
  IconMessage,
  IconPlayerPlay,
  IconPlayerStop,
} from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ActivityLayoutHistoryItem,
  ActivityLayoutItem,
} from '../../components/ActivityLayout';
import { CustomLoader } from '../../components/CustomLoader';
import { CustomMultipleFileInput } from '../../components/CustomMutipleFileInput';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { OrderDetails } from '../../components/OrderDetails';
import { Page } from '../../components/Page';
import {
  useAddActivityLayoutFile,
  useCreateActivityLayout,
  useGetActivityLayout,
  useGetActivityLayoutFile,
} from '../../data/hooks/activity-layouts';
import { useListOptions } from '../../data/hooks/options';
import {
  finishActivityRequest,
  showActivityRequest,
} from '../../data/services/activities';
import {
  createArtActivityRequest,
  getArtActivityListRequest,
  getUserLasArtActivityRequest,
  setArtActivityClockoutRequest,
} from '../../data/services/art-activities';
import { uploadMultipleFilesRequest } from '../../data/services/files';

import { getOrderRequest } from '../../data/services/orders';
import { useTimer } from '../../hooks/use-timer';
import {
  Activity,
  ActivityComplexity,
  ActivityStatus,
} from '../../models/activity';
import {
  ActivityLayout,
  ActivityLayoutStatus,
} from '../../models/activity-layout';
import {
  ArtProductionType,
  PopulatedArtActivity,
} from '../../models/art-activity';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Order } from '../../models/order';
import { UserRole } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  activityComplexityColor,
  activityComplexityHumanized,
  activityStatusColor,
  activityStatusHumanized,
} from '../../utils/constants/activity';

import { artActivityTypeHumanized } from '../../utils/constants/art-activity';
import { secondsToTime, translateServerHttpErrors } from '../../utils/helpers';

export function ArtProduction() {
  const { id } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const productionForm = useRef<HTMLFormElement>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [lastLayout, setLastLayout] = useState<ActivityLayout>();
  const [pageModalVisible, setPageModalVisible] = useState(false);
  const [lastProduction, setLastProduction] = useState<PopulatedArtActivity>();
  const [isOnProgress, setisOnProgress] = useState(false);
  const [activity, setActivity] = useState<Activity>();
  const [order, setOrder] = useState<Order>();
  const [productionList, setProductionList] = useState<PopulatedArtActivity[]>(
    [],
  );

  const {
    fetch: listStepsOptionsFetcher,
    reponseData: listStepsOptionsData,
    loading: listStepsOptionsLoader,
  } = useListOptions();
  const {
    fetch: createActivityLayoutFetcher,
    loading: createActivityLayoutLoader,
  } = useCreateActivityLayout();
  const {
    fetch: addActivityLayoutFileFetcher,
    loading: addActivityLayoutFileLoader,
  } = useAddActivityLayoutFile();
  const {
    fetch: getActivityLayoutFetcher,
    loading: getActivityLayoutLoader,
    reponseData: getActivityLayoutData,
  } = useGetActivityLayout();
  const {
    fetch: getActivityLayoutFilesFetcher,
    loading: getActivityLayoutFilesLoader,
    reponseData: getActivityLayoutFilesData,
  } = useGetActivityLayoutFile();

  const { days, hours, minutes, seconds } = useTimer({
    deadline: lastProduction?.clockIn ?? '',
  });

  const formProduction = useForm({
    initialValues: {
      type: '',
      complexity: '',
      description: '',
    },
  });

  const formOrderLayout = useForm({
    initialValues: {
      files: [],
    },
  });

  const isAllowedToProduction =
    user?.role === UserRole.FINAL_ART &&
    activity?.status !== ActivityStatus.DONE;

  async function handleSubmit(values: typeof formProduction.values) {
    if (values.type === 'finish') {
      try {
        setPageLoading(true);
        await finishActivityRequest(Number(id), {
          complexity: values.complexity as ActivityComplexity,
        });
        getActivityData();
        setPageLoading(false);
      } catch (error) {
        setPageLoading(false);
        errorNotification({
          title: 'Erro ao finalizar atividade.',
          message: 'tente novamente',
        });
      }
    } else {
      try {
        setPageLoading(true);
        const response = await createArtActivityRequest(Number(id), {
          type: values.type as ArtProductionType,
          description: values.description,
        });
        setPageLoading(false);
        setisOnProgress(true);
        setLastProduction({ ...response, clockOut: null });
        setProductionList([...productionList, { ...response, clockOut: null }]);
        getActivityData();
      } catch (error: any) {
        setPageLoading(false);
        setisOnProgress(false);
        errorNotification({
          title: 'Erro ao atualizar atividade.',
          message: translateServerHttpErrors(error, 'tente novamente'),
        });
      }
    }
  }

  async function handleStartProduction() {
    productionForm.current?.requestSubmit();
  }

  async function handleStopProduction() {
    if (!isOnProgress || !lastProduction) return;

    try {
      setPageLoading(true);
      await setArtActivityClockoutRequest(lastProduction.id);
      setPageLoading(false);
      setisOnProgress(false);
      formProduction.reset();
      await getArtActivityData();
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao encerrar etapa.',
        message: 'tente novamente',
      });
    }
  }

  async function getActivityData() {
    try {
      setPageLoading(true);
      const response = await showActivityRequest(Number(id));
      const orderResponse = await getOrderRequest(response.order);

      setActivity(response);
      setOrder(orderResponse);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar informações.',
        message: 'tente novamente',
      });
    }
  }

  async function getArtActivityData() {
    try {
      setPageLoading(true);
      const lastArtActivityResponse = await getUserLasArtActivityRequest(
        Number(id),
      );
      const artActivityListResponse = await getArtActivityListRequest(
        Number(id),
      );

      if (
        lastArtActivityResponse &&
        lastArtActivityResponse.clockOut === null
      ) {
        setisOnProgress(true);
        formProduction.setFieldValue('type', lastArtActivityResponse.type);
      }
      setProductionList(artActivityListResponse);
      setLastProduction(lastArtActivityResponse);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar informações complementares.',
        message: 'tente novamente',
      });
    }
  }

  async function getLayouts() {
    await getActivityLayoutFetcher({ activityId: Number(id) });
  }

  async function getLastLayoutFiles(id: number) {
    await getActivityLayoutFilesFetcher({ query: { activityLayout: id } });
  }

  async function handleSubmitOrderLayout(
    values: typeof formOrderLayout.values,
  ) {
    if (values.files.length) {
      setPageLoading(true);
      const fileIds = await uploadMultipleFilesRequest({
        files: values.files,
      });
      setPageLoading(false);

      await createActivityLayoutFetcher({
        activityId: Number(id),
        onSuccess: async (res) => {
          await addActivityLayoutFileFetcher({
            id: res.id,
            data: {
              files: fileIds,
            },
            onSuccess: () => {
              getLayouts();
              setPageModalVisible(false);
              formOrderLayout.reset();
              getActivityData();
            },
          });
        },
      });
    }
  }

  async function getStepsOptions() {
    await listStepsOptionsFetcher({
      query: {
        type: OptionTypes.ART_PRODUCTION,
        subtype: OptionSubTypes.STEPS,
      },
    });
  }

  useEffect(() => {
    getActivityData();
    getArtActivityData();
    getStepsOptions();
    getLayouts();
  }, [id]);

  useEffect(() => {
    if (getActivityLayoutData?.length) {
      const last = getActivityLayoutData[getActivityLayoutData?.length - 1];
      setLastLayout(last);
      getLastLayoutFiles(last.id);
    }
  }, [getActivityLayoutData?.length]);

  return (
    <Page title="Andamento de atividade">
      <CustomLoader
        loading={
          pageLoading ||
          listStepsOptionsLoader ||
          createActivityLayoutLoader ||
          addActivityLayoutFileLoader ||
          getActivityLayoutLoader ||
          getActivityLayoutFilesLoader
        }
      />
      <Grid gutter="xs" columns={5} w="82vw">
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center">
            <Badge
              color={activity?.status && activityStatusColor[activity.status]}
            >
              {activity?.status && activityStatusHumanized[activity.status]}
            </Badge>
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid gutter="xs" columns={6} w="82vw" style={{ position: 'relative' }}>
        <Grid.Col span={3}>
          <Paper p={8} mb={16} withBorder>
            <Grid m={8} gutter="xs" columns={2}>
              <Grid.Col mt={8} mb={8} span={2}>
                <Text color="gray">Info da atividade</Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex direction="column">
                  <Text fw="bold">Cliente</Text>
                  <Text color="gray">{order?.company.name ?? '--'}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Complexidade</Text>
                  <Badge
                    w={150}
                    color={
                      activity?.complexity &&
                      activityComplexityColor[activity?.complexity]
                    }
                  >
                    {activity?.complexity &&
                      activityComplexityHumanized[activity?.complexity]}
                  </Badge>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Prioridade</Text>
                  <Text color="gray">
                    {activity?.isPrioritary ? `sim` : `não`}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Criado por</Text>
                  <Text color="gray">
                    {activity?.createdBy && activity.createdBy}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Criado em</Text>
                  <Text color="gray">
                    {activity?.createdAt &&
                      formatLocale(activity.createdAt, 'DD/MM/YY')}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex direction="column">
                  <Text fw="bold">Observações</Text>
                  <Text color="gray">{activity?.obs ?? '--'}</Text>
                </Flex>
              </Grid.Col>
            </Grid>
          </Paper>
          <Paper p={8} withBorder>
            {order && (
              <OrderDetails
                orderId={order.id}
                sections={['basic', 'order-files', 'service-details']}
              />
            )}
          </Paper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Paper
            style={{ overflowY: 'scroll' }}
            pos="fixed"
            right="1vw"
            w={510}
            mah="75vh"
            p={16}
            bg="gray.3"
            shadow="md"
          >
            <Text fw="bold">Controle de Andamento</Text>
            <form
              ref={productionForm}
              onSubmit={formProduction.onSubmit((values) =>
                handleSubmit(values),
              )}
            >
              {isAllowedToProduction && (
                <>
                  <Select
                    w={170}
                    disabled={isOnProgress}
                    required
                    withAsterisk
                    name="type"
                    label="Etapa"
                    placeholder="selecione a etapa"
                    data={
                      listStepsOptionsData?.map((option) => ({
                        label: option.name,
                        value: option.value,
                      })) ?? []
                    }
                    mb={16}
                    mr={8}
                    {...formProduction.getInputProps('type')}
                  />
                  {formProduction.values.type === 'finish' ? (
                    <>
                      <Select
                        w={190}
                        required
                        withAsterisk
                        name="complexity"
                        label="Complexidade"
                        placeholder="avalie a complexidade"
                        data={[
                          {
                            label: 'Baixa',
                            value: 'low',
                          },
                          {
                            label: 'Alta',
                            value: 'high',
                          },
                        ]}
                        mb={16}
                        mr={8}
                        {...formProduction.getInputProps('complexity')}
                      />
                      <Button type="submit">Salvar</Button>
                    </>
                  ) : formProduction.values.type === 'pause' ? (
                    <>
                      <Flex>
                        <Textarea
                          maxLength={255}
                          disabled={isOnProgress}
                          w="100%"
                          h={110}
                          label="Motivo"
                          placeholder="descreva o mótivo da pausa"
                          name="obs"
                          required
                          withAsterisk
                          value={formProduction.values.description}
                          {...formProduction.getInputProps('description')}
                        />
                      </Flex>
                      <Flex align="center">
                        {lastProduction?.clockOut === null ? (
                          <Title mr={24} color="dark.3">
                            {days > 0 && `${days} ${days > 1 ? 'dias' : 'dia'}`}
                            {` ${hours}:${minutes}:${seconds}`}
                          </Title>
                        ) : (
                          <Title mr={24} color="dark.3">
                            00:00:00
                          </Title>
                        )}

                        <Button
                          onClick={() => handleStartProduction()}
                          disabled={isOnProgress}
                          p={0}
                          w={36}
                          h={36}
                          type="button"
                          mb={-8}
                          radius={18}
                          mr={8}
                        >
                          <IconPlayerPlay color="white" size={26} />
                        </Button>
                        <Button
                          onClick={() => handleStopProduction()}
                          disabled={!isOnProgress}
                          color="red"
                          p={0}
                          w={36}
                          h={36}
                          type="button"
                          mb={-8}
                          radius={18}
                        >
                          <IconPlayerStop color="white" size={26} />
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <Flex align="center">
                      {lastProduction?.clockOut === null ? (
                        <Title mr={24} color="dark.3">
                          {days > 0 && `${days} ${days > 1 ? 'dias' : 'dia'}`}
                          {` ${hours}:${minutes}:${seconds}`}
                        </Title>
                      ) : (
                        <Title mr={24} color="dark.3">
                          00:00:00
                        </Title>
                      )}
                      <Button
                        onClick={() => handleStartProduction()}
                        disabled={isOnProgress}
                        p={0}
                        w={36}
                        h={36}
                        type="button"
                        mb={-8}
                        radius={18}
                        mr={8}
                      >
                        <IconPlayerPlay color="white" size={26} />
                      </Button>
                      <Button
                        onClick={() => handleStopProduction()}
                        disabled={!isOnProgress}
                        color="red"
                        p={0}
                        w={36}
                        h={36}
                        type="button"
                        mb={-8}
                        radius={18}
                      >
                        <IconPlayerStop color="white" size={26} />
                      </Button>
                    </Flex>
                  )}
                </>
              )}
            </form>
            {lastLayout && (
              <ActivityLayoutItem
                activityLayout={lastLayout}
                files={getActivityLayoutFilesData}
              />
            )}
            {user?.role === UserRole.FINAL_ART &&
              (!lastLayout ||
                lastLayout?.status === ActivityLayoutStatus.REPROVED) && (
                <Button
                  mb={16}
                  mt={16}
                  color="dark"
                  type="button"
                  onClick={() => setPageModalVisible(true)}
                >
                  Enviar Layout
                </Button>
              )}
            <Divider m={8} />
            <ScrollArea mt={8} h={120}>
              {productionList?.reverse().map((prodItem, index) => {
                const { hours, minutes, seconds } = secondsToTime(
                  prodItem?.timeAmount,
                );

                return (
                  <Flex key={index} mt={8} mb={8} align={'center'}>
                    <ThemeIcon p={4} mr={8} color="green" variant="light">
                      <IconClock size={26} />
                    </ThemeIcon>
                    <Text mr={8}>
                      {formatLocale(prodItem.clockIn, 'DD/MM/YY')}
                    </Text>
                    <Text mr={8}>
                      {artActivityTypeHumanized[prodItem.type]} -
                    </Text>
                    <Text
                      maw={160}
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                      mr={8}
                    >
                      {prodItem.artUser.username}
                    </Text>
                    <Badge>
                      {prodItem.clockOut !== null &&
                        `${hours}h ${minutes}m ${seconds}s`}
                    </Badge>
                    {prodItem.description && (
                      <Tooltip
                        maw={200}
                        multiline
                        label={prodItem.description}
                        withArrow
                      >
                        <Button
                          ml={'xs'}
                          variant="subtle"
                          compact
                          p={0}
                          children={<IconMessage />}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                );
              })}
            </ScrollArea>

            <Card withBorder>
              <Accordion variant="default" transitionDuration={200}>
                <Accordion.Item value="photos">
                  <Accordion.Control
                    icon={<IconHistory size={30} color="orange" />}
                  >
                    Histórico de Layouts
                  </Accordion.Control>
                  <Accordion.Panel>
                    {getActivityLayoutData?.map((item) => (
                      <ActivityLayoutHistoryItem activityLayout={item} />
                    ))}
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Card>
          </Paper>
        </Grid.Col>
      </Grid>
      <Modal
        opened={pageModalVisible}
        onClose={() => setPageModalVisible(false)}
        title="Enviar Layout"
      >
        <form
          onSubmit={formOrderLayout.onSubmit((values) =>
            handleSubmitOrderLayout(values),
          )}
        >
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <CustomMultipleFileInput
                required
                withAsterisk
                name="files"
                accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.txt,.ps,.bin"
                label="Arquivos"
                placeholder="selecione arquivo por vez"
                formRef={formOrderLayout}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Enviar
            </Button>
          </Flex>
        </form>
      </Modal>
    </Page>
  );
}
