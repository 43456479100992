import {
  Button,
  Flex,
  Group,
  Modal,
  ScrollArea,
  Select,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';

import { Page } from '../../components/Page';
import { useDeleteOrderMaterial } from '../../data/hooks/order-materials';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import {
  associateOrderMaterialToComapanyRequest,
  OrderMaterialsPaginatedParams,
  paginatedOrderMaterialsRequest,
  validateOrderMaterialRequest,
} from '../../data/services/order-material';
import { MaterialStatus, OrderMaterial } from '../../models/order-material';
import { UserRole, UserType } from '../../models/user';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';

import { PageMeta } from '../../utils/types';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';
import { ReceptionistOrderMaterialList } from './receptionist';
import { VendorOrderMaterialListList } from './vendor';
import { OrderMaterialDetails } from '../OrderMaterialDetails';
import { FormAssociateCompany } from '../OrderMaterialListPending';
import { translateServerHttpErrors } from '../../utils/helpers';

export type FormFilterType = OrderMaterialsPaginatedParams;
export type PageModalType =
  | 'order-associate'
  | 'oie'
  | 'order-material-delete'
  | 'order-material-view'
  | 'order-company-associate'
  | null;
export type FormOIEType = {
  productCondition: string;
  packageCondition: string;
  note: string;
  status: string;
};

export function OrderMaterialList() {
  const { user } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [printIsVisible, setPrintIsVisible] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<OrderMaterial>();
  const [pageModalVisible, setPageModalVisible] = useState<PageModalType>(null);
  const [orderMaterialList, setOrderMaterialList] = useState<OrderMaterial[]>(
    [],
  );
  const [selectedOrderMaterial, setSelectedOrderMaterial] =
    useState<OrderMaterial>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const page = useRef(1);
  const pageLimit = useRef(10);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const {
    fetch: deleteOrderMaterialFetcher,
    loading: deleteOrderMaterialLoader,
  } = useDeleteOrderMaterial();

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: page.current,
      code: '',
      company: '',
      product: '',
      productColor: '',
      productMaterial: '',
      username: '',
      quantity: undefined,
      quantityVolume: undefined,
      receivedAt: null as any,
      showAssociates: false,
    },
  });

  const formOIE = useForm<FormOIEType>({
    initialValues: {
      productCondition: '',
      packageCondition: '',
      note: '',
      status: '',
    },
  });

  const formAssociateCompany = useForm<FormAssociateCompany>({
    initialValues: {
      companyId: '',
    },
  });

  function handlePaginate(newPage: number) {
    page.current = newPage;
    getOrderMaterial(false);
  }

  function handleSetOIE(orderMaterial: OrderMaterial) {
    setSelectedMaterial(orderMaterial);
    setPageModalVisible('oie');
    formOIE.setValues({ note: orderMaterial.note });
  }

  function setOrderMaterialToDelete(orderMaterial: OrderMaterial) {
    setSelectedMaterial(orderMaterial);
    setPageModalVisible('order-material-delete');
  }

  function handleChangePageLimit(limit: number) {
    pageLimit.current = limit;
    getOrderMaterial();
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getOrderMaterial(isReset = true) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setLoading(true);
      const response = await paginatedOrderMaterialsRequest({
        ...(isReset ? {} : validatedFilter),
        limit: pageLimit.current ?? 10,
        page: page.current ?? 1,
      });
      setOrderMaterialList(response.items);
      setPageMeta(response.meta);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao buscar ARs!',
        message: 'tente novamente.',
      });
    }
  }

  async function handleFilter() {
    getOrderMaterial(false);
  }

  async function handleClearFilter() {
    page.current = 1;
    pageLimit.current = 10;
    formFilter.reset();
    getOrderMaterial();
  }

  async function handleSubmiteValidate(values: typeof formOIE.values) {
    try {
      setLoading(true);
      await validateOrderMaterialRequest(Number(selectedMaterial?.id), {
        ...values,
        status: values.status as MaterialStatus,
      });
      formOIE.reset();
      getOrderMaterial();
      setLoading(false);
      setPageModalVisible(null);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao validar AR',
        message: 'tente novamente.',
      });
    }
  }

  async function handleDeleteOrderMaterial(id: number) {
    await deleteOrderMaterialFetcher({
      id,
      onSuccess: () => {
        setSelectedMaterial(undefined);
        setPageModalVisible(null);
        getOrderMaterial();
      },
    });
  }

  async function handleAssociateCompany(
    values: typeof formAssociateCompany.values,
  ) {
    if (!selectedOrderMaterial) {
      return;
    }
    try {
      setLoading(true);
      await associateOrderMaterialToComapanyRequest(
        Number(selectedOrderMaterial.id),
        {
          companyId: Number(values.companyId),
        },
      );
      setLoading(false);
      getOrderMaterial(false);
      formAssociateCompany.reset();
      setSelectedOrderMaterial(undefined);
      setPageModalVisible(null);
    } catch (error: any) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao atualizar AR.',
        message: translateServerHttpErrors(error, 'tente novamente'),
      });
    }
  }

  useEffect(() => {
    getOrderMaterial();
    getCompanies();
  }, []);

  return (
    <Page title="ARs">
      <CustomLoader loading={deleteOrderMaterialLoader} />
      {user?.role === UserRole.RECEPTIONIST && (
        <ReceptionistOrderMaterialList
          companyList={companyList}
          formFilter={formFilter}
          formOIE={formOIE}
          handleClearFilter={handleClearFilter}
          handleFilter={handleFilter}
          handlePaginate={handlePaginate}
          handleSetOIE={handleSetOIE}
          handleSubmiteValidate={handleSubmiteValidate}
          loading={loading}
          orderMaterialList={orderMaterialList}
          page={page}
          pageMeta={pageMeta}
          pageModalVisible={pageModalVisible}
          printIsVisible={printIsVisible}
          selectedMaterial={selectedMaterial}
          selectedOrderMaterial={selectedOrderMaterial}
          setPageModalVisible={setPageModalVisible}
          setPrintIsVisible={setPrintIsVisible}
          setSelectedOrderMaterial={setSelectedOrderMaterial}
          setOrderMaterialToDelete={setOrderMaterialToDelete}
          handleChangePageLimit={handleChangePageLimit}
        />
      )}
      {user?.role === UserRole.VENDOR && (
        <VendorOrderMaterialListList
          companyList={companyList}
          formFilter={formFilter}
          handleClearFilter={handleClearFilter}
          handleFilter={handleFilter}
          handlePaginate={handlePaginate}
          loading={loading}
          orderMaterialList={orderMaterialList}
          page={page}
          pageMeta={pageMeta}
          handleChangePageLimit={handleChangePageLimit}
          setSelectedOrderMaterial={setSelectedOrderMaterial}
          setPageModalVisible={setPageModalVisible}
        />
      )}
      {user?.type === UserType.MASTER && (
        <VendorOrderMaterialListList
          companyList={companyList}
          formFilter={formFilter}
          handleClearFilter={handleClearFilter}
          handleFilter={handleFilter}
          handlePaginate={handlePaginate}
          loading={loading}
          orderMaterialList={orderMaterialList}
          page={page}
          pageMeta={pageMeta}
          handleChangePageLimit={handleChangePageLimit}
          setSelectedOrderMaterial={setSelectedOrderMaterial}
          setPageModalVisible={setPageModalVisible}
        />
      )}
      <Modal
        size={500}
        opened={pageModalVisible === 'order-material-delete'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Excluir AR</Text>}
      >
        <Text>Deseja exluir a AR com Código: {selectedMaterial?.code} ?</Text>
        <Group mt={16}>
          <Button
            onClick={() =>
              selectedMaterial && handleDeleteOrderMaterial(selectedMaterial.id)
            }
            color="green"
            type="submit"
          >
            Sim
          </Button>
          <Button
            onClick={() => {
              setSelectedMaterial(undefined);
              setPageModalVisible(null);
            }}
            color="red"
            type="submit"
          >
            Não
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={pageModalVisible === 'order-material-view'}
        onClose={() => setPageModalVisible(null)}
        title="Detalhes da AR"
        size="lg"
      >
        <ScrollArea h={600}>
          <OrderMaterialDetails
            orderMaterialIdProps={selectedOrderMaterial?.id}
          />
        </ScrollArea>
      </Modal>
      <Modal
        opened={pageModalVisible === 'order-company-associate'}
        onClose={() => {
          setSelectedOrderMaterial(undefined);
          formAssociateCompany.reset();
          setPageModalVisible(null);
        }}
        title="Associar cliente"
        size="xs"
      >
        <Flex direction={'column'}>
          <form
            onSubmit={formAssociateCompany.onSubmit((values) =>
              handleAssociateCompany(values),
            )}
          >
            <Select
              required
              withAsterisk
              searchable
              name="companyId"
              label="Cliente"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formAssociateCompany.getInputProps('companyId')}
            />
            <Group position="right">
              <Button type="submit">Salvar</Button>
            </Group>
          </form>
        </Flex>
      </Modal>
    </Page>
  );
}
