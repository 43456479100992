import { Checkbox, Group, NumberInput, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useSelector } from 'react-redux';
import { OrderStatus } from '../../models/order';
import { UserRole, UserType } from '../../models/user';
import { RootState } from '../../providers/store';
import { statusOrder } from '../../utils/constants';
import { formatBRL, realToUSCash } from '../../utils/helpers';

interface SilkscreenFormItemProps {
  form: UseFormReturnType<any>;
  orderStatus?: OrderStatus;
}

export function SilkscreenEditItem({
  form,
  orderStatus,
}: SilkscreenFormItemProps) {
  const { user } = useSelector((state: RootState) => state.auth);
  const disableEdit = orderStatus
    ? statusOrder[orderStatus] >= statusOrder['produced']
    : undefined;

  const changeColorPrice =
    Number(realToUSCash(String(form.values.changeColorPrice))) || 0;
  return (
    <>
      <Group grow>
        <NumberInput
          disabled={disableEdit && user?.type !== UserType.MASTER}
          required
          withAsterisk
          label="Cores"
          placeholder="quantidade de cores"
          mb={16}
          min={0}
          name="colorsAmount"
          {...form.getInputProps('details.colorsAmount')}
        />
      </Group>
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        value={form.values.details.personalizationSides}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
      {user?.role !== UserRole.COMPANY && (
        <>
          <Checkbox
            label="Precisa de troca de cor?"
            description="(Selecione se este pedido precisa de troca de cores)"
            color="green"
            name="needChangeColor"
            mt={16}
            mb={16}
            w={300}
            checked={form.values.details.needChangeColor}
            value={form.values.details.needChangeColor}
            {...form.getInputProps('details.needChangeColor')}
            disabled={disableEdit && user?.type !== UserType.MASTER}
          />
          {form.values.details.needChangeColor ? (
            <Group grow>
              <TextInput
                disabled={disableEdit && user?.type !== UserType.MASTER}
                withAsterisk
                required
                label="Preço Unitário troca de cor"
                placeholder="$$$$"
                mb={16}
                type="text"
                name="samplePrice"
                value={formatBRL(form.values.changeColorPrice ?? '')}
                onChange={(e) =>
                  form.setValues({
                    changeColorPrice: formatBRL(e.target.value),
                  })
                }
              />
              <div>
                <Text>Preço Total troca de cor: </Text>
                <Text color="gray">
                  {formatBRL(
                    (
                      changeColorPrice * form.values.details.colorsAmount
                    ).toFixed(2) ?? '0',
                  )}
                </Text>
              </div>
            </Group>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
}
