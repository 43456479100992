import { Company } from './company';

export enum MaterialStatus {
  INITIAL = 'initial',
  APPROVED = 'approved',
  REPROVED = 'reproved',
  ASSOCIATED = 'associated',
}

export type OrderMaterial = {
  id: number;
  code: string;
  company?: Company;
  order?: number;
  product: string;
  productMaterial: string;
  productColor: string;
  quantity: number;
  quantityVolume: number;
  note?: string;
  status: MaterialStatus;
  productCondition?: string;
  packageCondition?: string;
  receivedAt?: string;
  username: string;
  hasNf: boolean;
  nfNumber?: string;
  createdAt: string;
  arrivedBy?: string;
  associatedAt?: string;
  associatedBy?: string;
};
