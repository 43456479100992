/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  Table,
  Text,
  TextInput,
  Textarea,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import {
  IconArchive,
  IconCalendarStats,
  IconCertificate,
  IconCheck,
  IconDotsVertical,
  IconEye,
  IconFile,
  IconReceipt,
  IconRefreshDot,
  IconTestPipe2,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { DateModalState, FormFilterType } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';
import { CustomLoader } from '../../../components/CustomLoader';
import { useGetCompanyDocuments } from '../../../data/hooks/company-documents';
import { useGetNfProviders } from '../../../data/hooks/financial';
import {
  getFilterCompaniesListRequest,
  getFilterServicesListRequest,
  getFilterTeamsListRequest,
} from '../../../data/services/filters';
import { emmitNFSeRequest } from '../../../data/services/financial';
import { generateOrderReceiptRequest } from '../../../data/services/order-receipt';
import {
  paginatedOrdersRequest,
  setOrderFinishedReceptionRequest,
} from '../../../data/services/orders';
import {
  FinancialStatus,
  Order,
  Order as OrderModel,
} from '../../../models/order';
import { TeamType } from '../../../models/team';
import { dayjsPlugins, formatLocale } from '../../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../../providers/mantine-notifications';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusList,
} from '../../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
  orderFinancialStatusList,
  orderPaymentTypeHumanized,
  orderPaymentTypeList,
  priceTypeHumanized,
} from '../../../utils/constants/order';
import { receiptInstalments } from '../../../utils/constants/receipt';
import {
  formatBRL,
  getNFSEDiscrimination,
  getOrderPrices,
  realToUSCash,
  translateServerHttpErrors,
} from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import { UseValidationPaymentSlipGroupById } from '../../../utils/types/data/hooks/payment-slip';
import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
  GetFilterTeamsListResponse,
} from '../../../utils/types/data/services/filters';
import { OrdersPaginatedResponse } from '../../../utils/types/data/services/orders';
import { TagInput } from '../../../components/TagInput';

type PageModalState = 'receipt-generate' | 'nfse-generate' | null;

interface ReceptionistHomeWebProps {
  items: OrderModel[];
  navigation: NavigateFunction;
  handlePaginate(page: number): void;
  handleSubmit(values: FormFilterType): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  formDispatchChange: UseFormReturnType<any>;
  handleClear: () => void;
  setChangeDateModalVisible: React.Dispatch<
    React.SetStateAction<DateModalState>
  >;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setChangeDispatchModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderFilesModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectToFinancialNote(order: Order): void;
  validationPaymentSlipGroupFetcher: ({
    orderId,
    onSuccess,
  }: UseValidationPaymentSlipGroupById) => Promise<void>;
}

type SelectedOrder = {
  id: number;
  os: string;
  product: string;
  value: string;
};

function downloadFile(response: Blob, fileName: string) {
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
}

type FilterFormOrder = {
  os?: string[];
  company?: string;
  status?: string;
};

export function ReceptionistHomeWeb({
  items,
  meta,
  handlePaginate,
  currentPage,
  formFilter,
  handleSubmit,
  handleClear,
  setChangeDateModalVisible,
  setSelectedOrder,
  formDispatchChange,
  setChangeDispatchModalVisible,
  setOrderFilesModalVisible,
  setOrderViewModalVisible,
  handleSelectToFinancialNote,
  validationPaymentSlipGroupFetcher,
}: ReceptionistHomeWebProps) {
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const [toReceiptModalVisible, setToReceitModalVisible] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [createReceiptLoading, setCreateReceiptLoading] = useState(false);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [orderList, setOrderList] = useState<OrdersPaginatedResponse>();
  const [selectedToReceipt, setSelectedToReceipt] = useState<Order>();
  const [selectedOrderList, setSelectedOrderList] = useState<SelectedOrder[]>(
    [],
  );
  const [selectedReceiptInstalments, setSelectedReceiptInstalments] = useState<
    string | undefined
  >('0');
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const currentPageReceipt = useRef(1);

  const [serviceList, setServiceList] = useState<
    GetFilterServicesListResponse[]
  >([]);

  const {
    fetch: getCompanyDocumentsFetcher,
    loading: getCompanyDocumentsLoader,
    reponseData: getCompanyDocumentsData,
  } = useGetCompanyDocuments();
  const { fetch: getNfProvidersFetcher, reponse: getNfProvidersResponse } =
    useGetNfProviders();

  const selectedToReceiptForm = useForm({
    initialValues: {
      materialQuantity: '',
      value: '',
    },
  });

  const findOrderForm = useForm<FilterFormOrder>({
    initialValues: {
      os: [],
      company: '',
      status: '',
    },
  });

  const receiptForm = useForm({
    initialValues: {
      installments: '0',
      totalValue: '',
      obs: '',
    },
  });

  const formEmitNFSe = useForm({
    initialValues: {
      discrimination: '',
      taxesPercentage: '',
      serviceCode: '06912',
      orderPrice: '',
      financialNote: '',
      companyDocumentId: '',
      emiterCNPJ: '',
    },
  });

  function handleDispatchOrder(order: Order) {
    const { id } = order;

    validationPaymentSlipGroupFetcher({
      orderId: id,
    });

    setSelectedOrder(order);
    setChangeDateModalVisible('dispatch');
  }

  function handleAlterDispacht(order: Order) {
    formDispatchChange.setValues({
      dispatchLocation: order.dispatchLocation,
      dispatchForecast: dayjsPlugins(order.dispatchForecast).toDate(),
    });
    setSelectedOrder(order);
    setChangeDispatchModalVisible(true);
  }

  function handleViewOrderFiles(order: Order) {
    setSelectedOrder(order);
    setOrderFilesModalVisible(true);
  }

  function selectedToEmmitNFSe(order: Order) {
    setSelectedToReceipt(order);
    getCompanyDocs(order.company.id);
    setPageModalVisible('nfse-generate');
  }

  function handleViewOrder(order?: Order) {
    setSelectedOrder(order);
    setOrderViewModalVisible(true);
  }

  function handleChangeReceiptInstalments(value: string | null): void {
    setSelectedReceiptInstalments(
      value?.substring(value?.indexOf('%') + 1).replace(',', '.'),
    );

    receiptForm.setFieldValue(
      'installments',
      receiptInstalments
        .map((item) => item.value)
        .indexOf(value ?? '')
        .toString(),
    );
  }

  function mountReceiptObs(
    companyName: string,
    price: string,
    instalments: string,
  ): string {
    let obs = '';
    obs += `Recebemos da empresa ${companyName.toUpperCase()} `;
    obs += `o valor de ${formatBRL(price)} `;
    if (instalments !== '0') {
      obs += `PARCELADO EM ${instalments}X, `;
    }
    obs += 'referente a prestação de serviços abaixo:';
    return obs;
  }

  function handleSetSelectedToReceipt(order: Order) {
    const findIndex = selectedOrderList?.findIndex(
      (item) => item.id === order.id,
    );

    if (findIndex === -1) {
      selectedToReceiptForm.setFieldValue(
        'value',
        getOrderPrices(order).totalPrice,
      );
      setSelectedToReceipt(order);
      setToReceitModalVisible(true);
    } else {
      errorNotification({
        title: '',
        message: 'Material já selecionado!',
      });
    }
  }

  function handleSelectOrder(
    values: typeof selectedToReceiptForm.values,
    order: Order,
  ) {
    // const copyList = selectedOrderList;
    const findIndex = selectedOrderList?.findIndex(
      (item) => item.id === order.id,
    );

    if (findIndex === -1) {
      const selectedItem: SelectedOrder = {
        id: order.id,
        os: order.os,
        product: `${order.product}, ${order.productColor}, ${order.productMaterial}`,
        value: values.value,
      };
      setSelectedOrderList([...selectedOrderList, selectedItem]);
      setSelectedToReceipt(undefined);
      setToReceitModalVisible(false);
      selectedToReceiptForm.reset();
    }
  }

  function handleRemoveOrder(orderId: number) {
    const copyList = selectedOrderList;
    const findIndex = selectedOrderList?.findIndex(
      (item) => item.id === orderId,
    );

    if (findIndex !== -1) {
      copyList.splice(findIndex, 1);
      setSelectedOrderList([...copyList]);
    }
  }

  function handleFindOrderClientChange(value: string | null) {
    findOrderForm.setFieldValue('company', value ?? '');
    setSelectedOrderList([]);
  }

  async function handleSetOrderFinished(id: number) {
    try {
      setPageLoading(true);
      await setOrderFinishedReceptionRequest(id);
      setPageLoading(false);
      handleSubmit(formFilter.values);
      successNotification({
        title: 'Pedido finalizado.',
        message: 'tudo certo',
      });
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao finalizar pedido.',
        message: 'tente novamente.',
      });
    }
  }

  async function getOrdersPaginated() {
    const validatedFilter: any = {};

    Object.entries(findOrderForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setOrdersLoading(true);
      const result = await paginatedOrdersRequest({
        page: currentPageReceipt.current,
        limit: 10,
        ...validatedFilter,
      });
      setOrderList(result);
      setOrdersLoading(false);
    } catch (error) {
      setOrdersLoading(false);
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getVendorTeams() {
    try {
      const response = await getFilterTeamsListRequest({
        type: TeamType.VENDOR,
      });
      setTeamList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar times.',
        message: 'tente novamente',
      });
    }
  }

  async function handleSubmitReceipt(
    values: typeof receiptForm.values,
    selectedToReceipt: SelectedOrder[],
  ) {
    try {
      setCreateReceiptLoading(true);
      const response = await generateOrderReceiptRequest({
        installments: Number(values.installments),
        obs: values.obs,
        totalValue: values.totalValue,
        orders: selectedToReceipt.map((item) => ({
          orderId: item.id,
          value: realToUSCash(item.value),
        })),
      });
      downloadFile(
        response,
        `recibo-${String(new Date(Date.now()).valueOf()).slice(7, 11)}.pdf`,
      );
      setCreateReceiptLoading(false);
      successNotification({
        title: 'Tudo certo!',
        message: 'o recibo será baixado em instantes.',
      });
      handleCloseModal();
      handleSubmit(formFilter.values);
    } catch (error: any) {
      const convertedError = JSON.parse(await error.response.data.text());
      setPageLoading(false);
      setCreateReceiptLoading(false);
      errorNotification({
        title: 'Erro ao gerar recibo.',
        message: translateServerHttpErrors(
          { response: { data: convertedError } as any } as any,
          'tente novamente.',
        ),
      });
    }
  }

  async function handleEmmitMultipleNFSe(values: typeof formEmitNFSe.values) {
    if (selectedToReceipt) {
      try {
        setPageLoading(true);
        await emmitNFSeRequest({
          ...values,
          taxesPercentage: Number(values.taxesPercentage),
          orderPrice: Number(realToUSCash(values.orderPrice)),
          orders: [selectedToReceipt.id],
          companyDocumentId: Number(values.companyDocumentId),
        });

        setPageLoading(false);
        successNotification({
          title: 'Nota em processamento!',
          message: 'Em breve você receberá uma confimação.',
        });
        setPageModalVisible(null);
        handleSubmit(formFilter.values);
      } catch (error: any) {
        setPageLoading(false);
        errorNotification({
          title: 'Erro ao emitir nota.',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      }
    }
  }

  async function getCompanyDocs(companyId: number) {
    await getCompanyDocumentsFetcher({ companyId });
  }

  async function getServices() {
    try {
      const response = await getFilterServicesListRequest();
      setServiceList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar serviços.',
        message: 'tente novamente',
      });
    }
  }

  function handlePaginateReceipt(page: number) {
    currentPageReceipt.current = page;
    getOrdersPaginated();
  }

  function handleCloseModal() {
    setPageModalVisible(null);
    setToReceitModalVisible(false);
    setSelectedToReceipt(undefined);
    setSelectedOrderList([]);
    setSelectedReceiptInstalments('0');
    setOrderList(undefined);
    receiptForm.reset();
    findOrderForm.reset();
    currentPageReceipt.current = 1;
  }

  useMemo(() => {
    const company = companyList?.filter(
      (company) => company.id === Number(findOrderForm.values.company),
    );

    if (company[0]) {
      receiptForm.setFieldValue(
        'obs',
        mountReceiptObs(
          company[0].name,
          receiptForm.values.totalValue,
          receiptForm.values.installments,
        ),
      );
    }
  }, [
    findOrderForm.values.company,
    receiptForm.values.totalValue,
    receiptForm.values.installments,
  ]);

  useMemo(() => {
    let total = 0;
    if (Number(selectedReceiptInstalments) > 0) {
      selectedOrderList.forEach(
        (item) =>
          (total +=
            Number(realToUSCash(item.value)) /
            Number(selectedReceiptInstalments)),
      );
    }
    if (Number(selectedReceiptInstalments) === 0) {
      selectedOrderList.forEach(
        (item) => (total += Number(realToUSCash(item.value))),
      );
    }
    receiptForm.setFieldValue('totalValue', total.toFixed(2));
  }, [selectedOrderList.length, selectedReceiptInstalments]);

  useEffect(() => {
    getCompanies();
    getNfProvidersFetcher();
    getVendorTeams();
    getServices();
  }, []);

  useEffect(() => {
    let totalPrice = 0;
    let discriminationArr: string[] = [];

    if (selectedToReceipt && pageModalVisible === 'nfse-generate') {
      const orderPrice = getOrderPrices(selectedToReceipt);
      const discriminationGenArr = getNFSEDiscrimination(
        selectedToReceipt,
        orderPrice,
      );
      discriminationGenArr.splice(discriminationGenArr.length - 1, 1);

      if (!discriminationArr.length) {
        discriminationArr = discriminationGenArr;
      } else {
        discriminationGenArr.splice(0, 1);
        discriminationArr = [...discriminationArr, ...discriminationGenArr];
      }

      totalPrice += Number(realToUSCash(orderPrice.totalPrice));

      formEmitNFSe.setValues({
        orderPrice: formatBRL(totalPrice.toFixed(2)),
        discrimination: getNFSEDiscrimination(
          selectedToReceipt,
          orderPrice,
        ).join('\n'),
        financialNote: selectedToReceipt.financialNote,
      });

      discriminationArr.push(
        `\n CARGA TRIBUTÁRIA CONFORME LEI 12.471/2012 ${formatBRL(
          totalPrice.toFixed(2),
        )}`,
      );
      formEmitNFSe.setFieldValue(
        'discrimination',
        discriminationArr.join('\n'),
      );
    }
  }, [selectedToReceipt?.id]);

  if (!companyList) return null;

  return (
    <Flex direction="column" w="99%">
      <CustomLoader loading={pageLoading || getCompanyDocumentsLoader} />
      <Group position="right">
        <Button
          onClick={() => setPageModalVisible('receipt-generate')}
          leftIcon={<IconReceipt />}
          color="ltpBlue.9"
          variant="light"
        >
          Recibo
        </Button>
      </Group>
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex wrap="wrap">
            <TextInput
              label="Identificador"
              placeholder="código interno do pedido"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <TextInput
              label="Logo"
              placeholder="logo do produto"
              mb={16}
              mr={8}
              type="text"
              name="engravingText"
              {...formFilter.getInputProps('engravingText')}
            />
            <TextInput
              label="Produto"
              placeholder="nome do produto"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('product')}
            />
            <Select
              required
              name="paymentType"
              withAsterisk
              label="Forma de pagamento"
              placeholder="selecione uma forma de pagamento"
              data={orderPaymentTypeList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('paymentType')}
            />
            <MultiSelect
              clearable
              searchable
              name="company"
              label="Clientes"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.company &&
                formFilter.values.company.split(',')
              }
              onChange={(values) => {
                formFilter.setFieldValue('company', values.toString());
              }}
            />
            <Select
              searchable
              clearable
              name="service"
              label="Serviço"
              placeholder="selecione um serviço"
              defaultValue={serviceList[formFilter.values.service]?.id}
              data={serviceList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('service')}
            />
            <Select
              searchable
              clearable
              name="team"
              label="Times"
              placeholder="selecione um time"
              defaultValue={
                formFilter.values.team && teamList[formFilter.values.team]?.id
              }
              data={teamList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('team')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Data de Retirada"
              lang="br"
              name="receiptForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.receiptForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.receiptForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'receiptForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('receiptForecast', '');
              }}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Prev. de Finalização"
              name="finishForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.finishForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.finishForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'finishForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('finishForecast', '');
              }}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Criado em"
              placeholder="selecione um intervalo"
              mr={8}
              defaultValue={
                formFilter.values.createdAt && [
                  new Date(
                    dayjsPlugins(
                      formFilter.values.createdAt.split(',')[0],
                    ).toDate(),
                  ),
                  new Date(
                    dayjsPlugins(
                      formFilter.values.createdAt.split(',')[1],
                    ).toDate(),
                  ),
                ]
              }
              onChange={(value) => {
                value[0] && value[1]
                  ? formFilter.setFieldValue(
                      'createdAt',
                      `${dayjsPlugins(value[0]).format(
                        'YYYY-MM-DD',
                      )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                    )
                  : formFilter.setFieldValue('createdAt', undefined);
              }}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Faturado em"
              placeholder="selecione um intervalo"
              mr={8}
              defaultValue={
                formFilter.values.issueDate && [
                  new Date(
                    dayjsPlugins(
                      formFilter.values.issueDate.split(',')[0],
                    ).toDate(),
                  ),
                  new Date(
                    dayjsPlugins(
                      formFilter.values.issueDate.split(',')[1],
                    ).toDate(),
                  ),
                ]
              }
              onChange={(value) => {
                value[0] && value[1]
                  ? formFilter.setFieldValue(
                      'issueDate',
                      `${dayjsPlugins(value[0]).format(
                        'YYYY-MM-DD',
                      )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                    )
                  : formFilter.setFieldValue('issueDate', undefined);
              }}
            />
            <MultiSelect
              maw={240}
              clearable
              searchable
              name="status"
              label="Status"
              placeholder="selecione o status do pedido"
              data={statusList}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.status && formFilter.values.status.split(',')
              }
              onChange={(values) => {
                formFilter.setFieldValue('status', values.toString());
              }}
            />
            <MultiSelect
              clearable
              name="financialStatus"
              label="Status Fiscal"
              placeholder="selecione o status de fiscal"
              data={orderFinancialStatusList}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.financialStatus &&
                formFilter.values.financialStatus.split(',')
              }
              onChange={(values) => {
                formFilter.setFieldValue('financialStatus', values.toString());
              }}
            />
            <NumberInput
              placeholder="Qtde"
              w={100}
              min={0}
              label="Quantidade"
              mb={16}
              mr={8}
              type="number"
              name="quantity"
              {...formFilter.getInputProps('quantity')}
            />
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
            <Checkbox
              mt={30}
              label="Mostrar retrabalhos"
              checked={formFilter.values.showRework}
              {...formFilter.getInputProps('showRework')}
            />
          </Group>
        </Paper>
      </form>
      <DataTable
        height="75vh"
        noRecordsText="Sem pedidos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        recordsPerPage={10}
        onPageChange={handlePaginate}
        records={items}
        rowStyle={(record) =>
          record.rework
            ? { backgroundColor: 'rgba(255, 17, 0, 0.027)' }
            : record.isSample
            ? { backgroundColor: 'rgba(0, 255, 0, 0.027)' }
            : {}
        }
        columns={[
          {
            accessor: 'types',
            title: '',
            render: ({ rework, isSample }) =>
              rework ? (
                <Tooltip color="grape" label="retrabalho">
                  <ThemeIcon color="grape" variant="light">
                    <IconRefreshDot size={24} />
                  </ThemeIcon>
                </Tooltip>
              ) : isSample ? (
                <Tooltip color="green" label="amostra">
                  <ThemeIcon color="green" variant="light">
                    <IconTestPipe2 size={24} />
                  </ThemeIcon>
                </Tooltip>
              ) : (
                ''
              ),
          },
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'company.team.name',
            title: 'Time',
          },
          {
            accessor: 'internalNumber',
            title: 'Identificador',
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderStatusColor[status]} mb={6}>
                {orderStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'financialStatus',
            title: 'Status Fiscal',
            render: ({ financialStatus }) =>
              financialStatus && (
                <Badge color={financialStatusColor[financialStatus]} mb={6}>
                  {financialStatusTypeHumanized[financialStatus]}
                </Badge>
              ),
          },
          {
            accessor: 'paymentType',
            title: 'Forma de Pagamento',
            render: ({ paymentType }) =>
              paymentType && orderPaymentTypeHumanized[paymentType],
            width: 110,
          },
          {
            accessor: 'service.name',
            title: 'Serviço',
          },
          {
            accessor: 'engravingText',
            title: 'Logotipo',
          },
          {
            accessor: 'quantity',
            title: 'Qtde',
            width: 60,
          },
          {
            accessor: 'product',
            title: 'Produto',
          },
          {
            accessor: 'needsPostal',
            title: 'Correio',
            render: ({ needsPostal }) => (
              <Badge color={needsPostal ? 'green' : 'red'} mb={6}>
                {needsPostal ? 'sim' : 'não'}
              </Badge>
            ),
          },
          {
            accessor: 'totalprice',
            title: 'Preço total',
            render: (order) => {
              const prices = getOrderPrices(order);

              return `${prices.totalPrice} (${prices.unit})`;
            },
            width: 120,
          },
          {
            accessor: 'receiptedAt',
            title: 'Recebido em',
            render: ({ receiptedAt }) =>
              receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'finishForecast',
            title: 'Prev. Finalização',
            render: ({ finishForecast }) =>
              finishForecast && formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'dispatchForecast',
            title: 'Prev. Retirada',
            render: ({ dispatchForecast }) =>
              dispatchForecast &&
              formatLocale(dispatchForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'dispatchName',
            title: 'Retirado por',
          },
          {
            accessor: 'fileLink',
            title: 'Arquivos',
            render: (order) => (
              <Button
                color="blue"
                variant="subtle"
                onClick={() => handleViewOrderFiles(order)}
                w={40}
                p={0}
              >
                <IconFile />
              </Button>
            ),
            width: 110,
          },
          {
            accessor: 'supplier',
            title: 'Fornecedor',
          },
          {
            accessor: 'carrierName',
            title: 'Portador',
          },
          {
            accessor: 'financialNote',
            title: 'Obs. Fiscal',
            width: 180,
          },
          {
            accessor: '',
            title: '',
            render: (orderItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() => handleSelectToFinancialNote(orderItem)}
                    icon={<IconArchive size={14} />}
                  >
                    Observação Fiscal
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handleAlterDispacht(orderItem)}
                    icon={<IconCalendarStats size={14} />}
                  >
                    Alterar retirada
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handleDispatchOrder(orderItem)}
                    icon={<IconCalendarStats size={14} />}
                  >
                    Retirada do pedido
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handleSetOrderFinished(orderItem.id)}
                    icon={<IconCheck size={14} />}
                  >
                    Finalizar
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handleViewOrder(orderItem)}
                    icon={<IconEye size={14} />}
                  >
                    Ver
                  </Menu.Item>
                  <Menu.Item
                    disabled={
                      orderItem.financialStatus === FinancialStatus.INVOICED
                    }
                    onClick={() => selectedToEmmitNFSe(orderItem)}
                    icon={<IconCertificate size={14} />}
                  >
                    Emitir NFS-e
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
      <Modal
        size={700}
        opened={pageModalVisible === 'receipt-generate'}
        onClose={() => handleCloseModal()}
        title="Emitir Recibo"
      >
        <form onSubmit={findOrderForm.onSubmit(getOrdersPaginated)}>
          <Flex mb={16} wrap="wrap" align="center">
            <Select
              withAsterisk
              required
              searchable
              clearable
              name="company"
              label="Cliente"
              placeholder="selecione o cliente"
              data={companyList.map((item) => ({
                value: String(item.id),
                label: item.name,
              }))}
              onChange={(value) => handleFindOrderClientChange(value)}
              mb={16}
              mr={8}
            />
            <Select
              clearable
              name="status"
              label="Status"
              placeholder="selecione o status do pedido"
              data={statusList}
              mb={16}
              mr={8}
              {...findOrderForm.getInputProps('status')}
            />
            <TagInput
              label="OS"
              placeholder="digite OS separadas por virgula"
              handleChange={(val) => findOrderForm.setFieldValue('os', val)}
              data={findOrderForm.values.os}
              mb={16}
              mr={8}
              w={200}
              {...findOrderForm.getInputProps('os')}
            />
            <Button mb={-10} type="submit">
              Buscar
            </Button>
          </Flex>
        </form>
        <DataTable
          fetching={ordersLoading}
          onRowClick={(record) => handleSetSelectedToReceipt(record)}
          minHeight={200}
          height={400}
          noRecordsText="Sem pedidos"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={orderList?.items}
          page={currentPageReceipt.current}
          totalRecords={orderList?.meta?.totalItems}
          recordsPerPage={10}
          onPageChange={handlePaginateReceipt}
          columns={[
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderStatusColor[status]} mb={6}>
                  {orderStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'product',
              title: 'Produto',
              render: (order) =>
                `${order.product}, ${order.productColor}, ${order.productMaterial}`,
            },
            {
              accessor: 'quantity',
              title: 'Quantidade',
            },
            {
              accessor: 'price',
              title: 'Preço Total',
              render: (order) => getOrderPrices(order).totalPrice,
            },
          ]}
        />
        <Text mt={10} fw={'bold'}>
          Materiais Selecionados
        </Text>
        <DataTable
          minHeight={200}
          height={400}
          noRecordsText="Vazio"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={selectedOrderList}
          columns={[
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'product',
              title: 'Produto',
            },
            {
              accessor: 'materialQuantity',
              title: 'Quantidade',
            },
            {
              accessor: 'value',
              title: 'Preço Total',
              render: (order) => order.value,
            },
            {
              accessor: 'deleteCol',
              title: '',
              render: ({ id }) => (
                <Button
                  onClick={() => handleRemoveOrder(id)}
                  variant="light"
                  color="red"
                  p={4}
                >
                  <IconX />
                </Button>
              ),
            },
          ]}
        />
        <form
          onSubmit={receiptForm.onSubmit((values) =>
            handleSubmitReceipt(values, selectedOrderList),
          )}
        >
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={1}>
              <Select
                name="installments"
                label="Forma de pagamento"
                data={receiptInstalments}
                defaultValue={receiptForm.values.installments}
                mb={16}
                mr={8}
                onChange={(value) => handleChangeReceiptInstalments(value)}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Text fw="bold">Total</Text>
              <Text>{formatBRL(receiptForm.values.totalValue)}</Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                required
                withAsterisk
                autosize
                label="Observações"
                placeholder="obs do recibo"
                name="obs"
                mb={16}
                value={receiptForm.values.obs}
                onChange={(e) =>
                  receiptForm.setFieldValue('obs', e.target.value)
                }
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button
              color="ltpBlue.9"
              type="submit"
              disabled={selectedOrderList.length === 0}
              loading={createReceiptLoading}
            >
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        opened={toReceiptModalVisible}
        onClose={() => setToReceitModalVisible(false)}
        title="Adicionar ao recibo"
      >
        <Grid gutter="xs" columns={2} mt={8}>
          <Grid.Col span={1}>
            <Text fw="bold" color="gray">
              OS
            </Text>
            <Text>{selectedToReceipt?.os}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fw="bold" color="gray">
              Produto
            </Text>
            <Text>{`${selectedToReceipt?.product}, ${selectedToReceipt?.productColor}, ${selectedToReceipt?.productMaterial}`}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fw="bold" color="gray">
              Quantidade Total
            </Text>
            <Text>{selectedToReceipt?.quantity}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fw="bold" color="gray">
              Preço Total
            </Text>
            <Text>
              {selectedToReceipt &&
                getOrderPrices(selectedToReceipt).totalPrice}
            </Text>
          </Grid.Col>
        </Grid>
        <form
          onSubmit={selectedToReceiptForm.onSubmit((values) =>
            handleSelectOrder(values, selectedToReceipt!),
          )}
        >
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor"
                placeholder="quanto foi pago"
                mb={16}
                type="text"
                name="value"
                value={selectedToReceiptForm.values.value}
                onChange={(e) =>
                  selectedToReceiptForm.setFieldValue(
                    'value',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Adicionar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={700}
        opened={pageModalVisible === 'nfse-generate'}
        onClose={() => handleCloseModal()}
        title="Faturamento"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text fw="bold" color="gray">
              Info do pedido
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedToReceipt?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Serviço</Text>
              <Text color="gray">
                {selectedToReceipt?.service.name ?? '--'}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{selectedToReceipt?.quantity ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">
                {`${selectedToReceipt?.product}, ${selectedToReceipt?.productColor}, ${selectedToReceipt?.productMaterial}` ??
                  '--'}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <Flex mt={8} direction="column">
          <Text fw="bold">Valores do Pedido</Text>
          <Table highlightOnHover withBorder striped>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Preço</th>
              </tr>
            </thead>
            <tbody>
              {selectedToReceipt &&
                Object.entries(getOrderPrices(selectedToReceipt)).map(
                  ([key, value]) =>
                    priceTypeHumanized[key] && (
                      <tr key={`${key}-${value}`}>
                        <td>{priceTypeHumanized[key]}</td>
                        <td>{value ?? 'R$0,00'}</td>
                      </tr>
                    ),
                )}
              <tr>
                <td>Total</td>
                <td>
                  {(selectedToReceipt &&
                    getOrderPrices(selectedToReceipt).totalPrice) ??
                    '--'}
                </td>
              </tr>
            </tbody>
          </Table>
        </Flex>
        <form onSubmit={formEmitNFSe.onSubmit(handleEmmitMultipleNFSe)}>
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <Text fw="bold" color="gray">
                Campos Fiscais
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Select
                withAsterisk
                required
                clearable
                searchable
                name="emiterCNPJ"
                label="Empresa Prestadora"
                placeholder="selecione o CNPJ do prestador"
                data={getNfProvidersResponse?.map((item) => ({
                  label: `${item.name}`,
                  value: item.value,
                }))}
                mb={16}
                mr={8}
                {...formEmitNFSe.getInputProps('emiterCNPJ')}
              />
              <Divider />
            </Grid.Col>
            <Grid.Col span={2}>
              <Select
                withAsterisk
                required
                clearable
                searchable
                name="company"
                label="CNPJ do Tomador"
                placeholder="selecione o CNPJ para faturar"
                data={
                  getCompanyDocumentsData?.map((item) => ({
                    label: `${item.nomeFantasia} (${item.document})`,
                    value: item.id,
                  })) ?? []
                }
                mb={16}
                mr={8}
                {...formEmitNFSe.getInputProps('companyDocumentId')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Percentual Carga Tributária"
                placeholder="ex: 7.50"
                mb={16}
                type="number"
                name="taxesPercentage"
                {...formEmitNFSe.getInputProps('taxesPercentage')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Código do Serviço"
                placeholder="ex: 01565"
                mb={16}
                type="text"
                name="serviceCode"
                {...formEmitNFSe.getInputProps('serviceCode')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor Total do Serviço"
                placeholder="ex: R$152,00"
                mb={16}
                type="text"
                name="orderPrice"
                value={formEmitNFSe.values.orderPrice}
                onChange={(e) =>
                  formEmitNFSe.setFieldValue(
                    'orderPrice',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                required
                withAsterisk
                autosize
                label="Discriminação"
                placeholder="discriminação do serviço"
                name="discrimination"
                value={formEmitNFSe.values.discrimination}
                onChange={(e) =>
                  formEmitNFSe.setFieldValue('discrimination', e.target.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Obs Fiscais"
                placeholder="observações fiscais"
                name="financialNote"
                {...formEmitNFSe.getInputProps('financialNote')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
    </Flex>
  );
}
