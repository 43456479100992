import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseApprovalPaymentSlipGroup,
  UseCancelPaymentSlipGroup,
  UseCreatePaymentSlip,
  UseGetPaymentSlipFile,
  UseGetPaymentSlipListOs,
  UseGetPaymentSlipPaginated,
  UseInvalidationPaymentSlip,
  UsePaymentSlipGroupDetails,
  UseUpdatePaymentSlipFile,
  UseValidationPaymentSlip,
  UseValidationPaymentSlipGroupById,
} from '../../../utils/types/data/hooks/payment-slip';
import {
  PaymentSlipFileResponse,
  PaymentSlipGroupDetails,
  PaymentSlipPaginatedResponse,
} from '../../../utils/types/data/services/payment-slip';
import {
  createPaymentSlipRequest,
  getPaymentSlipGroupDetailsRequest,
  getPaymentSlipGroupOsListRequest,
  paginatedPaymentSlipApprovedRequest,
  paginatedPaymentSlipOsPendingRequest,
  paginatedPaymentSlipPendingRequest,
  paginatedPaymentSlipReprovedRequest,
  paginatedPaymentSlipRequest,
  paginatedPaymentSlipToInvoiceRequest,
  paymentSlipFilesRequest,
  paymentSlipGroupApprovalRequest,
  paymentSlipGroupCancelRequest,
  paymentSlipGroupValidationByOrderRequest,
  paymentSlipInvalidationRequest,
  paymentSlipValidationRequest,
  updatePaymentSlipFile,
} from '../../services/payment-slip';

export function useCreatePaymentSlip() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseCreatePaymentSlip) =>
    fetchData({
      fetcher: () => createPaymentSlipRequest(data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao criar comprovante.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipsPending() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipPaginatedResponse>();

  const fetch = ({ query, onSuccess }: UseGetPaymentSlipPaginated) =>
    fetchData({
      fetcher: () => paginatedPaymentSlipPendingRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao listar comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipsApproved() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipPaginatedResponse>();

  const fetch = ({ query, onSuccess }: UseGetPaymentSlipPaginated) =>
    fetchData({
      fetcher: () => paginatedPaymentSlipApprovedRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao listar comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipsReproved() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipPaginatedResponse>();

  const fetch = ({ query, onSuccess }: UseGetPaymentSlipPaginated) =>
    fetchData({
      fetcher: () => paginatedPaymentSlipReprovedRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao listar comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipsOsPending() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipPaginatedResponse>();

  const fetch = ({ query, onSuccess }: UseGetPaymentSlipPaginated) =>
    fetchData({
      fetcher: () => paginatedPaymentSlipOsPendingRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao listar comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlips() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipPaginatedResponse>();

  const fetch = ({ query, onSuccess }: UseGetPaymentSlipPaginated) =>
    fetchData({
      fetcher: () => paginatedPaymentSlipRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao listar comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipsFiles() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipFileResponse[]>();

  const fetch = ({ paymentSlipGroupId, onSuccess }: UseGetPaymentSlipFile) =>
    fetchData({
      fetcher: () => paymentSlipFilesRequest(paymentSlipGroupId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar arquivos do comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useValidationPaymentSlip() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<{ validation: boolean }>();

  const fetch = ({
    paymentSlipId,
    onSuccess,
    data,
  }: UseValidationPaymentSlip) =>
    fetchData({
      fetcher: () => paymentSlipValidationRequest(paymentSlipId, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao validar comprovante.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipGroupDetails() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipGroupDetails>();

  const fetch = ({
    paymentSlipGroupId,
    onSuccess,
  }: UsePaymentSlipGroupDetails) =>
    fetchData({
      fetcher: () => getPaymentSlipGroupDetailsRequest(paymentSlipGroupId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao buscar grupo de comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useInvalidatePaymentSlip() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ paymentSlipId, onSuccess }: UseInvalidationPaymentSlip) =>
    fetchData({
      fetcher: () => paymentSlipInvalidationRequest(paymentSlipId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao invalidar comprovante.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useApprovalPaymentSlipGroup() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({
    paymentSlipId,
    data,
    onSuccess,
  }: UseApprovalPaymentSlipGroup) =>
    fetchData({
      fetcher: () => paymentSlipGroupApprovalRequest(paymentSlipId, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao validar grupo de comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useUpdatePaymentSlipFile() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({
    paymentSlipGroupId,
    data,
    onSuccess,
  }: UseUpdatePaymentSlipFile) =>
    fetchData({
      fetcher: () => updatePaymentSlipFile(paymentSlipGroupId, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao adicionar arquivo ao comprovante.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useValidationPaymentSlipGroupByOrder() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<{ validation: boolean }>();

  const fetch = ({ orderId, onSuccess }: UseValidationPaymentSlipGroupById) =>
    fetchData({
      fetcher: () => paymentSlipGroupValidationByOrderRequest(orderId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao validar comprovante.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipGroupOsList() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<string[]>();

  const fetch = ({ query, onSuccess }: UseGetPaymentSlipListOs) =>
    fetchData({
      fetcher: () => getPaymentSlipGroupOsListRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: `Erro ao buscar Os's.`,
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetPaymentSlipsToInvoice() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaymentSlipPaginatedResponse>();

  const fetch = ({ query, onSuccess }: UseGetPaymentSlipPaginated) =>
    fetchData({
      fetcher: () => paginatedPaymentSlipToInvoiceRequest(query),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao listar comprovantes.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useCancelPaymentSlipGroup() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({
    paymentSlipGroupId,
    onSuccess,
  }: UseCancelPaymentSlipGroup) =>
    fetchData({
      fetcher: () => paymentSlipGroupCancelRequest(paymentSlipGroupId),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao cancelar comprovante.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
