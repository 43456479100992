import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useReducer, useRef } from 'react';

interface State<T> {
  data?: T;
  error?: Error;
  loading: boolean;
}

interface UserFetchProps<T> {
  fetcher: () => Promise<AxiosResponse<T>>;
  onError?: (error: Error) => void;
  onSuccess?: (res: T) => void;
  url?: string;
  options?: AxiosRequestConfig<any>;
}

type Action<T> =
  | { type: 'loading'; payload: boolean }
  | { type: 'fetched'; payload: T }
  | { type: 'error'; payload: Error };

type Cache<T> = { [url: string]: T };

export function useFetch<T = unknown>() {
  const cache = useRef<Cache<T>>({});
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
    loading: false,
  };

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    if (action.type == 'loading') {
      return { ...initialState, loading: action.payload };
    } else if (action.type == 'fetched') {
      return { ...initialState, data: action.payload };
    } else if (action.type == 'error') {
      return { ...initialState, error: action.payload };
    } else {
      return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const fetchData = async ({
    fetcher,
    url,
    onError,
    onSuccess,
  }: UserFetchProps<T>) => {
    dispatch({ type: 'loading', payload: true });

    if (url && cache.current[url]) {
      dispatch({ type: 'fetched', payload: cache.current[url] });
      return;
    }

    try {
      const response = await fetcher();

      const data = response.data as T;
      url && (cache.current[url] = data);
      if (cancelRequest.current) return;

      dispatch({ type: 'loading', payload: false });
      dispatch({ type: 'fetched', payload: data });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({ type: 'loading', payload: false });
      if (cancelRequest.current) return;

      dispatch({ type: 'error', payload: error as Error });
      onError && onError(error as Error);
    }
  };

  useEffect(() => {
    cancelRequest.current = false;

    return () => {
      cancelRequest.current = true;
    };
  }, []);

  return { state, fetchData };
}
