import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Stepper,
  Tabs,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconForms, IconPlus, IconUsers } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyDocItem } from '../../components/CompanyDocItem';
import { CustomLoader } from '../../components/CustomLoader';
import {
  rootNavigate,
  rootNavigateGoBack,
} from '../../components/CustomRouter';

import { Page } from '../../components/Page';
import {
  useGetCompany,
  useGetCompanyUsers,
  useRemoveUserCompany,
  useSetCompanyUsers,
  useSetGetUserCompanyEmailPrivacy,
  useUpdateCompany,
} from '../../data/hooks/companies';
import {
  useAddCompanyDocuments,
  useRemoveCompanyDocuments,
} from '../../data/hooks/company-documents';
import { useGetUsersWithEmailFilter } from '../../data/hooks/filters';
import { useCreateUser } from '../../data/hooks/users';
import { getAddressByCepOnProxy } from '../../data/services/cep';
import { getTeamsRequest } from '../../data/services/teams';
import { CompanyCategory } from '../../models/company';
import { Team } from '../../models/team';
import { UserRole, UserType } from '../../models/user';
import { successNotification } from '../../providers/mantine-notifications';
import {
  companyCategoryOptionList,
  docTypeList,
} from '../../utils/constants/company';
import { AppRoutePaths } from '../../utils/enums';
import {
  clearMask,
  formatCEP,
  formatCNPJ,
  formatCPF,
  formatPhone,
} from '../../utils/formater';
import { AddCompanyDocumentsData } from '../../utils/types/data/services/company-documents';
import { GetFilterUsersWithEmailListResponse } from '../../utils/types/data/services/filters';
import { CompanyUser } from './sub-components/company-user';
import { useSelector } from 'react-redux';
import { RootState } from '../../providers/store';

type CompanyDocumentItem = AddCompanyDocumentsData & { onEdit: boolean };

type PageModalState = 'add-cnpj' | 'add-user' | 'relate-users' | null;

export function CompanyEdit() {
  const { id } = useParams();
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const [teamList, setTeamList] = useState<Team[]>();
  const [currentStep] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [companyDocs, setCompanyDocs] = useState<CompanyDocumentItem[]>([]);
  const [relateUserList, setRelateUsersList] = useState<
    GetFilterUsersWithEmailListResponse[]
  >([]);
  const [selectedRelatedUsers, setSelectedRelatedUsers] = useState<
    GetFilterUsersWithEmailListResponse[]
  >([]);
  const {
    fetch: getCompanyFetcher,
    loading: getCompanyLoader,
    reponseData: getCompanyData,
  } = useGetCompany();
  const { fetch: removeCompanyDocFetcher, loading: removeCompanyDocLoader } =
    useRemoveCompanyDocuments();
  const { fetch: addCompanyDocFetcher, loading: addCompanyDocLoader } =
    useAddCompanyDocuments();
  const { fetch: updateCompanyFetcher, loading: updateCompanyLoader } =
    useUpdateCompany();
  const {
    fetch: getUsersFilterFetcher,
    loading: getUsersFilterLoader,
    response: getUsersFilterData,
  } = useGetUsersWithEmailFilter();

  const { fetch: setCompanyUsersFetcher, loading: setCompanyUsersLoader } =
    useSetCompanyUsers();
  const {
    fetch: getCompanyUsersFetcher,
    loading: getCompanyUsersLoader,
    response: getCompanyUsersData,
  } = useGetCompanyUsers();
  const { fetch: createUserFetcher, loading: createUserLoader } =
    useCreateUser();
  const { fetch: setEmailPrivacyFetcher, loading: setEmailPrivacyLoader } =
    useSetGetUserCompanyEmailPrivacy();
  const { fetch: removeUserFetcher, loading: removeUserLoader } =
    useRemoveUserCompany();

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      team: undefined,
      category: '',
      phone: '',
      whatsapp: '',
    },
  });

  const documentForm = useForm({
    initialValues: {
      id: '',
      document: '',
      documentType: 'cnpj',
      nomeFantasia: '',
      razaoSocial: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      email: '',
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressCity: '',
      addressState: '',
      addressCountry: '',
      addressZipCode: '',
      addressStateIBGECode: '',
    },
  });

  const addUserForm = useForm({
    initialValues: {
      username: '',
      email: '',
      password: '',
      type: 'user',
      role: UserRole.COMPANY,
    },
  });

  const teamOptions = teamList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));

  function setNewCompanyDoc() {
    documentForm.reset();
    setPageModal('add-cnpj');
  }

  async function getTeams() {
    const response = await getTeamsRequest();
    setTeamList(response);
  }

  async function getCompany() {
    await getCompanyFetcher({ id: Number(id) });
  }

  async function getAddress(cep: string) {
    documentForm.setFieldValue('addressCity', '');
    documentForm.setFieldValue('addressState', '');
    documentForm.setFieldValue('addressStreet', '');

    setPageLoading(true);
    const address = await getAddressByCepOnProxy(cep.replace('-', ''));
    setPageLoading(false);
    if (address) {
      documentForm.setFieldValue('addressCity', address?.city);
      documentForm.setFieldValue('addressCountry', 'BR');
      documentForm.setFieldValue('addressState', address?.state);
      documentForm.setFieldValue('addressStreet', address?.address);
    }
  }

  async function handleSubmit(values: typeof form.values) {
    await updateCompanyFetcher({
      id: Number(id),
      data: {
        ...values,
        team: Number(values.team),
        category: values.category as CompanyCategory,
        phone: clearMask(values.phone),
        whatsapp: clearMask(values.whatsapp),
        name: values.name,
      },
      onSuccess: () => {
        form.reset();
        documentForm.reset();
        rootNavigate(AppRoutePaths.COMPANY);
      },
    });
  }

  async function handleAddDocument(values: typeof documentForm.values) {
    const cloneList = companyDocs;
    const foundIndex = cloneList.findIndex(
      (item) => item.document === clearMask(values.document),
    );

    if (foundIndex !== -1) {
      cloneList[foundIndex] = {
        ...cloneList[foundIndex],
        email: values.email,
        addressCity: values.addressCity,
        addressComplement: values.addressComplement,
        addressCountry: values.addressCountry,
        addressNumber: values.addressNumber,
        addressState: values.addressState,
        addressStateIBGECode: values.addressStateIBGECode,
        addressStreet: values.addressStreet,
        addressZipCode: values.addressZipCode,
        documentType: values.documentType,
        inscricaoEstadual: values.inscricaoEstadual,
        inscricaoMunicipal: values.inscricaoMunicipal,
        nomeFantasia: values.nomeFantasia,
        razaoSocial: values.razaoSocial,
        document: clearMask(values.document),
        onEdit: false,
        company: Number(id),
      };
    } else {
      cloneList.push({
        documentType: values.documentType,
        inscricaoEstadual: values.inscricaoEstadual,
        inscricaoMunicipal: values.inscricaoMunicipal,
        nomeFantasia: values.nomeFantasia,
        razaoSocial: values.razaoSocial,
        email: values.email,
        addressCity: values.addressCity,
        addressComplement: values.addressComplement,
        addressCountry: values.addressCountry,
        addressNumber: values.addressNumber,
        addressState: values.addressState,
        addressStateIBGECode: values.addressStateIBGECode,
        addressStreet: values.addressStreet,
        addressZipCode: values.addressZipCode,
        document: clearMask(values.document),
        onEdit: false,
        company: Number(id),
        id: undefined,
      });
    }
    await addCompanyDocFetcher({
      data: {
        documents: cloneList.map((cloItem) => ({
          ...cloItem,
          onEdit: undefined,
        })),
      },
    });
    setCompanyDocs([...cloneList]);
    documentForm.reset();
    setPageModal(null);
  }

  async function handleDeleteDocument(index: number) {
    const cloneList = companyDocs;
    if (cloneList[index].id !== undefined) {
      await removeCompanyDocFetcher({
        id: Number(cloneList[index].id),
      });
    }
    cloneList.splice(index, 1);
    setCompanyDocs([...cloneList]);
  }

  async function handleSetDocumentToEdit(index: number) {
    const cloneList = companyDocs;
    cloneList[index].onEdit = true;
    setCompanyDocs([...cloneList]);

    const foundedItem = cloneList[index];

    documentForm.setValues({
      ...foundedItem,
      document:
        foundedItem.documentType === 'cnpj'
          ? formatCNPJ(foundedItem.document ?? '')
          : formatCPF(foundedItem.document ?? ''),
      id: '',
    });
    setPageModal('add-cnpj');
  }

  async function getCompanyUsers() {
    if (user?.role !== UserRole.RECEPTIONIST) {
      await getCompanyUsersFetcher({
        companyId: Number(id),
      });
    }
  }

  async function getUsersFilter() {
    await getUsersFilterFetcher({
      query: { role: UserRole.COMPANY },
    });
  }

  async function handleSetRelatedUsers(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!selectedRelatedUsers.length) return;

    await setCompanyUsersFetcher({
      companyId: Number(id),
      data: {
        insert: selectedRelatedUsers.map((item) => item.id),
      },
      onSuccess: () => {
        successNotification({
          title: 'Lista de usuários atualizada.',
          message: 'tudo certo!',
        });
        setPageModal(null);
        setSelectedRelatedUsers([]);
        getCompanyUsers();
      },
    });
  }

  async function handleSubmitAddUser(values: typeof addUserForm.values) {
    await createUserFetcher({
      data: {
        ...values,
        type: values.type as UserType,
        role: values.role as UserRole,
      },
      onSuccess: async (data) => {
        successNotification({
          title: 'Usuário cadastrado!',
          message: 'tudo certo.',
        });
        await setCompanyUsersFetcher({
          companyId: Number(id),
          data: {
            insert: [data.id],
            remove: [],
          },
        });
        getCompanyUsers();
        setPageModal(null);
        addUserForm.reset();
      },
    });
  }

  async function handleChangeEmailPrivacy({
    companyId,
    userId,
    allow,
  }: {
    companyId: number;
    userId: number;
    allow: boolean;
  }) {
    await setEmailPrivacyFetcher({
      companyId,
      userId,
      data: {
        allowEmail: allow,
      },
      onSuccess: () => {
        getCompanyUsers();
      },
    });
  }

  async function handleRemoverCompanyUser({
    companyId,
    userId,
  }: {
    companyId: number;
    userId: number;
  }) {
    await removeUserFetcher({
      companyId,
      userId,
      onSuccess: () => {
        getCompanyUsers();
      },
    });
  }

  useEffect(() => {
    getTeams();
    getCompany();
    getCompanyUsers();
    getUsersFilter();
  }, [id]);

  useEffect(() => {
    if (getUsersFilterData?.length || getCompanyUsersData?.length) {
      const formatedGeneral: GetFilterUsersWithEmailListResponse[] = [];

      getUsersFilterData?.forEach((value) => {
        if (!getCompanyUsersData?.some((item) => item.id === value.id)) {
          formatedGeneral.push({
            name: value.name,
            id: value.id,
            email: value.email,
          });
        }
      });

      setRelateUsersList([...formatedGeneral]);
    }
  }, [getUsersFilterData, getCompanyUsersData]);

  useEffect(() => {
    if (getCompanyData) {
      setCompanyDocs(
        getCompanyData.documents.map((item) => ({ ...item, onEdit: false })),
      );
      form.setFieldValue('name', getCompanyData.name);
      form.setFieldValue('email', getCompanyData.email);
      form.setFieldValue('team', String(getCompanyData.team) as any);
      form.setFieldValue('category', getCompanyData?.category ?? '');
      form.setFieldValue('phone', formatPhone(getCompanyData?.phone ?? ''));
      form.setFieldValue(
        'whatsapp',
        formatPhone(getCompanyData?.whatsapp ?? ''),
      );
    }
  }, [getCompanyData]);

  return (
    <Page>
      <Container>
        <CustomLoader
          loading={
            getCompanyLoader ||
            updateCompanyLoader ||
            addCompanyDocLoader ||
            removeCompanyDocLoader ||
            getUsersFilterLoader ||
            setCompanyUsersLoader ||
            getCompanyUsersLoader ||
            createUserLoader ||
            setEmailPrivacyLoader ||
            removeUserLoader
          }
        />
        <CustomLoader loading={pageLoading} />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Editar Empresa
        </Title>
        <Divider color="gray.2" m={16} />
        <Tabs
          color="orange"
          variant="pills"
          keepMounted={false}
          radius="md"
          defaultValue="data"
        >
          <Tabs.List>
            <Tabs.Tab fw="bold" value="data" icon={<IconForms size={14} />}>
              Dados
            </Tabs.Tab>
            {user?.role !== UserRole.RECEPTIONIST && (
              <Tabs.Tab fw="bold" value="users" icon={<IconUsers size={14} />}>
                Usuários
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Tabs.Panel value="data" pt="xs">
            <Paper maw={600}>
              <Stepper
                mt={16}
                color="orange"
                orientation="horizontal"
                iconSize={37}
                active={currentStep}
              >
                <Stepper.Step label="Empresa" description="dados da empresa">
                  <Grid gutter="xs" maw={900} columns={4}>
                    <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        type="submit"
                        leftIcon={<IconPlus />}
                        onClick={setNewCompanyDoc}
                        color="orange"
                        variant="light"
                        mt={8}
                        h={30}
                      >
                        Novo CNPJ
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Text size={16} color="dark.4">
                        CNPJs
                      </Text>
                    </Grid.Col>
                    <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Flex wrap="wrap">
                        {companyDocs.map((docItem, index) => (
                          <CompanyDocItem
                            key={`doc-${index}`}
                            docItem={docItem}
                            handleDeleteDocument={() =>
                              handleDeleteDocument(index)
                            }
                            handleSetDocumentToEdit={() =>
                              handleSetDocumentToEdit(index)
                            }
                          />
                        ))}
                      </Flex>
                    </Grid.Col>
                  </Grid>
                  <form
                    onSubmit={form.onSubmit((values) => handleSubmit(values))}
                  >
                    <Grid gutter="xs" maw={900} columns={4}>
                      <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                        <TextInput
                          required
                          withAsterisk
                          label="Nome"
                          placeholder="nome da empresa"
                          type="text"
                          name="name"
                          {...form.getInputProps('name')}
                        />
                      </Grid.Col>
                      <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                        <TextInput
                          label="Telefone"
                          placeholder="telefone de contato"
                          type="text"
                          name="phone"
                          withAsterisk
                          required
                          onChange={(e) =>
                            form.setFieldValue(
                              'phone',
                              formatPhone(e.target.value),
                            )
                          }
                          value={form.values.phone}
                        />
                      </Grid.Col>
                      <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                        <TextInput
                          label="WhatsApp"
                          placeholder="WhatsApp para contato"
                          type="text"
                          name="whatsapp"
                          withAsterisk
                          required
                          onChange={(e) =>
                            form.setFieldValue(
                              'whatsapp',
                              formatPhone(e.target.value),
                            )
                          }
                          value={form.values.whatsapp}
                        />
                      </Grid.Col>
                      <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                        <TextInput
                          required
                          withAsterisk
                          label="Email"
                          placeholder="email para notificações"
                          type="text"
                          name="email"
                          {...form.getInputProps('email')}
                        />
                      </Grid.Col>
                      <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                        <Select
                          searchable
                          required
                          withAsterisk
                          name="team"
                          label="Time"
                          placeholder="selecione o time responsável"
                          data={teamOptions ?? []}
                          {...form.getInputProps('team')}
                        />
                      </Grid.Col>
                      <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                        <Select
                          required
                          withAsterisk
                          name="category"
                          label="Categoria"
                          placeholder="selecione a categorização da empresa"
                          data={companyCategoryOptionList}
                          {...form.getInputProps('category')}
                        />
                      </Grid.Col>
                    </Grid>
                    <Group position="right" mt="xl">
                      <Button color="ltpBlue.9" type="submit">
                        Salvar
                      </Button>
                    </Group>
                  </form>
                </Stepper.Step>
              </Stepper>
            </Paper>
          </Tabs.Panel>
          <Tabs.Panel value="users" pt="xs">
            <Paper maw={600}>
              <ScrollArea p={16} h={480} w={500}>
                {getCompanyUsersData?.map((item) => (
                  <CompanyUser
                    key={item.id}
                    user={item}
                    onChangeEmailPrivacy={({ allow, userId }) =>
                      handleChangeEmailPrivacy({
                        allow,
                        userId,
                        companyId: Number(id),
                      })
                    }
                    onRemoveUser={({ userId }) =>
                      handleRemoverCompanyUser({
                        userId,
                        companyId: Number(id),
                      })
                    }
                  />
                ))}
              </ScrollArea>
              <Group position="right">
                <Button
                  onClick={() => setPageModal('add-user')}
                  color="orange.6"
                  variant="light"
                  mt={8}
                  h={30}
                >
                  Novo Usuário
                </Button>
                <Button
                  onClick={() => setPageModal('relate-users')}
                  color="orange.8"
                  variant="light"
                  mt={8}
                  h={30}
                >
                  Relacionar Usuários
                </Button>
              </Group>
            </Paper>
          </Tabs.Panel>
        </Tabs>
      </Container>
      <Modal
        title="Adicionar CNPJ"
        size={600}
        opened={pageModal === 'add-cnpj'}
        onClose={() => setPageModal(null)}
      >
        <form onSubmit={documentForm.onSubmit(handleAddDocument)}>
          <Grid gutter="xs" columns={4}>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Nome Fantasia"
                placeholder="nome fantasia da empresa"
                type="text"
                name="nomeFantasia"
                {...documentForm.getInputProps('nomeFantasia')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Razão Social"
                placeholder="razão social da empresa"
                type="text"
                name="razaoSocial"
                {...documentForm.getInputProps('razaoSocial')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <Select
                required
                withAsterisk
                name="documentType"
                label="Tipo de Documento"
                placeholder="selecione o tipo de documento"
                data={docTypeList}
                onChange={(value) => {
                  documentForm.setFieldValue('documentType', String(value));
                  documentForm.setFieldValue('document', '');
                }}
                value={documentForm.values.documentType}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label={
                  documentForm.values.documentType === 'cnpj' ? 'CNPJ' : 'CPF'
                }
                placeholder="documento da empresa"
                type="text"
                name="name"
                onChange={(e) =>
                  documentForm.setFieldValue(
                    'document',
                    documentForm.values.documentType === 'cnpj'
                      ? formatCNPJ(e.target.value)
                      : formatCPF(e.target.value),
                  )
                }
                value={documentForm.values.document}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email cadastral"
                type="text"
                name="email"
                {...documentForm.getInputProps('email')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                label="Inscrição Estadual"
                placeholder="número da inscrição"
                type="text"
                name="inscricaoEstadual"
                {...documentForm.getInputProps('inscricaoEstadual')}
              />
            </Grid.Col>
            <Grid.Col mb={16} xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Inscrição Municipal"
                placeholder="número da inscrição"
                type="text"
                name="inscricaoMunicipal"
                {...documentForm.getInputProps('inscricaoMunicipal')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Text size={16} color="dark.4">
                Endereço
              </Text>
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="CEP"
                placeholder="digite o CEP do endereço"
                type="text"
                name="addressZipCode"
                onChange={async (e) => {
                  documentForm.setFieldValue(
                    'addressZipCode',
                    formatCEP(e.target.value),
                  );
                  if (e.target.value.length == 9) {
                    await getAddress(e.target.value);
                  }
                }}
                value={documentForm.values.addressZipCode}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Endereço"
                placeholder="digite o endereço"
                type="text"
                name="addressStreet"
                {...documentForm.getInputProps('addressStreet')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Número"
                placeholder="digite o número"
                type="text"
                name="addressNumber"
                {...documentForm.getInputProps('addressNumber')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                label="Complemento"
                placeholder="digite o complemento do endereço"
                type="text"
                name="addressComplement"
                {...documentForm.getInputProps('addressComplement')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Estado"
                placeholder="digite o estado ex: SP"
                type="text"
                name="addressState"
                {...documentForm.getInputProps('addressState')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Cidade"
                placeholder="digite a cidade"
                type="text"
                name="addressCity"
                {...documentForm.getInputProps('addressCity')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Código de Estado do IBGE"
                placeholder="digite o código"
                type="text"
                name="addressCity"
                {...documentForm.getInputProps('addressStateIBGECode')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button
              type="submit"
              onClick={() => {}}
              color="orange"
              variant="light"
              mt={8}
              h={30}
            >
              Adicionar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        title="Novo Usuário"
        size={400}
        opened={pageModal === 'add-user'}
        onClose={() => setPageModal(null)}
      >
        <form onSubmit={addUserForm.onSubmit(handleSubmitAddUser)}>
          <Grid columns={1}>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Usuário"
                placeholder="nome do usuário"
                mb={8}
                type="text"
                name="username"
                {...addUserForm.getInputProps('username')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email do usuário"
                mb={8}
                type="text"
                name="email"
                {...addUserForm.getInputProps('email')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                minLength={6}
                label="Senha"
                placeholder="senha do usuário"
                mb={16}
                type="text"
                name="password"
                {...addUserForm.getInputProps('password')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        title="Relacionar Usuários"
        size={400}
        opened={pageModal === 'relate-users'}
        onClose={() => setPageModal(null)}
      >
        <form onSubmit={handleSetRelatedUsers}>
          <DataTable
            onSelectedRecordsChange={setSelectedRelatedUsers}
            selectedRecords={selectedRelatedUsers}
            height="60vh"
            noRecordsText="Sem usuários"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={relateUserList}
            columns={[
              {
                accessor: 'name',
                title: 'Nome',
              },
              {
                accessor: 'email',
                title: 'Email',
              },
            ]}
          />
          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
