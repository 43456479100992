import { Activity, ActivityComplexity, ActivityStatus } from './activity';
import { User } from './user';

export enum ArtProductionType {
  PRODUCTION = 'production',
  PAUSE = 'pause',
}

export type ArtActivity = {
  id: number;
  activity: number;
  artUser: number;
  clockIn: string;
  clockOut?: string | null;
  type: ArtProductionType;
  timeAmount: number;
  amount?: number;
  description?: string;
};

export type PopulatedArtActivity = Omit<ArtActivity, 'activity' | 'artUser'> & {
  activity: Activity;
  artUser: User;
};

export type ActivityReport = {
  id: number;
  activity: number;
  artUser: number;
  clockIn: string;
  clockOut: string;
  type: ArtProductionType;
  timeAmount: number;
  amount: number | null;
  username: string;
  company_name: string;
  order_id: number;
  os: string;
  team_name: string;
  activity_status: ActivityStatus;
  activity_complexity: ActivityComplexity;
};
