import {
  Badge,
  Button,
  Flex,
  Menu,
  Paper,
  Select,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconDotsVertical,
  IconEye,
  IconRefreshDot,
  IconStatusChange,
  IconTestPipe2,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { FormFilterType } from '..';

import {
  getFilterCompaniesListRequest,
  getFilterServicesListRequest,
} from '../../../data/services/filters';
import { Order, Order as OrderModel, OrderStatus } from '../../../models/order';
import { formatLocale } from '../../../providers/dayjs-plugins';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  orderStatusColor,
  orderStatusHumanized,
} from '../../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
  orderPaymentTypeHumanized,
} from '../../../utils/constants/order';
import { getOrderPrices } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
} from '../../../utils/types/data/services/filters';

interface VendorHomeWebProps {
  items: OrderModel[];
  navigation: NavigateFunction;
  handlePaginate(page: number): void;
  handleChangePageLimit(limit: number): void;
  handleSubmit(values: FormFilterType): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  handleClear: () => void;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeOrderStatus: (orderItem: Order) => void;
}

export function VendorHomeWeb({
  items,
  meta,
  handlePaginate,
  handleChangePageLimit,
  currentPage,
  setSelectedOrder,
  setOrderViewModalVisible,
  handleClear,
  formFilter,
  handleSubmit,
  handleChangeOrderStatus,
}: VendorHomeWebProps) {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [serviceList, setServiceList] = useState<
    GetFilterServicesListResponse[]
  >([]);

  async function getCompanies() {
    const response = await getFilterCompaniesListRequest();
    setCompanyList(response);
  }

  async function getServices() {
    try {
      const response = await getFilterServicesListRequest();
      setServiceList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar serviços.',
        message: 'tente novamente',
      });
    }
  }

  function handleViewOrder(order?: Order) {
    setSelectedOrder(order);
    setOrderViewModalVisible(true);
  }

  useEffect(() => {
    getServices();
    getCompanies();
  }, []);

  if (!companyList) return null;

  return (
    <Flex direction="column" w="99%">
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex>
            <Select
              searchable
              name="company"
              label="Clientes"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <Select
              searchable
              name="service"
              label="Serviços"
              placeholder="selecione o serviço"
              data={serviceList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('service')}
            />
          </Flex>
          <Button
            mt={25}
            color="ltpBlue.9"
            type="button"
            variant="outline"
            onClick={handleClear}
          >
            Limpar
          </Button>
          <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
            Filtrar
          </Button>
        </Paper>
      </form>
      <DataTable
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 300]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        height="75vh"
        noRecordsText="Sem pedidos em atraso"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        recordsPerPage={meta?.itemsPerPage ?? 10}
        onPageChange={handlePaginate}
        records={items}
        rowStyle={(record) =>
          record.rework
            ? { backgroundColor: 'rgba(255, 17, 0, 0.027)' }
            : record.isSample
            ? { backgroundColor: 'rgba(0, 255, 0, 0.027)' }
            : {}
        }
        columns={[
          {
            accessor: 'types',
            title: '',
            render: ({ rework, isSample }) =>
              rework ? (
                <Tooltip color="grape" label="retrabalho">
                  <ThemeIcon color="grape" variant="light">
                    <IconRefreshDot size={24} />
                  </ThemeIcon>
                </Tooltip>
              ) : isSample ? (
                <Tooltip color="green" label="amostra">
                  <ThemeIcon color="green" variant="light">
                    <IconTestPipe2 size={24} />
                  </ThemeIcon>
                </Tooltip>
              ) : (
                ''
              ),
          },
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'internalNumber',
            title: 'Identificador',
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderStatusColor[status]} mb={6}>
                {orderStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'financialStatus',
            title: 'Status Fiscal',
            render: ({ financialStatus }) =>
              financialStatus && (
                <Badge color={financialStatusColor[financialStatus]} mb={6}>
                  {financialStatusTypeHumanized[financialStatus]}
                </Badge>
              ),
          },
          {
            accessor: 'issueDate',
            title: 'Faturado em',
            render: ({ issueDate }) =>
              issueDate && formatLocale(issueDate, 'DD/MM/YY HH:mm'),
          },
          {
            accessor: 'service.name',
            title: 'Serviço',
          },
          {
            accessor: 'engravingText',
            title: 'Logotipo',
          },
          {
            accessor: 'quantity',
            title: 'Qtde',
            width: 60,
          },
          {
            accessor: 'product',
            title: 'Produto',
          },
          {
            accessor: 'needsPostal',
            title: 'Correio',
            render: ({ needsPostal }) => (
              <Badge color={needsPostal ? 'green' : 'red'} mb={6}>
                {needsPostal ? 'sim' : 'não'}
              </Badge>
            ),
          },
          {
            accessor: 'receiptedAt',
            title: 'Recebido em',
            render: ({ receiptedAt }) =>
              receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'finishForecast',
            title: 'Prev. Finalização',
            render: ({ finishForecast }) =>
              finishForecast && formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'createdAt',
            title: 'Criado em',
            render: ({ createdAt }) =>
              createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'supplier',
            title: 'Fornecedor',
          },
          {
            accessor: 'vendor-porter',
            title: 'Portador',
            render: (orderItem) =>
              orderItem.status === OrderStatus.FINISHED
                ? orderItem.dispatchLocation
                : orderItem.carrierName,
          },
          {
            accessor: 'paymentType',
            title: 'Forma de Pagamento',
            render: ({ paymentType }) =>
              paymentType && orderPaymentTypeHumanized[paymentType],
            width: 110,
          },
          {
            accessor: 'totalprice',
            title: 'Preço total',
            render: (orderItem) => `${getOrderPrices(orderItem).totalPrice}`,
            width: 110,
          },
          {
            accessor: 'vendor-porter-menu',
            title: '',
            render: (orderItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() => handleViewOrder(orderItem)}
                    icon={<IconEye size={14} />}
                  >
                    Ver
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handleChangeOrderStatus(orderItem)}
                    icon={<IconStatusChange size={14} />}
                  >
                    Alterar status
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
