import { Checkbox, Group, NumberInput, Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';
import { orderClichePriceOptions } from '../../utils/constants/order';
import { formatBRL } from '../../utils/helpers';

interface HotStampingFormItemProps {
  form: UseFormReturnType<any>;
}

export function HotStampingFormItem({ form }: HotStampingFormItemProps) {
  useEffect(() => {
    form.setValues({
      clichePriceSelector: '',
      details: {
        height: '',
        width: '',
        hasSample: false,
        personalizationSides: [],
      },
    });
  }, []);

  return (
    <>
      <Group grow>
        <NumberInput
          label="Altura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="height"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.height')}
        />
        <NumberInput
          required
          withAsterisk
          label="Largura"
          placeholder="em cm*"
          mb={16}
          min={0}
          name="width"
          precision={2}
          step={0.5}
          {...form.getInputProps('details.width')}
        />
        <Select
          required
          withAsterisk
          label="Tamanho do Clichê"
          placeholder="selecione o tamanho"
          data={orderClichePriceOptions}
          mb={16}
          name="clichePriceSelector"
          {...form.getInputProps('clichePriceSelector')}
          onChange={(val) => {
            form.setFieldValue('clichePrice', formatBRL(val));
            form.setFieldValue('clichePriceSelector', val);
          }}
        />
      </Group>
      <Checkbox.Group
        defaultValue={['frente']}
        label="Local da gravação"
        description="você pode selecionar mais de um"
        withAsterisk
        name="personalizationSides"
        mb={16}
        {...form.getInputProps('details.personalizationSides')}
      >
        <Checkbox value="frente" label="Frente" color="green" />
        <Checkbox value="verso" label="Verso" color="green" />
      </Checkbox.Group>
    </>
  );
}
