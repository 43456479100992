import { CompanyDocument } from './company-document';
import { Team } from './team';

export enum CompanyCategory {
  AGENCY = 'agency',
  BRINDEIRO_P = 'brindeiro_p',
  BRINDEIRO_M = 'brindeiro_m',
  BRINDEIRO_G = 'brindeiro_g',
  BRINDEIRO_GG = 'brindeiro_gg',
  INDUSTRY = 'industry',
  CLIENT = 'client',
  IMPORTER_BRINDE = 'importer_brinde',
  IMPORTER = 'importer',
}

export enum CompanyDocumentType {
  CNPJ = 'cnpj',
  CPF = 'cpf',
}

export enum CompanyPaymentTermType {
  DAY_AMOUNT = 'day_amount',
  DAYS = 'days',
}

export enum CompanyPaymentType {
  BANK_SLIP = 'bank_slip',
  DEPOSIT = 'deposit',
}

export type Company = {
  id: number;
  name: string;
  email: string;
  team: Team;
  category?: CompanyCategory;
  phone?: string;
  document?: string;
  documentType?: CompanyDocumentType;
  nomeFantasia?: string;
  razaoSocial?: string;
  inscricaoEstadual?: string;
  inscricaoMunicipal?: string;
  addressStreet?: string;
  addressNumber?: string;
  addressComplement?: string;
  addressCity?: string;
  addressState?: string;
  addressCountry?: string;
  addressZipCode?: string;
  addressStateIBGECode?: string;
  createdAt: string;
  createdBy: string;
  documents: CompanyDocument[];
  isProtested: boolean;
  protestDescription?: string;
  whatsapp?: string;
};
