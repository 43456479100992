import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Collapse,
  CopyButton,
  Flex,
  Group,
  Image,
  Modal,
  Paper,
  Select,
  Skeleton,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconAlertCircle,
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconCopy,
  IconDownload,
  IconSearch,
  IconUpload,
} from '@tabler/icons';
import axios from 'axios';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomMultipleFileInput } from '../../components/CustomMutipleFileInput';
import { Page } from '../../components/Page';
import {
  useApprovalPaymentSlipGroup,
  useCancelPaymentSlipGroup,
  useCreatePaymentSlip,
  useGetPaymentSlipGroupDetails,
  useGetPaymentSlips,
  useGetPaymentSlipsApproved,
  useGetPaymentSlipsFiles,
  useGetPaymentSlipsOsPending,
  useGetPaymentSlipsPending,
  useGetPaymentSlipsReproved,
  useGetPaymentSlipsToInvoice,
  useInvalidatePaymentSlip,
  useUpdatePaymentSlipFile,
} from '../../data/hooks/payment-slip';
import { uploadMultipleFilesRequest } from '../../data/services/files';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { paginatedOrdersRequest } from '../../data/services/orders';
import { Order } from '../../models/order';
import { PaymentSlipApprovalStatus } from '../../models/payment-slip';
import { UserRole } from '../../models/user';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  paymentSlipStatusColor,
  paymentSlipStatusHumanized,
} from '../../utils/constants';
import { formatBRL, getOrderPrices } from '../../utils/helpers';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';
import {
  PaymentSlipFileResponse,
  PaymentSlipResponse,
} from '../../utils/types/data/services/payment-slip';
import { CompanyPaymentSlip } from './company';
import { FinancialPaymentSlip } from './financial';
import { PaymentSlipFileItem } from './sub-components/payment-slip-file-item';
import { VendorPaymentSlip } from './vendor';

export type PaymentSlipPageModalVisible =
  | 'create'
  | 'analyze'
  | 'view'
  | 'review'
  | 'invalidate-slip'
  | 'cancel'
  | null;

export type InvalidateModalVisible = 'invalidate-slip' | null;

type CreatePaymentSlipFormType = {
  fileReceipts: File[];
};

type ReviewPaymentSlipFormType = {
  fileReceipts: File[];
};

async function downloadFile(fileUrl: string, fileName: string) {
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    headers: {},
  });
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

function MountPaymentSlipCards({
  items,
}: {
  items: PaymentSlipFileResponse[];
}) {
  return (
    <>
      {items &&
        items.map((item) => (
          <Flex
            mb={'md'}
            key={item.id}
            gap={'sm'}
            sx={(theme) => ({
              border: [PaymentSlipApprovalStatus.REPROVED].includes(
                item.approval,
              )
                ? `2px solid ${theme.colors.red[5]}`
                : [PaymentSlipApprovalStatus.APPROVED].includes(item.approval)
                ? `2px solid ${theme.colors.green[5]}`
                : '',
              backgroundColor: theme.colors.gray[0],
              padding: theme.spacing.sm,
              borderRadius: theme.radius.lg,
            })}
          >
            <Flex
              direction={'column'}
              gap={'sm'}
              justify={'center'}
              align={'center'}
            >
              <Image
                radius="md"
                src={item.file_url}
                alt="Payment slip file"
                width={180}
                height={220}
                fit="cover"
                withPlaceholder
              />
              <Button
                variant="subtle"
                onClick={() => downloadFile(item.file_url, item.file_name)}
              >
                <IconDownload size={24} color="blue" />
              </Button>
            </Flex>
            <Flex direction={'column'} w="99%">
              <Text fw={'bold'} size={'sm'}>
                ID transação
              </Text>
              <Text size={'sm'} color="gray">
                {item.identification ?? '--'}
              </Text>
              <Text fw={'bold'} size={'sm'} mt={'sm'}>
                Valor transação
              </Text>
              <Text size={'sm'} color="gray">
                {formatBRL(item.price ?? '0')}
              </Text>
              <Group mt={'xl'}>
                {[PaymentSlipApprovalStatus.APPROVED].includes(
                  item.approval,
                ) && (
                  <Text color="green" size={'xs'} fw={'bold'}>
                    *Comprovante válido
                  </Text>
                )}
                {[PaymentSlipApprovalStatus.REPROVED].includes(
                  item.approval,
                ) && (
                  <Text color="red" size={'xs'} fw={'bold'}>
                    *Comprovante inválido
                  </Text>
                )}
                {[PaymentSlipApprovalStatus.PENDING].includes(
                  item.approval,
                ) && (
                  <Text color="orange" size={'xs'} fw={'bold'}>
                    *Comprovante em análise
                  </Text>
                )}
              </Group>
            </Flex>
          </Flex>
        ))}
    </>
  );
}

function MountPaymentSlipCardsReview({
  items,
}: {
  items: PaymentSlipFileResponse[];
}) {
  return (
    <>
      {items &&
        items.map((item) => (
          <Flex
            mb={'md'}
            key={item.id}
            gap={'sm'}
            sx={(theme) => ({
              border: [PaymentSlipApprovalStatus.REPROVED].includes(
                item.approval,
              )
                ? `2px solid ${theme.colors.red[5]}`
                : [PaymentSlipApprovalStatus.APPROVED].includes(item.approval)
                ? `2px solid ${theme.colors.green[5]}`
                : '',
              backgroundColor: theme.colors.gray[0],
              padding: theme.spacing.sm,
              borderRadius: theme.radius.lg,
            })}
          >
            <Flex
              direction={'column'}
              gap={'sm'}
              justify={'center'}
              align={'center'}
            >
              <Image
                radius="md"
                src={item.file_url}
                alt="Random unsplash image"
                width={180}
                height={220}
                fit="cover"
                withPlaceholder
              />

              <Button
                variant="subtle"
                onClick={() => downloadFile(item.file_url, item.file_name)}
              >
                <IconDownload size={24} color="blue" />
              </Button>
            </Flex>
            <Flex direction={'column'} w="99%">
              <Flex direction={'column'} w="99%">
                <Text fw={'bold'} size={'sm'}>
                  ID transação
                </Text>
                <Text size={'sm'} color="gray">
                  {item.identification ?? '--'}
                </Text>
                <Text fw={'bold'} size={'sm'} mt={'sm'}>
                  Valor transação
                </Text>
                <Text size={'sm'} color="gray">
                  {formatBRL(item.price ?? '0')}
                </Text>
              </Flex>
              <Group mt={'xl'}>
                {[PaymentSlipApprovalStatus.APPROVED].includes(
                  item.approval,
                ) && (
                  <Text color="green" size={'xs'} fw={'bold'}>
                    *Comprovante válido
                  </Text>
                )}
                {[PaymentSlipApprovalStatus.REPROVED].includes(
                  item.approval,
                ) && (
                  <Text color="red" size={'xs'} fw={'bold'}>
                    *Comprovante inválido
                  </Text>
                )}
                {[PaymentSlipApprovalStatus.PENDING].includes(
                  item.approval,
                ) && (
                  <Text color="orange" size={'xs'} fw={'bold'}>
                    *Comprovante em análise
                  </Text>
                )}
              </Group>
            </Flex>
          </Flex>
        ))}
    </>
  );
}

export function PaymentSlip() {
  const { user } = useSelector((state: RootState) => state.auth);
  const page = useRef(1);
  const pageLimit = useRef(10);
  const currentPageLimit = useRef(10);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [pageModalVisible, setPageModalVisible] =
    useState<PaymentSlipPageModalVisible>(null);
  const [modalInvalidateVisible, setModalInvalidateVisible] =
    useState<InvalidateModalVisible>(null);
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [filtersCollapse, setFiltersCollapse] = useState(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [selectedPaymentSlipFile, setSelectedPaymentSlipFile] =
    useState<PaymentSlipFileResponse>();
  const [reviewAddFileOpen, setReviewAddFileOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | null>('to-confirm');
  const [osToCopyFinancial, setOsToCopyFinancial] = useState<string[]>([]);
  const [selectedOrdersToCopyFinancial, setSelectedOrdersToCopyFinancial] =
    useState<PaymentSlipResponse[]>([]);
  const [selectedPaymentSlipGroup, setSelectedPaymentSlipGroup] =
    useState<PaymentSlipResponse>();

  const { fetch: createPaymentSlipFetcher, loading: createPaymentSlipLoader } =
    useCreatePaymentSlip();

  const {
    fetch: getPaymentSlipFilesFetcher,
    loading: getPaymentSlipFilesLoader,
    response: getPaymentSlipFilesResponse,
  } = useGetPaymentSlipsFiles();

  const {
    fetch: getPaymentSlipGroupDetailsFetcher,
    response: getPaymentSlipGroupDetailsResponse,
    loading: getPaymentSlipGroupDetailsLoader,
  } = useGetPaymentSlipGroupDetails();

  const {
    fetch: invalidatePaymentSlipFetcher,
    loading: invalidatePaymentSlipLoader,
  } = useInvalidatePaymentSlip();

  const {
    fetch: approvalPaymentSlipGroupFetcher,
    loading: approvalPaymentSlipGroupLoader,
  } = useApprovalPaymentSlipGroup();

  const {
    fetch: updatePaymentSlipFileFetcher,
    loading: updatePaymentSlipFileLoader,
  } = useUpdatePaymentSlipFile();

  const {
    response: getPaymentSlipListResponse,
    fetch: getPaymentSlipListFetcher,
    loading: getPaymentSlipListLoader,
  } = useGetPaymentSlips();

  const {
    response: getPaymentSlipPendingResponse,
    fetch: getPaymentSlipPendingFetcher,
    loading: getPaymentSlipPendingLoader,
  } = useGetPaymentSlipsPending();

  const {
    response: getPaymentSlipApprovedResponse,
    fetch: getPaymentSlipApprovedFetcher,
    loading: getPaymentSlipApprovedLoader,
  } = useGetPaymentSlipsApproved();

  const {
    response: getPaymentSlipOsPendingResponse,
    fetch: getPaymentSlipOsPendingFetcher,
    loading: getPaymentSlipOsPendingLoader,
  } = useGetPaymentSlipsOsPending();

  const {
    response: getPaymentSlipReprovedResponse,
    fetch: getPaymentSlipReprovedFetcher,
    loading: getPaymentSlipReprovedLoader,
  } = useGetPaymentSlipsReproved();

  const {
    response: getPaymentSlipToInvoiceResponse,
    fetch: getPaymentSlipToInvoiceFetcher,
    loading: getPaymentSlipToInvoiceLoader,
  } = useGetPaymentSlipsToInvoice();

  const {
    fetch: cancelPaymentSlipGroupFetcher,
    loading: cancelPaymentSlipGroupLoader,
  } = useCancelPaymentSlipGroup();

  const filterForm = useForm({
    initialValues: {
      code: '',
      status: '',
      companies: '',
    },
  });

  const formCreatePaymentSlip = useForm<CreatePaymentSlipFormType>({
    initialValues: {
      fileReceipts: [],
    },
  });

  const formReviewPaymentSlip = useForm<ReviewPaymentSlipFormType>({
    initialValues: {
      fileReceipts: [],
    },
  });

  const orderFilterForm = useForm({
    initialValues: {
      os: '',
      company: '',
    },
  });

  const formApprovalSlipGroup = useForm({
    initialValues: {
      obs: '',
      isApproved: false,
    },
  });

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({
        team: user?.role === UserRole.VENDOR ? Number(user.team) : undefined,
      });
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  function handleFilterOrders() {
    getOrdersPaginated();
  }

  async function getOrdersPaginated() {
    const validatedFilter: any = {};

    Object.entries(orderFilterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setOrdersLoading(true);
      const result = await paginatedOrdersRequest({
        page: 1,
        limit: 10,
        ...validatedFilter,
        company: user?.role === UserRole.COMPANY ? user.company : undefined,
      });
      setOrderList(result.items);
      setOrdersLoading(false);
    } catch (error) {
      setOrdersLoading(false);
    }
  }

  function handleCloseModal() {
    setSelectedPaymentSlipGroup(undefined);
    setSelectedPaymentSlipFile(undefined);
    setModalInvalidateVisible(null);
    setPageModalVisible(null);
    setSelectedOrders([]);
    formCreatePaymentSlip.reset();
  }

  async function handleCreatePaymentSlip() {
    const { fileReceipts } = formCreatePaymentSlip.values;
    let fileIds: number[] = [];

    if (!selectedOrders.length) {
      errorNotification({
        title: 'Selecione ao menos 1 pedido',
        message: 'tente novamente 🤞',
      });
      return;
    }

    if (fileReceipts.length) {
      try {
        setLoadingUploadFile(true);
        fileIds = await handleUploadFiles(fileReceipts);
        setLoadingUploadFile(false);
      } catch (error) {
        setLoadingUploadFile(false);
        errorNotification({
          title: 'Erro ao realizar o upload dos arquivos!',
          message: 'tente novamente 🤞',
        });
      }
    } else {
      errorNotification({
        title: 'Inclua ao menos 1 comprovante',
        message: 'tente novamente 🤞',
      });
      return;
    }

    createPaymentSlipFetcher({
      data: {
        orders: selectedOrders.map((order) => order.id),
        files: fileIds.map((fileId) => fileId),
      },
      onSuccess: () => {
        getPaymentSlipsGroups();
        handleCloseModal();
        successNotification({
          title: 'Comprovante criado com sucesso!',
          message: 'tudo certo ✅',
        });
      },
    });
  }

  async function handleUploadFiles(files: File[]): Promise<number[]> {
    const fileIds = await uploadMultipleFilesRequest({ files });

    return fileIds;
  }

  async function handleGetPaymentSlipGroupDetails(
    paymentSlipGroup: PaymentSlipResponse,
  ) {
    setSelectedPaymentSlipGroup(paymentSlipGroup);
    const { paymentSlipGroup: paymentSlipId } = paymentSlipGroup;

    getPaymentSlipGroupDetailsFetcher({
      paymentSlipGroupId: paymentSlipId,
    });

    getPaymentSlipFilesFetcher({
      paymentSlipGroupId: paymentSlipId,
    });
  }

  async function handleOpenInvalidationModal(
    paymentSlip: PaymentSlipFileResponse,
  ) {
    setSelectedPaymentSlipFile(paymentSlip);
    setModalInvalidateVisible('invalidate-slip');
  }

  async function handleInvalidatePaymentSlip() {
    if (!selectedPaymentSlipFile) {
      return;
    }
    await invalidatePaymentSlipFetcher({
      paymentSlipId: selectedPaymentSlipFile?.id,
      onSuccess: () => {
        setSelectedPaymentSlipFile(undefined);
        setModalInvalidateVisible(null);
        getPaymentSlipFilesResponse?.map((file) => {
          if (file.id === selectedPaymentSlipFile.id) {
            file.approval = PaymentSlipApprovalStatus.REPROVED;
          }
        });
      },
    });
  }

  async function handleCloseInvalidationModal() {
    setSelectedPaymentSlipFile(undefined);
    setModalInvalidateVisible(null);
  }

  async function handleApprovalPaymentSlipGroup(
    values: typeof formApprovalSlipGroup.values,
  ) {
    if (!selectedPaymentSlipGroup) {
      return;
    }

    await approvalPaymentSlipGroupFetcher({
      paymentSlipId: selectedPaymentSlipGroup.paymentSlipGroup,
      data: {
        isApproved: values.isApproved,
        obs: values.obs,
      },
      onSuccess: () => {
        handleCloseModal();
        getPaymentSlipsGroupFinancial();
        successNotification({
          title: 'Comprovante analisado com sucesso!',
          message: 'tudo certo ✅',
        });
      },
    });
  }

  async function handleUpdatePaymentSlipFile() {
    if (!selectedPaymentSlipGroup) {
      return;
    }

    const { paymentSlipGroup: paymentSlipGroupId } = selectedPaymentSlipGroup;
    const { fileReceipts } = formReviewPaymentSlip.values;
    let fileIds: number[] = [];

    if (fileReceipts.length) {
      try {
        setLoadingUploadFile(true);
        fileIds = await handleUploadFiles(fileReceipts);
        setLoadingUploadFile(false);
      } catch (error) {
        setLoadingUploadFile(false);
        errorNotification({
          title: 'Erro ao realizar o upload dos arquivos!',
          message: 'tente novamente 🤞',
        });
      }
    } else {
      errorNotification({
        title: 'Inclua ao menos 1 comprovante',
        message: 'tente novamente 🤞',
      });
      return;
    }

    updatePaymentSlipFileFetcher({
      paymentSlipGroupId,
      data: {
        files: fileIds.map((fileId) => fileId),
      },
      onSuccess: () => {
        getPaymentSlipFilesFetcher({
          paymentSlipGroupId,
        });
        setReviewAddFileOpen(false);
        successNotification({
          title: 'Comprovante enviado com sucesso!',
          message: 'tudo certo ✅',
        });
      },
    });
  }

  async function getPaymentSlipsGroups(isReset = false) {
    let dynamicFields: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        dynamicFields[key] = val;
      }
    });

    if (isReset) {
      dynamicFields = {};
    }

    await getPaymentSlipListFetcher({
      query: {
        ...dynamicFields,
        limit: pageLimit.current ?? 10,
        page: page.current ?? 1,
      },
    });
  }

  function handleFilter() {
    getPaymentSlipsGroups();
  }

  function handleChangePage(pageNumber: number) {
    page.current = pageNumber;
    getPaymentSlipsGroups();
  }

  function handleChangePageLimit(pageLimitNumber: number) {
    currentPageLimit.current = pageLimitNumber;
    getPaymentSlipsGroups();
  }

  function handleClear() {
    filterForm.reset();
    getPaymentSlipsGroups(true);
  }

  async function getPaymentSlipsGroupFinancial(isReset = false) {
    let dynamicFields: any = {};

    Object.entries(filterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        dynamicFields[key] = val;
      }
    });

    if (isReset) {
      dynamicFields = {};
    }

    switch (activeTab) {
      case 'to-confirm':
        page.current = 1;
        setSelectedOrdersToCopyFinancial([]);
        await getPaymentSlipPendingFetcher({
          query: {
            ...dynamicFields,
            limit: pageLimit.current ?? 10,
            page: page.current ?? 1,
          },
        });
        break;
      case 'to-invoice':
        page.current = 1;
        setSelectedOrdersToCopyFinancial([]);
        await getPaymentSlipToInvoiceFetcher({
          query: {
            ...dynamicFields,
            limit: pageLimit.current ?? 10,
            page: page.current ?? 1,
          },
        });
        break;
      case 'approved':
        page.current = 1;
        setSelectedOrdersToCopyFinancial([]);
        await getPaymentSlipApprovedFetcher({
          query: {
            ...dynamicFields,
            limit: pageLimit.current ?? 10,
            page: page.current ?? 1,
          },
        });
        break;
      case 'to-finish':
        page.current = 1;
        setSelectedOrdersToCopyFinancial([]);
        await getPaymentSlipOsPendingFetcher({
          query: {
            ...dynamicFields,
            limit: pageLimit.current ?? 10,
            page: page.current ?? 1,
          },
        });
        break;
      case 'divergence':
        page.current = 1;
        setSelectedOrdersToCopyFinancial([]);
        await getPaymentSlipReprovedFetcher({
          query: {
            ...dynamicFields,
            limit: pageLimit.current ?? 10,
            page: page.current ?? 1,
          },
        });
        break;
      default:
        break;
    }
  }

  function handleCalculateOrdesTotalPrice() {
    if (getPaymentSlipGroupDetailsResponse) {
      return getPaymentSlipGroupDetailsResponse.orders.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.total_price),
        0.0,
      );
    }
  }

  function handleCopyOS() {
    if (!getPaymentSlipGroupDetailsResponse) {
      return;
    }

    const os = getPaymentSlipGroupDetailsResponse.orders.map(
      (order) => order.os,
    );

    return os.toString();
  }

  async function handleCancelPaymentSlipGroup() {
    if (!selectedPaymentSlipGroup) {
      return;
    }

    await cancelPaymentSlipGroupFetcher({
      paymentSlipGroupId: selectedPaymentSlipGroup.paymentSlipGroup,
      onSuccess: () => {
        handleCloseModal();
        getPaymentSlipsGroupFinancial();
        successNotification({
          title: 'Comprovante cancelado com sucesso!',
          message: 'tudo certo ✅',
        });
      },
    });
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Page title="Comprovantes">
      {user?.role === UserRole.VENDOR && (
        <VendorPaymentSlip
          companyList={companyList}
          filterForm={filterForm}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          setPageModalVisible={setPageModalVisible}
          handleGetPaymentSlipGroup={handleGetPaymentSlipGroupDetails}
          items={getPaymentSlipListResponse?.items}
          loading={getPaymentSlipListLoader}
          getPaymentSlipsGroups={getPaymentSlipsGroups}
          handleFilter={handleFilter}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
          handleClear={handleClear}
          currentPage={page}
        />
      )}

      {user?.role === UserRole.COMPANY && (
        <CompanyPaymentSlip
          filterForm={filterForm}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          setPageModalVisible={setPageModalVisible}
          handleGetPaymentSlipGroup={handleGetPaymentSlipGroupDetails}
          items={getPaymentSlipListResponse?.items}
          loading={getPaymentSlipListLoader}
          getPaymentSlipsGroups={getPaymentSlipsGroups}
          handleFilter={handleFilter}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
          handleClear={handleClear}
          currentPage={page}
        />
      )}

      {user?.role === UserRole.FINANCIAL && (
        <FinancialPaymentSlip
          companyList={companyList}
          filterForm={filterForm}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
          setPageModalVisible={setPageModalVisible}
          handleGetPaymentSlipGroup={handleGetPaymentSlipGroupDetails}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          currentPage={page}
          currentPageLimit={currentPageLimit}
          getPaymentSlipsGroupFinancial={getPaymentSlipsGroupFinancial}
          itemsApproved={getPaymentSlipApprovedResponse}
          itemsPending={getPaymentSlipPendingResponse}
          itemsOsPending={getPaymentSlipOsPendingResponse}
          itemsReproved={getPaymentSlipReprovedResponse}
          itemsToInvoice={getPaymentSlipToInvoiceResponse}
          approvedLoading={getPaymentSlipApprovedLoader}
          osPendingLoading={getPaymentSlipOsPendingLoader}
          pendingLoading={getPaymentSlipPendingLoader}
          reprovedLoading={getPaymentSlipReprovedLoader}
          toInvoiceLoading={getPaymentSlipToInvoiceLoader}
          setOsToCopyFinancial={setOsToCopyFinancial}
          osToCopyFinancial={osToCopyFinancial}
          setSelectedOrdersToCopyFinancial={setSelectedOrdersToCopyFinancial}
          selectOrdersToCopyFinancial={selectedOrdersToCopyFinancial}
          setSelectedPaymentSlipGroup={setSelectedPaymentSlipGroup}
        />
      )}

      <Modal
        size={610}
        opened={pageModalVisible === 'create'}
        onClose={() => handleCloseModal()}
        title={<Text fw="bold">Adicionar comprovante</Text>}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Flex direction="column" w="99%">
          <form onSubmit={orderFilterForm.onSubmit(handleFilterOrders)}>
            <Paper p={16} mb={16} mt={16} withBorder>
              <Flex align={'center'}>
                <TextInput
                  label="OS"
                  placeholder="digite a os"
                  mr={8}
                  type="text"
                  name="os"
                  {...orderFilterForm.getInputProps('os')}
                />
                {user?.role === UserRole.VENDOR && (
                  <Select
                    clearable
                    searchable
                    name="company"
                    label="Cliente"
                    placeholder="selecione o cliente"
                    data={companyList?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    mr={8}
                    {...orderFilterForm.getInputProps('company')}
                  />
                )}
                <Button color="blue" mt={24} type="submit">
                  <IconSearch size={20} />
                </Button>
              </Flex>
            </Paper>
          </form>
          <DataTable
            height={280}
            noRecordsText="Sem pedidos"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={orderList}
            fetching={ordersLoading}
            selectedRecords={selectedOrders}
            onSelectedRecordsChange={setSelectedOrders}
            columns={[
              {
                accessor: 'os',
                title: 'OS',
              },
              {
                accessor: '',
                title: `${user?.role !== UserRole.COMPANY ? 'Cliente' : ''}`,
                render: ({ company }) =>
                  user?.role !== UserRole.COMPANY && company.name,
              },
              {
                accessor: '',
                title: 'Produto',
                render: ({ product, productColor, productMaterial }) =>
                  `${product}, ${productColor}, ${productMaterial}`,
              },
              {
                accessor: 'quantity',
                title: 'Qtde',
              },
              {
                accessor: 'totalprice',
                title: 'Total',
                render: (orderItem) =>
                  `${getOrderPrices(orderItem).totalPrice}`,
                width: 110,
              },
            ]}
          />
          <CustomMultipleFileInput
            name="fileReceipts"
            accept="application/pdf,image/png,image/jpeg,image/jpg"
            label="Comprovantes"
            placeholder="selecione arquivo por vez"
            inputName="fileReceipts"
            formRef={formCreatePaymentSlip}
          />
          <Group position="right">
            <Button
              color="blue"
              mt={24}
              disabled={!selectedOrders.length}
              onClick={() => handleCreatePaymentSlip()}
              loading={createPaymentSlipLoader || loadingUploadFile}
            >
              Salvar
            </Button>
          </Group>
        </Flex>
      </Modal>
      <Modal
        size={610}
        opened={pageModalVisible === 'analyze'}
        onClose={() => handleCloseModal()}
        title={<Text fw="bold">Analisar</Text>}
      >
        <Skeleton visible={getPaymentSlipGroupDetailsLoader}>
          <Text fw={'bold'} size={'sm'}>
            Comprovante: {getPaymentSlipGroupDetailsResponse?.code}
          </Text>

          <Badge
            color={
              getPaymentSlipGroupDetailsResponse &&
              paymentSlipStatusColor[getPaymentSlipGroupDetailsResponse?.status]
            }
            mb={6}
          >
            {getPaymentSlipGroupDetailsResponse &&
              paymentSlipStatusHumanized[
                getPaymentSlipGroupDetailsResponse?.status
              ]}
          </Badge>

          <Flex align={'center'} gap={'md'} mt={'md'}>
            <Text size={'sm'} fw={'bold'}>
              OS's
            </Text>
            <CopyButton value={handleCopyOS() ?? '--'} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? `OS's Copiadas` : `Copiar OS's`}
                  withArrow
                  position="right"
                >
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                    {copied ? <IconCheck size={20} /> : <IconCopy size={20} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Flex>

          <DataTable
            height={210}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getPaymentSlipGroupDetailsResponse?.orders ?? []}
            columns={[
              {
                accessor: 'os',
                title: 'OS',
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: '',
                title: 'Produto',
                render: ({ product_name, product_material, product_color }) =>
                  `${product_name}, ${product_material}, ${product_color}`,
              },
              {
                accessor: 'quantity',
                title: 'Qtde',
              },
              {
                accessor: 'totalprice',
                title: 'Total',
                render: ({ total_price }) =>
                  formatBRL(total_price.toFixed(2) ?? '0'),
                width: 110,
              },
            ]}
          />
          <Group position="right">
            <Text size={'sm'} fw={'bold'}>
              Total:{' '}
              {formatBRL(handleCalculateOrdesTotalPrice()?.toFixed(2) ?? '0')}
            </Text>
          </Group>
        </Skeleton>

        <Text mt={'md'} size={'sm'} fw={'bold'} mb={'md'}>
          Comprovantes
        </Text>

        <Skeleton visible={getPaymentSlipFilesLoader}>
          {getPaymentSlipFilesResponse?.map((file) => (
            <PaymentSlipFileItem
              key={file.id}
              item={file}
              handleOpenInvalidationModal={handleOpenInvalidationModal}
              invalidatePaymentSlipLoader={invalidatePaymentSlipLoader}
              downloadFile={downloadFile}
            />
          ))}
        </Skeleton>

        <form
          onSubmit={formApprovalSlipGroup.onSubmit((values) =>
            handleApprovalPaymentSlipGroup(values),
          )}
        >
          <Textarea
            maxLength={255}
            mt={16}
            label="Observação"
            placeholder="digite a observação"
            name="obs"
            required
            {...formApprovalSlipGroup.getInputProps('obs')}
          />

          <Group position="right" mt={'xl'}>
            <Button
              size="sm"
              color="red"
              type="submit"
              onClick={() =>
                formApprovalSlipGroup.setFieldValue('isApproved', false)
              }
              loading={approvalPaymentSlipGroupLoader}
            >
              Reprovar
            </Button>
            <Button
              size="sm"
              color="green"
              type="submit"
              onClick={() =>
                formApprovalSlipGroup.setFieldValue('isApproved', true)
              }
              loading={approvalPaymentSlipGroupLoader}
            >
              Aprovar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={610}
        opened={pageModalVisible === 'view'}
        onClose={() => handleCloseModal()}
        title={<Text fw="bold">Ver</Text>}
      >
        <Skeleton visible={getPaymentSlipGroupDetailsLoader}>
          <Text fw={'bold'} size={'sm'}>
            Comprovante: {getPaymentSlipGroupDetailsResponse?.code}
          </Text>

          <Badge
            color={
              getPaymentSlipGroupDetailsResponse &&
              paymentSlipStatusColor[getPaymentSlipGroupDetailsResponse?.status]
            }
            mb={6}
          >
            {getPaymentSlipGroupDetailsResponse &&
              paymentSlipStatusHumanized[
                getPaymentSlipGroupDetailsResponse?.status
              ]}
          </Badge>

          <Flex align={'center'} gap={'md'} mt={'md'}>
            <Text size={'sm'} fw={'bold'}>
              OS's
            </Text>
            <CopyButton value={handleCopyOS() ?? '--'} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? `OS's Copiadas` : `Copiar OS's`}
                  withArrow
                  position="right"
                >
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                    {copied ? <IconCheck size={20} /> : <IconCopy size={20} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Flex>

          <DataTable
            height={210}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getPaymentSlipGroupDetailsResponse?.orders ?? []}
            columns={[
              {
                accessor: 'os',
                title: 'OS',
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: '',
                title: 'Produto',
                render: ({ product_name, product_material, product_color }) =>
                  `${product_name}, ${product_material}, ${product_color}`,
              },
              {
                accessor: 'quantity',
                title: 'Qtde',
              },
              {
                accessor: 'totalprice',
                title: 'Total',
                render: ({ total_price }) =>
                  formatBRL(total_price.toFixed(2) ?? '0'),
                width: 110,
              },
            ]}
          />

          <Group position="right">
            <Text size={'sm'} fw={'bold'}>
              Total:{' '}
              {formatBRL(handleCalculateOrdesTotalPrice()?.toFixed(2) ?? '0')}
            </Text>
          </Group>

          <Text size={'sm'} fw={'bold'} mt={'md'}>
            Observação
          </Text>
          <Text size={'sm'} color="gray">
            {getPaymentSlipGroupDetailsResponse?.obs ?? '--'}
          </Text>
        </Skeleton>

        <Text mt={'md'} size={'sm'} fw={'bold'} mb={'md'}>
          Comprovantes
        </Text>
        <Skeleton visible={getPaymentSlipFilesLoader}>
          <MountPaymentSlipCards items={getPaymentSlipFilesResponse ?? []} />
        </Skeleton>
      </Modal>
      <Modal
        size={610}
        opened={pageModalVisible === 'review'}
        onClose={() => handleCloseModal()}
        title={<Text fw="bold">Revisar</Text>}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Atenção"
          color="red"
          mb={16}
        >
          Identificamos divergências nos comprovantes enviados. Favor revisar e
          enviar um novo comp rovente.
        </Alert>

        <Skeleton visible={getPaymentSlipGroupDetailsLoader}>
          <Text fw={'bold'} size={'sm'}>
            Comprovante: {getPaymentSlipGroupDetailsResponse?.code}
          </Text>

          <Badge
            color={
              getPaymentSlipGroupDetailsResponse &&
              paymentSlipStatusColor[getPaymentSlipGroupDetailsResponse?.status]
            }
            mb={6}
          >
            {getPaymentSlipGroupDetailsResponse &&
              paymentSlipStatusHumanized[
                getPaymentSlipGroupDetailsResponse?.status
              ]}
          </Badge>

          <Text mt={'md'} size={'sm'} fw={'bold'}>
            OS's
          </Text>

          <DataTable
            height={210}
            noRecordsText="Sem comprovantes"
            withBorder
            borderRadius="sm"
            striped
            highlightOnHover
            records={getPaymentSlipGroupDetailsResponse?.orders ?? []}
            columns={[
              {
                accessor: 'os',
                title: 'OS',
              },
              {
                accessor: 'company.name',
                title: 'Cliente',
              },
              {
                accessor: '',
                title: 'Produto',
                render: ({ product_name, product_material, product_color }) =>
                  `${product_name}, ${product_material}, ${product_color}`,
              },
              {
                accessor: 'quantity',
                title: 'Qtde',
              },
              {
                accessor: 'totalprice',
                title: 'Total',
                render: ({ total_price }) =>
                  formatBRL(total_price.toFixed(2) ?? '0'),
                width: 110,
              },
            ]}
          />

          <Group position="right">
            <Text size={'sm'} fw={'bold'}>
              Total:{' '}
              {formatBRL(handleCalculateOrdesTotalPrice()?.toFixed(2) ?? '0')}
            </Text>
          </Group>

          <Text size={'sm'} fw={'bold'} mt={'md'}>
            Observação
          </Text>
          <Text size={'sm'} color="gray">
            {getPaymentSlipGroupDetailsResponse?.obs ?? '--'}
          </Text>
        </Skeleton>

        <Text mt={'md'} size={'sm'} fw={'bold'}>
          Comprovantes
        </Text>

        <Flex direction={'column'} mb={'md'} mt={'xs'}>
          <Button
            onClick={() =>
              setReviewAddFileOpen((reviewAddFileOpen) => !reviewAddFileOpen)
            }
            rightIcon={
              reviewAddFileOpen ? (
                <IconChevronUp size={20} />
              ) : (
                <IconChevronDown size={20} />
              )
            }
            variant="outline"
          >
            Adicionar comprovante
          </Button>

          <Collapse in={reviewAddFileOpen}>
            <Flex direction={'column'} gap={'xs'}>
              <CustomMultipleFileInput
                formRef={formReviewPaymentSlip}
                variant="default"
                icon={<IconUpload size={20} color="blue" />}
                accept="application/pdf,image/png,image/jpeg,image/jpg"
                placeholder="selecione arquivo por vez"
                inputName="fileReceipts"
              />

              <Group position="right">
                <Button
                  size="sm"
                  variant="outline"
                  disabled={!formReviewPaymentSlip.values.fileReceipts.length}
                  loading={updatePaymentSlipFileLoader || loadingUploadFile}
                  onClick={() => handleUpdatePaymentSlipFile()}
                >
                  Enviar comprovante
                </Button>
              </Group>
            </Flex>
          </Collapse>
        </Flex>

        <Skeleton visible={getPaymentSlipFilesLoader}>
          <MountPaymentSlipCardsReview
            items={getPaymentSlipFilesResponse ?? []}
          />
        </Skeleton>
      </Modal>
      <Modal
        size={410}
        opened={pageModalVisible === 'cancel'}
        onClose={() => handleCloseModal()}
        title={<Text fw="bold">Confirmar</Text>}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
      >
        <Flex direction={'column'} gap={'md'}>
          <Flex gap={'xs'}>
            <Text size={'sm'} fw={'bold'}>
              Comprovante:
            </Text>
            <Text size={'sm'} fw={'bold'} color="red">
              {selectedPaymentSlipGroup?.code}
            </Text>
          </Flex>
          <Text fw={'bold'}>Deseja realmente cancelar este comprovante?</Text>
          <Group position="right">
            <Button color="red" onClick={() => handleCloseModal()}>
              Não
            </Button>
            <Button
              onClick={() => handleCancelPaymentSlipGroup()}
              loading={cancelPaymentSlipGroupLoader}
            >
              Sim
            </Button>
          </Group>
        </Flex>
      </Modal>
      <Modal
        size={410}
        opened={modalInvalidateVisible === 'invalidate-slip'}
        onClose={() => handleCloseInvalidationModal()}
        title={<Text fw="bold">Confirmar</Text>}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
      >
        <Flex direction={'column'} gap={'md'}>
          <Text>Deseja realmente invalidar este comprovante?</Text>
          <Group position="right">
            <Button color="red" onClick={() => handleCloseInvalidationModal()}>
              Não
            </Button>
            <Button onClick={() => handleInvalidatePaymentSlip()}>Sim</Button>
          </Group>
        </Flex>
      </Modal>
    </Page>
  );
}
