import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import {
  ApprovalPaymentSlipGroup,
  CreatePaymentSlip,
  PaymentSlipFileResponse,
  PaymentSlipGroupDetails,
  PaymentSlipGroupOsListParams,
  PaymentSlipPaginatedParams,
  PaymentSlipPaginatedResponse,
  UpdatePaymentSlipFile,
  ValidationPaymentSlip,
} from '../../utils/types/data/services/payment-slip';

export async function paginatedPaymentSlipRequest(
  params: PaymentSlipPaginatedParams,
): Promise<AxiosResponse<PaymentSlipPaginatedResponse>> {
  const response = await api.get<PaymentSlipPaginatedResponse>(
    '/payment-slip-group',
    {
      params,
    },
  );

  return response;
}

export async function paginatedPaymentSlipPendingRequest(
  params: PaymentSlipPaginatedParams,
): Promise<AxiosResponse<PaymentSlipPaginatedResponse>> {
  const response = await api.get<PaymentSlipPaginatedResponse>(
    '/payment-slip-group/pending',
    {
      params,
    },
  );

  return response;
}

export async function paginatedPaymentSlipApprovedRequest(
  params: PaymentSlipPaginatedParams,
): Promise<AxiosResponse<PaymentSlipPaginatedResponse>> {
  const response = await api.get<PaymentSlipPaginatedResponse>(
    '/payment-slip-group/approved',
    {
      params,
    },
  );

  return response;
}

export async function paginatedPaymentSlipReprovedRequest(
  params: PaymentSlipPaginatedParams,
): Promise<AxiosResponse<PaymentSlipPaginatedResponse>> {
  const response = await api.get<PaymentSlipPaginatedResponse>(
    '/payment-slip-group/reproved',
    {
      params,
    },
  );

  return response;
}

export async function paginatedPaymentSlipOsPendingRequest(
  params: PaymentSlipPaginatedParams,
): Promise<AxiosResponse<PaymentSlipPaginatedResponse>> {
  const response = await api.get<PaymentSlipPaginatedResponse>(
    '/payment-slip-group/order-pending',
    {
      params,
    },
  );

  return response;
}

export async function createPaymentSlipRequest(
  data: CreatePaymentSlip,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`/payment-slip-group`, {
    orders: data.orders,
    files: data.files,
  });

  return response;
}

export async function paymentSlipFilesRequest(
  paymentSlipGroupId: number,
): Promise<AxiosResponse<PaymentSlipFileResponse[]>> {
  const response = await api.get<PaymentSlipFileResponse[]>(
    `/payment-slip-group/${paymentSlipGroupId}/slip-files`,
  );

  return response;
}

export async function paymentSlipValidationRequest(
  paymentSlipId: number,
  data: ValidationPaymentSlip,
): Promise<AxiosResponse<{ validation: boolean }>> {
  const response = await api.put<{ validation: boolean }>(
    `/payment-slip-group/${paymentSlipId}/validation`,
    {
      identification: data.identification,
      price: data.price,
    },
  );

  return response;
}

export async function getPaymentSlipGroupDetailsRequest(
  paymentSlipGroupId: number,
): Promise<AxiosResponse<PaymentSlipGroupDetails>> {
  const response = await api.get<PaymentSlipGroupDetails>(
    `/payment-slip-group/${paymentSlipGroupId}/details`,
  );

  return response;
}

export async function paymentSlipInvalidationRequest(
  paymentSlipId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(
    `/payment-slip-group/${paymentSlipId}/invalidate`,
  );

  return response;
}

export async function paymentSlipGroupApprovalRequest(
  paymentSlipGroupId: number,
  data: ApprovalPaymentSlipGroup,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `/payment-slip-group/${paymentSlipGroupId}/approval`,
    {
      obs: data.obs,
      isApproved: data.isApproved,
    },
  );

  return response;
}

export async function updatePaymentSlipFile(
  paymentSlipGroupId: number,
  data: UpdatePaymentSlipFile,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `/payment-slip-group/${paymentSlipGroupId}/update-files`,
    {
      files: data.files,
    },
  );

  return response;
}

export async function paymentSlipGroupValidationByOrderRequest(
  orderId: number,
): Promise<AxiosResponse<{ validation: boolean }>> {
  const response = await api.get<{ validation: boolean }>(
    `/payment-slip-group/${orderId}/slip-validation`,
  );

  return response;
}

export async function getPaymentSlipGroupOsListRequest(
  params: PaymentSlipGroupOsListParams,
): Promise<AxiosResponse<string[]>> {
  const response = await api.get<string[]>(`/payment-slip-group/get-os`, {
    params,
  });

  return response;
}

export async function paginatedPaymentSlipToInvoiceRequest(
  params: PaymentSlipPaginatedParams,
): Promise<AxiosResponse<PaymentSlipPaginatedResponse>> {
  const response = await api.get<PaymentSlipPaginatedResponse>(
    '/payment-slip-group/to-invoice',
    {
      params,
    },
  );

  return response;
}

export async function paymentSlipGroupCancelRequest(
  paymentSlipGroupId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(
    `/payment-slip-group/${paymentSlipGroupId}/cancel`,
  );

  return response;
}
