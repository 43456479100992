import {
  Flex,
  Paper,
  TextInput,
  Select,
  Button,
  Badge,
  Group,
  Checkbox,
  Tooltip,
  Modal,
  Grid,
  Menu,
  Textarea,
  Switch,
  Text,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  IconCheck,
  IconDotsVertical,
  IconEdit,
  IconEye,
  IconMessage,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomLoader } from '../../../components/CustomLoader';
import { useUpdateActivity } from '../../../data/hooks/activities';
import { CustomActivity } from '../../../data/services/activities';
import { ActivityStatus } from '../../../models/activity';
import { dayjsPlugins, formatLocale } from '../../../providers/dayjs-plugins';
import {
  activityComplexityColor,
  activityComplexityHumanized,
  activityStatusColor,
  activityStatusHumanized,
  activityStatusList,
} from '../../../utils/constants/activity';
import { PageMeta } from '../../../utils/types';
import {
  GetFilterCompaniesListResponse,
  GetFilterTeamsListResponse,
  GetFilterUsersListResponse,
} from '../../../utils/types/data/services/filters';

interface VendorActivityListWebProps {
  formFilter: UseFormReturnType<any>;
  companyList: GetFilterCompaniesListResponse[] | undefined;
  activityList: CustomActivity[];
  activityMeta: PageMeta | undefined;
  handleSubmit(): void;
  handlePaginate(page: number): void;
  handleClearFilter(): void;
  currentPage: React.MutableRefObject<number>;
  filterUserList: GetFilterUsersListResponse[];
  teamList: GetFilterTeamsListResponse[];
  navigation: NavigateFunction;
}

type UpdateActivityForm = {
  obs?: string;
  isPrioritary: boolean;
  status?: string;
};

type PageModalState = 'visible' | null;

export function VendorActivityListWeb({
  formFilter,
  activityList,
  activityMeta,
  companyList,
  handleClearFilter,
  handlePaginate,
  handleSubmit,
  currentPage,
  filterUserList,
  teamList,
  navigation,
}: VendorActivityListWebProps) {
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [selectedActivity, setSelectedActivity] = useState<CustomActivity>();
  const { fetch, loading } = useUpdateActivity();

  const updateActivityForm = useForm<UpdateActivityForm>({
    initialValues: {
      isPrioritary: false,
      obs: '',
      status: '',
    },
  });

  function handleSelectActivity(item: CustomActivity) {
    setSelectedActivity(item);
    setPageModal('visible');
  }

  async function handleUpdateActivity(
    values: typeof updateActivityForm.values,
  ) {
    if (selectedActivity) {
      await fetch({
        id: selectedActivity?.id,
        data: {
          isPrioritary: values.isPrioritary,
          obs: values.obs,
          status: values.status ? (values.status as ActivityStatus) : undefined,
        },
      });
      setPageModal(null);
      setSelectedActivity(undefined);
      updateActivityForm.reset();
      handleSubmit();
    }
  }

  useEffect(() => {
    if (selectedActivity) {
      updateActivityForm.setValues({
        isPrioritary: selectedActivity.isPrioritary,
        obs: selectedActivity.obs,
      });
    }
  }, [selectedActivity?.id]);

  return (
    <Flex direction="column" w="99%">
      <CustomLoader loading={loading} />
      <form onSubmit={formFilter.onSubmit(() => handleSubmit())}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex wrap="wrap">
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <TextInput
              label="Identificador"
              placeholder="numero do identificador"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <Select
              clearable
              searchable
              name="team"
              label="Time"
              placeholder="selecione um time"
              data={teamList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('team')}
            />
            <Select
              clearable
              searchable
              name="company"
              label="Cliente"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <Select
              clearable
              searchable
              name="target"
              label="Usuário"
              placeholder="selecione o usuário"
              data={filterUserList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('target')}
            />
            <Select
              clearable
              searchable
              name="status"
              label="Status"
              placeholder="selecione o status"
              data={activityStatusList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('status')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Criado em"
              name="createdAt"
              withAsterisk={false}
              required={false}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.createdAt &&
                new Date(dayjsPlugins(formFilter.values.createdAt).toDate())
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'createdAt',
                      `${dayjsPlugins(value).format('YYYY-MM-DD')}`,
                    )
                  : formFilter.setFieldValue('createdAt', null);
              }}
            />
          </Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClearFilter}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
            <Checkbox
              mt={40}
              label="Mostrar finalizados"
              checked={formFilter.values.showDone}
              {...formFilter.getInputProps('showDone')}
            />
          </Group>
        </Paper>
      </form>
      <DataTable
        minHeight={500}
        noRecordsText="Sem atividades"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={activityMeta?.totalItems}
        recordsPerPage={10}
        onPageChange={handlePaginate}
        records={activityList}
        columns={[
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'internal_number',
            title: 'Identificador',
          },
          {
            accessor: 'teamName',
            title: 'Time',
          },
          {
            accessor: 'companyName',
            title: 'Cliente',
          },
          {
            accessor: 'productGroup',
            title: 'Produto',
            render: ({ product, product_color, product_material }) =>
              `${product}, ${product_color}, ${product_material}`,
          },
          {
            accessor: 'obs',
            title: 'Descrição',
            render: ({ obs }) =>
              obs && (
                <Tooltip maw={200} multiline label={obs} withArrow>
                  <Button variant="subtle" p={0} children={<IconMessage />} />
                </Tooltip>
              ),
          },
          {
            accessor: 'quantity',
            title: 'Quantidade',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={activityStatusColor[status]}>
                {activityStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'complexity',
            title: 'Complexidade',
            render: ({ complexity }) => (
              <Badge color={activityComplexityColor[complexity]}>
                {activityComplexityHumanized[complexity]}
              </Badge>
            ),
          },
          {
            accessor: 'isPrioritary',
            title: 'Prioritário',
            render: ({ isPrioritary }) => (isPrioritary ? `sim` : `não`),
          },
          {
            accessor: 'targetName',
            title: 'Atribuido para',
          },
          {
            accessor: 'createdBy',
            title: 'Criado por',
          },
          {
            accessor: 'createdAt',
            title: 'Criado em',
            render: ({ createdAt }) =>
              createdAt && formatLocale(createdAt, 'DD/MM/YY'),
          },
          {
            accessor: 'menu',
            title: '',
            render: (activityItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    disabled={activityItem.status === ActivityStatus.DONE}
                    onClick={() => handleSelectActivity(activityItem)}
                    icon={<IconEdit size={14} />}
                  >
                    Editar
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      navigation(`/art-activities/${activityItem.id}`)
                    }
                    icon={<IconEye size={14} />}
                  >
                    Ver
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
      <Modal
        opened={pageModal === 'visible'}
        onClose={() => setPageModal(null)}
        title="Editar Atividade"
      >
        <form onSubmit={updateActivityForm.onSubmit(handleUpdateActivity)}>
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={1}>
              <Text fw="bold">OS:</Text>
              <Text>{selectedActivity?.os}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text fw="bold">Identificador:</Text>
              <Text>{selectedActivity?.internal_number}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text fw="bold">Time:</Text>
              <Text>{selectedActivity?.teamName}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text fw="bold">Cliente:</Text>
              <Text>{selectedActivity?.companyName}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text fw="bold">Produto:</Text>
              <Text>{`${selectedActivity?.product}, ${selectedActivity?.product_color}, ${selectedActivity?.product_material}`}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text fw="bold">Quantidade:</Text>
              <Text>{selectedActivity?.quantity}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text fw="bold">Atribuido para:</Text>
              <Text>{selectedActivity?.targetName}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Switch
                checked={updateActivityForm.values.isPrioritary}
                color="teal"
                size="sm"
                label="é Prioritário?"
                thumbIcon={
                  updateActivityForm.values.isPrioritary ? (
                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                  ) : (
                    <IconX size="0.8rem" color="red" stroke={3} />
                  )
                }
                {...updateActivityForm.getInputProps('isPrioritary')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                clearable
                searchable
                name="status"
                label="Status"
                placeholder="selecione o status"
                data={activityStatusList.filter((item) =>
                  [ActivityStatus.DONE].includes(item.value),
                )}
                mb={16}
                mr={8}
                {...updateActivityForm.getInputProps('status')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Observações"
                placeholder="observações sobre o layout"
                name="obs"
                required
                withAsterisk
                value={updateActivityForm.values.obs}
                onChange={(e) =>
                  updateActivityForm.setFieldValue('obs', e.target.value)
                }
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
    </Flex>
  );
}
