/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, ScrollArea, Text, ThemeIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconRefreshDot, IconTestPipe2 } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { CustomLoader } from '../../components/CustomLoader';
import { OrderDetails } from '../../components/OrderDetails';
import { Page } from '../../components/Page';
import { useUrlParams } from '../../hooks/use-url-params';
import { Order } from '../../models/order';
import { TeamConfigAlias } from '../../models/team-config';
import { UserRole, UserType } from '../../models/user';
import { RootState } from '../../providers/store';
import { getOrdersPaginatedDispatcher } from '../../store/orders';
import { AdminHomeWeb } from './admin/web';
import { VendorHomeWeb } from './vendor/web';
import { FinancialHomeWeb } from './financial/web';

export type FormFilterType = {
  status?: string | null;
  finishForecast?: string | null;
  receiptForecast?: string | null;
  dispatchedAt?: string | null;
  company?: string | undefined;
  os?: string | null;
  financialStatus?: string | null;
  internalNumber?: string | null;
  limit: number;
  page: number;
  services?: string | null;
  paymentStatus?: string;
  paymentDeadline?: string;
  createdAt?: string | undefined;
  issueDate?: string;
  financialNote?: string;
  paymentType?: string;
  service?: string | null;
  product?: string;
  quantity?: number;
  engravingText?: string;
  showRework?: boolean;
};

export function OrderFirstContact() {
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.orders,
  );
  const [filtersCollapse, setFiltersCollapse] = useState(false);
  const { user, userTeam } = useSelector((state: RootState) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const currentPageLimit = useRef(10);
  const dispatch = useDispatch<any>();
  const [orderViewModalVisible, setOrderViewModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = useRef(Number(searchParams.get('page')) || 1);

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      services: userTeam?.configs.find(
        (item) => item.alias === TeamConfigAlias.SERVICE_FILTER,
      )?.value,
      page: Number(searchParams.get('page')) || 1,
      company: searchParams.get('company') || '',
      dispatchedAt: searchParams.get('dispatchedAt') || null,
      finishForecast: searchParams.get('finishForecast') || null,
      internalNumber: searchParams.get('internalNumber') || '',
      os: searchParams.get('os') || '',
      financialStatus: searchParams.get('financialStatus') || null,
      receiptForecast: searchParams.get('receiptForecast') || null,
      status: searchParams.get('status') || '',
      paymentDeadline: searchParams.get('paymentDeadline') || undefined,
      paymentStatus: searchParams.get('paymentStatus') || undefined,
      createdAt: searchParams.get('createdAt') || undefined,
      issueDate: searchParams.get('issueDate') || undefined,
      paymentType: searchParams.get('paymentType') || '',
      product: searchParams.get('product') || '',
      quantity: Number(searchParams.get('quantity')) || undefined,
      engravingText: searchParams.get('engravingText') || '',
      service: searchParams.get('service') || null,
      financialNote: searchParams.get('financialNote') || '',
    },
  });

  useUrlParams(formFilter, currentPage, searchParams, setSearchParams);

  function handleSubmit() {
    currentPage.current = 1;
    getOrderPaginated();
  }

  function handleClearFilter() {
    setSearchParams(new URLSearchParams());
    Object.entries(formFilter.values).forEach(([key]) => {
      if (key !== 'page' && key !== 'limit') {
        formFilter.setFieldValue(key, '');
      }
    });
    currentPage.current = 1;
    currentPageLimit.current = 10;
    getOrderPaginated(true);
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getOrderPaginated();
  }

  async function getOrderPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(
        getOrdersPaginatedDispatcher({
          page: 1,
          limit: 10,
          services: validatedFilter.services,
          firstContact: true,
        }),
      );
    } else {
      dispatch(
        getOrdersPaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
          firstContact: true,
        }),
      );
    }
  }

  useEffect(() => {
    getOrderPaginated();
  }, []);

  return (
    <Page title="Pedidos [Primeiros Contatos]">
      <CustomLoader loading={loading} />
      {user?.role === UserRole.VENDOR && (
        <VendorHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
        />
      )}
      {user?.role === UserRole.FINANCIAL && (
        <FinancialHomeWeb
          currentPage={currentPage}
          formFilter={formFilter}
          handleChangePageLimit={handleChangePageLimit}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
        />
      )}
      {user?.type === UserType.MASTER && (
        <AdminHomeWeb
          handleChangePageLimit={handleChangePageLimit}
          currentPage={currentPage}
          formFilter={formFilter}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          items={items}
          meta={meta}
          handleClear={handleClearFilter}
          setSelectedOrder={setSelectedOrder}
          setOrderViewModalVisible={setOrderViewModalVisible}
          filtersCollapse={filtersCollapse}
          setFiltersCollapse={setFiltersCollapse}
        />
      )}
      <Modal
        opened={orderViewModalVisible}
        onClose={() => setOrderViewModalVisible(false)}
        title={
          <Text fw="bold">
            Detalhes do Pedido
            {selectedOrder?.rework && user?.role !== UserRole.COMPANY ? (
              <ThemeIcon ml={10} color="grape" variant="light">
                <IconRefreshDot size={24} />
              </ThemeIcon>
            ) : (
              ''
            )}
            {selectedOrder?.isSample && (
              <ThemeIcon ml={10} color="green" variant="light">
                <IconTestPipe2 size={24} />
              </ThemeIcon>
            )}
          </Text>
        }
        size="lg"
      >
        <ScrollArea h={600}>
          {selectedOrder && (
            <OrderDetails
              sections={[
                'basic',
                'prices',
                'service-details',
                'historic',
                'dates',
                'payment',
                'order-receipt-files',
                'problem-reported',
              ]}
              orderId={selectedOrder?.id}
            />
          )}
        </ScrollArea>
      </Modal>
    </Page>
  );
}
