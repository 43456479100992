import { AxiosResponse } from 'axios';
import {
  OrderProduction,
  OrderProductionReport,
  ProductionType,
} from '../../models/order-production';
import { api } from '../../providers/base-api';
import {
  AddOrderProductionManuallyRequestData,
  ChangeManyOrderProductionRequestData,
  IsPartialFinishedResponse,
  StopProductionData,
} from '../../utils/types/data/services/order-productions';
import { PaginatedResponse } from '../../utils/types';

export type CreateOrderProductionData = {
  type: ProductionType;
  technicalName: string;
  description?: string;
  machine?: string;
  supervisor?: string;
};

export type GetOrderProductionReportParam = {
  company?: number;
  technical?: number;
  service?: number;
  machine?: string;
  supervisor?: string;
  startDate: string;
  endDate: string;
  os?: string;
  page?: number;
  limit?: number;
};

export async function getOrderProductionRequest(
  orderId: number,
): Promise<OrderProduction[]> {
  const response = await api.get<OrderProduction[]>(
    `order-productions/${orderId}`,
  );

  return response.data;
}

export async function getLastOrderProductionRequest(
  orderId: number,
): Promise<OrderProduction> {
  const response = await api.get<OrderProduction>(
    `order-productions/last/${orderId}`,
  );

  return response.data;
}

export async function setOrderProductionRequest(
  orderId: number,
  payload: CreateOrderProductionData,
): Promise<OrderProduction> {
  const response = await api.post<OrderProduction>(
    `order-productions/${orderId}`,
    payload,
  );

  return response.data;
}

export async function setOutOrderProductionRequest(
  id: number,
): Promise<OrderProduction> {
  const response = await api.put<OrderProduction>(
    `order-productions/set-out/${id}`,
  );

  return response.data;
}

export async function setOutProductionOrderProductionRequest(
  id: number,
  data: StopProductionData,
): Promise<AxiosResponse<OrderProduction>> {
  const response = await api.put<OrderProduction>(
    `order-productions/set-out-production/${id}`,
    data,
  );

  return response;
}

export async function getOrderProductionReportRequest(
  params: GetOrderProductionReportParam,
): Promise<AxiosResponse<PaginatedResponse<OrderProductionReport>>> {
  const response = await api.get<PaginatedResponse<OrderProductionReport>>(
    `order-productions/report`,
    {
      params,
    },
  );

  return response;
}

export async function changeManyOrderProductionRequest(
  id: number,
  data: ChangeManyOrderProductionRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`order-productions/many/${id}`, data);

  return response;
}

export async function serOrderProductionPartialFinishRequest(
  orderId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `order-productions/partial-finish/${orderId}`,
  );

  return response;
}

export async function getOrderProductionIsPartialFinishedRequest(
  orderId: number,
): Promise<AxiosResponse<IsPartialFinishedResponse>> {
  const response = await api.get<IsPartialFinishedResponse>(
    `order-productions/partial-finish/${orderId}`,
  );

  return response;
}

export async function addOrderProductionManuallyRequest(
  orderId: number,
  data: AddOrderProductionManuallyRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `order-productions/manual/${orderId}`,
    data,
  );

  return response;
}
