import { Button, Collapse, Flex, Group, Paper } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconFilter, IconFilterOff } from '@tabler/icons';
import { ReactNode } from 'react';

type CustomPageFilterProps = {
  isCollapsed: boolean;
  formFilter: UseFormReturnType<any, (values: any) => any>;
  customInputs?: () => ReactNode;
  handleSubmit(values: any): void;
  handleClear: () => void;
};

type CustomPageFilterButtonProps = {
  onClick: () => void;
  isCollapsed: boolean;
};

const FilterButton = ({
  isCollapsed,
  ...props
}: CustomPageFilterButtonProps) => {
  return (
    <Button
      variant="light"
      color="blue"
      leftIcon={isCollapsed ? <IconFilterOff /> : <IconFilter />}
      {...props}
    >
      Filtros
    </Button>
  );
};

const CustomPageFilter = ({
  isCollapsed,
  formFilter,
  handleSubmit,
  handleClear,
  customInputs,
}: CustomPageFilterProps) => {
  return (
    <Collapse in={isCollapsed}>
      <form onSubmit={formFilter.onSubmit((values) => handleSubmit(values))}>
        <Paper p={16} mb={16} withBorder>
          <Flex wrap="wrap">{customInputs && customInputs()}</Flex>
          <Group>
            <Button
              mt={25}
              color="ltpBlue.9"
              type="button"
              variant="outline"
              onClick={handleClear}
            >
              Limpar
            </Button>
            <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
              Filtrar
            </Button>
          </Group>
        </Paper>
      </form>
    </Collapse>
  );
};

CustomPageFilter.Button = FilterButton;

export default CustomPageFilter;
