import 'dayjs/locale/pt-br';
import { DatePickerProps } from '@mantine/dates';
import { DatePicker } from '@mantine/dates';

export function CustomDatePicker(props: DatePickerProps) {
  return (
    <DatePicker
      inputFormat="DD/MM/YYYY"
      withAsterisk
      mb={16}
      locale="pt-br"
      {...props}
    />
  );
}
