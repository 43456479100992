import { useForm } from '@mantine/form';
import { useValidationPaymentSlip } from '../../../data/hooks/payment-slip';
import { formatBRL, realToUSCash } from '../../../utils/helpers';
import { PaymentSlipFileResponse } from '../../../utils/types/data/services/payment-slip';
import { PaymentSlipApprovalStatus } from '../../../models/payment-slip';
import { useEffect } from 'react';
import { Button, Flex, Group, Image, Text, TextInput } from '@mantine/core';
import { IconDownload } from '@tabler/icons';

export function PaymentSlipFileItem({
  item,
  handleOpenInvalidationModal,
  invalidatePaymentSlipLoader,
  downloadFile,
}: {
  item: PaymentSlipFileResponse;
  handleOpenInvalidationModal: (item: PaymentSlipFileResponse) => void;
  invalidatePaymentSlipLoader: boolean;
  downloadFile: (fileUrl: string, fileName: string) => void;
}) {
  const {
    fetch: validationPaymentSlipFetcher,
    loading: validationPaymentSlipLoader,
    response: validationPaymentSlipResponse,
  } = useValidationPaymentSlip();

  async function handleValidationPaymentSlipFile(
    values: typeof formValidationSlip.values,
    paymentSlipId: number,
  ) {
    validationPaymentSlipFetcher({
      data: {
        identification: values.identification,
        price: realToUSCash(values.price),
      },
      paymentSlipId,
    });
  }

  const formValidationSlip = useForm({
    initialValues: {
      identification: '',
      price: '',
    },
  });

  const isInvalidSlip =
    [
      PaymentSlipApprovalStatus.APPROVED,
      PaymentSlipApprovalStatus.REPROVED,
    ].includes(item.approval) || validationPaymentSlipResponse?.validation;

  useEffect(() => {
    formValidationSlip.setValues({
      identification: item.identification,
      price: item.price,
    });
  }, [item]);

  return (
    <Flex gap={'sm'}>
      <Flex direction={'column'} gap={'sm'} justify={'center'} align={'center'}>
        <Image
          radius="md"
          src={item.file_url}
          alt="Payment slip file"
          width={180}
          height={220}
          fit="cover"
          withPlaceholder
        />
        <Button
          variant="subtle"
          onClick={() => downloadFile(item.file_url, item.file_name)}
        >
          <IconDownload size={24} color="blue" />
        </Button>
      </Flex>
      <Flex direction={'column'} w="99%">
        <form
          onSubmit={formValidationSlip.onSubmit((values) =>
            handleValidationPaymentSlipFile(values, item.id),
          )}
        >
          <Flex direction={'column'} gap={'sm'}>
            <TextInput
              disabled={isInvalidSlip}
              autoComplete="off"
              name="identification"
              required
              placeholder="ID transação"
              {...formValidationSlip.getInputProps('identification')}
            />
            <TextInput
              disabled={isInvalidSlip}
              autoComplete="off"
              name="price"
              required
              placeholder="Valor transação"
              value={formatBRL(formValidationSlip.values.price ?? '0')}
              onChange={(e) =>
                formValidationSlip.setFieldValue(
                  'price',
                  formatBRL(e.target.value ?? '0'),
                )
              }
            />

            <Group position="right">
              <Button
                size="xs"
                variant="outline"
                color="red"
                disabled={isInvalidSlip}
                onClick={() => handleOpenInvalidationModal(item)}
                loading={invalidatePaymentSlipLoader}
              >
                Comprovante inválido
              </Button>
              <Button
                size="xs"
                type="submit"
                disabled={isInvalidSlip}
                loading={validationPaymentSlipLoader}
              >
                Validar
              </Button>
            </Group>
          </Flex>

          <Group>
            <Group mt={'xl'}>
              {[PaymentSlipApprovalStatus.APPROVED].includes(item.approval) && (
                <Text color="green" size={'xs'} fw={'bold'}>
                  *Comprovante válido
                </Text>
              )}
              {[PaymentSlipApprovalStatus.REPROVED].includes(item.approval) && (
                <Text color="red" size={'xs'} fw={'bold'}>
                  *Comprovante inválido
                </Text>
              )}
            </Group>

            {validationPaymentSlipResponse &&
              (validationPaymentSlipResponse.validation ? (
                <Text color="green" size={'xs'} fw={'bold'}>
                  *Comprovante válido
                </Text>
              ) : (
                <Text color="red" size={'xs'} fw={'bold'}>
                  *Comprovante inválido
                </Text>
              ))}
          </Group>
        </form>
      </Flex>
    </Flex>
  );
}
