import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Select,
  Stepper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconPlus } from '@tabler/icons';
import { useEffect, useState } from 'react';

import { CompanyDocItem } from '../../components/CompanyDocItem';
import { CustomLoader } from '../../components/CustomLoader';
import {
  rootNavigate,
  rootNavigateGoBack,
} from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { useCreateCompany } from '../../data/hooks/companies';
import { useAddCompanyDocuments } from '../../data/hooks/company-documents';
import { getAddressByCepOnProxy } from '../../data/services/cep';
import { getTeamsRequest } from '../../data/services/teams';
import { CompanyCategory } from '../../models/company';
import { Team } from '../../models/team';
import { errorNotification } from '../../providers/mantine-notifications';
import {
  companyCategoryOptionList,
  docTypeList,
} from '../../utils/constants/company';
import { AppRoutePaths } from '../../utils/enums';
import {
  clearMask,
  formatCEP,
  formatCNPJ,
  formatCPF,
  formatPhone,
} from '../../utils/formater';
import { AddCompanyDocumentsData } from '../../utils/types/data/services/company-documents';

type CompanyDocumentItem = AddCompanyDocumentsData & { onEdit: boolean };

export function CompanyCreate() {
  const [pageModal, setPageModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [teamList, setTeamList] = useState<Team[]>();
  const [currentStep] = useState(0);
  const [companyDocs, setCompanyDocs] = useState<CompanyDocumentItem[]>([]);
  const { fetch: createCompanyFetcher, loading: createCompanyLoader } =
    useCreateCompany();
  const { fetch: addCompanyDocFetcher, loading: addCompanyDocLoader } =
    useAddCompanyDocuments();

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      team: undefined,
      category: '',
      phone: '',
      whatsapp: '',
    },
  });

  const documentForm = useForm({
    initialValues: {
      id: '',
      document: '',
      documentType: 'cnpj',
      nomeFantasia: '',
      razaoSocial: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      email: '',
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressCity: '',
      addressState: '',
      addressCountry: '',
      addressZipCode: '',
      addressStateIBGECode: '',
    },
  });

  function setNewCompanyDoc() {
    documentForm.reset();
    setPageModal(true);
  }

  function handleAddDocument(values: typeof documentForm.values) {
    const cloneList = companyDocs;
    const foundIndex = cloneList.findIndex(
      (item) => item.document === clearMask(values.document),
    );

    if (foundIndex !== -1) {
      cloneList[foundIndex] = {
        ...values,
        document: clearMask(values.document),
        id: undefined,
        onEdit: false,
        company: 0,
      };
    } else {
      cloneList.push({
        ...values,
        document: clearMask(values.document),
        id: undefined,
        onEdit: false,
        company: 0,
      });
    }
    setCompanyDocs([...cloneList]);
    documentForm.reset();
    setPageModal(false);
  }

  function handleSetDocumentToEdit(index: number) {
    const cloneList = companyDocs;
    cloneList[index].onEdit = true;
    setCompanyDocs([...cloneList]);

    const foundedItem = cloneList[index];

    documentForm.setValues({
      ...foundedItem,
      document:
        foundedItem.documentType === 'cnpj'
          ? formatCNPJ(foundedItem.document ?? '')
          : formatCPF(foundedItem.document ?? ''),
      id: '',
    });
    setPageModal(true);
  }

  function handleDeleteDocument(index: number) {
    const cloneList = companyDocs;
    cloneList.splice(index, 1);
    setCompanyDocs([...cloneList]);
  }

  async function getAddress(cep: string) {
    documentForm.setFieldValue('addressCity', '');
    documentForm.setFieldValue('addressState', '');
    documentForm.setFieldValue('addressStreet', '');

    setPageLoading(true);
    const address = await getAddressByCepOnProxy(cep.replace('-', ''));
    setPageLoading(false);
    if (address) {
      documentForm.setFieldValue('addressCity', address?.city);
      documentForm.setFieldValue('addressCountry', 'BR');
      documentForm.setFieldValue('addressState', address?.state);
      documentForm.setFieldValue('addressStreet', address?.address);
    }
  }

  async function handleSubmit(values: typeof form.values) {
    if (!companyDocs.length) {
      errorNotification({
        title: 'Necessário adicionar ao menos um documento',
        message: 'revise os dados',
      });
      return;
    }

    await createCompanyFetcher({
      data: {
        ...values,
        team: { id: Number(values.team) },
        category: values.category as CompanyCategory,
        phone: clearMask(values.phone),
        whatsapp: clearMask(values.whatsapp),
        name: values.name,
      },
      onSuccess: async (res) => {
        await addCompanyDocFetcher({
          data: {
            documents: companyDocs.map((docItem) => ({
              ...docItem,
              company: res.id,
              id: undefined,
              onEdit: undefined,
            })),
          },
        });

        setCompanyDocs([]);
        form.reset();
        documentForm.reset();
        rootNavigate(AppRoutePaths.COMPANY);
      },
    });
  }

  async function getTeams() {
    const response = await getTeamsRequest();
    setTeamList(response);
  }

  const teamOptions = teamList?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));

  useEffect(() => {
    getTeams();
  }, []);

  return (
    <Page>
      <Container>
        <CustomLoader
          loading={createCompanyLoader || addCompanyDocLoader || pageLoading}
        />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Criar Empresa
        </Title>
        <Divider m={16} />
        <Stepper
          color="orange"
          orientation="horizontal"
          iconSize={37}
          active={currentStep}
        >
          <Stepper.Step label="Empresa" description="dados da empresa">
            <Grid gutter="xs" maw={900} columns={4}>
              <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                <Button
                  type="submit"
                  leftIcon={<IconPlus />}
                  onClick={setNewCompanyDoc}
                  color="orange"
                  variant="light"
                  mt={8}
                  h={30}
                >
                  Novo CNPJ
                </Button>
              </Grid.Col>
              <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                <Text>CNPJs</Text>
                <Flex wrap="wrap">
                  {companyDocs.map((docItem, index) => (
                    <CompanyDocItem
                      key={`doc-${index}`}
                      docItem={docItem}
                      handleDeleteDocument={() => handleDeleteDocument(index)}
                      handleSetDocumentToEdit={() =>
                        handleSetDocumentToEdit(index)
                      }
                    />
                  ))}
                </Flex>
              </Grid.Col>
            </Grid>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Grid gutter="xs" maw={900} columns={4}>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Nome"
                    placeholder="nome da empresa"
                    type="text"
                    name="name"
                    {...form.getInputProps('name')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    label="Telefone"
                    placeholder="telefone de contato"
                    type="text"
                    name="phone"
                    withAsterisk
                    required
                    onChange={(e) =>
                      form.setFieldValue('phone', formatPhone(e.target.value))
                    }
                    value={form.values.phone}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    label="WhatsApp"
                    placeholder="WhatsApp para contato"
                    type="text"
                    name="whatsapp"
                    withAsterisk
                    required
                    onChange={(e) =>
                      form.setFieldValue(
                        'whatsapp',
                        formatPhone(e.target.value),
                      )
                    }
                    value={form.values.whatsapp}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <TextInput
                    required
                    withAsterisk
                    label="Email para notificações"
                    placeholder="email para notificações"
                    type="text"
                    name="email"
                    {...form.getInputProps('email')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <Select
                    searchable
                    required
                    withAsterisk
                    name="team"
                    label="Time"
                    placeholder="selecione o time responsável"
                    data={teamOptions ?? []}
                    {...form.getInputProps('team')}
                  />
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
                  <Select
                    required
                    withAsterisk
                    name="category"
                    label="Categoria"
                    placeholder="selecione a categorização da empresa"
                    data={companyCategoryOptionList}
                    {...form.getInputProps('category')}
                  />
                </Grid.Col>
              </Grid>
              <Group position="right" mt="xl">
                <Button color="ltpBlue.9" type="submit">
                  Salvar
                </Button>
              </Group>
            </form>
          </Stepper.Step>
        </Stepper>
      </Container>
      <Modal
        title="CNPJ"
        size={600}
        opened={pageModal}
        onClose={() => setPageModal(false)}
      >
        <form onSubmit={documentForm.onSubmit(handleAddDocument)}>
          <Grid gutter="xs" columns={4}>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Nome Fantasia"
                placeholder="nome fantasia da empresa"
                type="text"
                name="nomeFantasia"
                {...documentForm.getInputProps('nomeFantasia')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Razão Social"
                placeholder="razão social da empresa"
                type="text"
                name="razaoSocial"
                {...documentForm.getInputProps('razaoSocial')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <Select
                required
                withAsterisk
                name="documentType"
                label="Tipo de Documento"
                placeholder="selecione o tipo de documento"
                data={docTypeList}
                onChange={(value) => {
                  documentForm.setFieldValue('documentType', String(value));
                  documentForm.setFieldValue('document', '');
                }}
                value={documentForm.values.documentType}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label={
                  documentForm.values.documentType === 'cnpj' ? 'CNPJ' : 'CPF'
                }
                placeholder="documento da empresa"
                type="text"
                name="name"
                onChange={(e) =>
                  documentForm.setFieldValue(
                    'document',
                    documentForm.values.documentType === 'cnpj'
                      ? formatCNPJ(e.target.value)
                      : formatCPF(e.target.value),
                  )
                }
                value={documentForm.values.document}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email cadastral"
                type="text"
                name="email"
                {...documentForm.getInputProps('email')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                label="Inscrição Estadual"
                placeholder="número da inscrição"
                type="text"
                name="inscricaoEstadual"
                {...documentForm.getInputProps('inscricaoEstadual')}
              />
            </Grid.Col>
            <Grid.Col mb={16} xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Inscrição Municipal"
                placeholder="número da inscrição"
                type="text"
                name="inscricaoMunicipal"
                {...documentForm.getInputProps('inscricaoMunicipal')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Text size={16} color="dark.4">
                Endereço
              </Text>
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="CEP"
                placeholder="digite o CEP do endereço"
                type="text"
                name="addressZipCode"
                onChange={async (e) => {
                  documentForm.setFieldValue(
                    'addressZipCode',
                    formatCEP(e.target.value),
                  );
                  if (e.target.value.length == 9) {
                    await getAddress(e.target.value);
                  }
                }}
                value={documentForm.values.addressZipCode}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Endereço"
                placeholder="digite o endereço"
                type="text"
                name="addressStreet"
                {...documentForm.getInputProps('addressStreet')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Número"
                placeholder="digite o número"
                type="text"
                name="addressNumber"
                {...documentForm.getInputProps('addressNumber')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                label="Complemento"
                placeholder="digite o complemento do endereço"
                type="text"
                name="addressComplement"
                {...documentForm.getInputProps('addressComplement')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Estado"
                placeholder="digite o estado ex: SP"
                type="text"
                name="addressState"
                {...documentForm.getInputProps('addressState')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Cidade"
                placeholder="digite a cidade"
                type="text"
                name="addressCity"
                {...documentForm.getInputProps('addressCity')}
              />
            </Grid.Col>
            <Grid.Col xl={2} lg={2} md={2} sm={4} xs={4}>
              <TextInput
                required
                withAsterisk
                label="Código de Estado do IBGE"
                placeholder="digite o código"
                type="text"
                name="addressCity"
                {...documentForm.getInputProps('addressStateIBGECode')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button
              type="submit"
              onClick={() => {}}
              color="orange"
              variant="light"
              mt={8}
              h={30}
            >
              Adicionar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
