/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Center,
  FileInput,
  FileInputProps,
  Image,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPhoto, IconX } from '@tabler/icons';

interface CustomMultipleFileInputProps extends FileInputProps {
  formRef: UseFormReturnType<any>;
  inputName?: string;
}

interface FileItemProps {
  formRef: UseFormReturnType<any>;
  inputName: string;
  file: File | null;
}

function FileItem({ file, inputName, formRef }: FileItemProps) {
  function removeFile() {
    formRef.setFieldValue(inputName, null);
  }

  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: '3px 7px',
        margin: '4px',
        borderRadius: theme.radius.sm,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      })}
    >
      <Button
        onClick={removeFile}
        style={{
          position: 'absolute',
          right: 10,
          top: 10,
          zIndex: 10,
        }}
        ml={8}
        p={4}
        h={20}
        color="red"
        variant="light"
      >
        <IconX size={12} />
      </Button>
      {file && (
        <Image
          width={100}
          height={100}
          src={URL.createObjectURL(file)}
          fit="contain"
          withPlaceholder
        />
      )}
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 100,
          display: 'inline-block',
        }}
      >
        {file?.name}
      </span>
    </Center>
  );
}

export function CustomFileInput({
  formRef,
  inputName = 'file',
  ...props
}: CustomMultipleFileInputProps) {
  return (
    <>
      {formRef.values[inputName] && (
        <FileItem
          inputName={inputName}
          formRef={formRef}
          file={formRef.values?.[inputName]}
        />
      )}
      <FileInput
        onChange={(file) => file && formRef.setFieldValue(inputName, file)}
        {...props}
      />
    </>
  );
}

function Value({ file }: { file: File }) {
  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: '3px 7px',
        borderRadius: theme.radius.sm,
      })}
    >
      <IconPhoto size={14} style={{ marginRight: 5 }} />
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 100,
          display: 'inline-block',
        }}
      >
        {file.name}
      </span>
    </Center>
  );
}

export const ValueComponent: FileInputProps['valueComponent'] = ({
  value,
}: any) => {
  return <Value file={value} />;
};
