import {
  Badge,
  Button,
  Flex,
  Group,
  Menu,
  MultiSelect,
  Select,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconDotsVertical, IconEye, IconShoppingCartPlus } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormFilterType } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';

import {
  getFilterCompaniesListRequest,
  getFilterServicesListRequest,
} from '../../../data/services/filters';
import { Order, Order as OrderModel } from '../../../models/order';
import { TeamConfigAlias } from '../../../models/team-config';
import { dayjsPlugins, formatLocale } from '../../../providers/dayjs-plugins';
import { errorNotification } from '../../../providers/mantine-notifications';
import { RootState } from '../../../providers/store';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusList,
} from '../../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
  orderFinancialStatusList,
  orderPaymentTypeHumanized,
  orderPaymentTypeList,
} from '../../../utils/constants/order';
import { formatBRL, getOrderPrices } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
} from '../../../utils/types/data/services/filters';
import OrderListPageFilter from '../../OrderList/sub-components/page-filter';

interface VendorHomeWebProps {
  items: OrderModel[];
  handlePaginate(page: number): void;
  handleChangePageLimit(limit: number): void;
  handleSubmit(values: FormFilterType): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  filtersCollapse: boolean;
  handleClear: () => void;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setFiltersCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

export function VendorHomeWeb({
  items,
  meta,
  handlePaginate,
  handleChangePageLimit,
  currentPage,
  formFilter,
  handleSubmit,
  handleClear,
  setSelectedOrder,
  setOrderViewModalVisible,
  filtersCollapse,
  setFiltersCollapse,
}: VendorHomeWebProps) {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);

  const [serviceList, setServiceList] = useState<
    GetFilterServicesListResponse[]
  >([]);

  const { user, userTeam } = useSelector((state: RootState) => state.auth);

  const teamConfilterFilter = useMemo(() => {
    return userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.TEAM_FILTER,
    );
  }, []);

  async function getCompanies() {
    const response = await getFilterCompaniesListRequest({
      team:
        teamConfilterFilter?.value === 'false' ? undefined : Number(user?.team),
    });
    setCompanyList(response);
  }

  function handleViewOrder(order?: Order) {
    setSelectedOrder(order);
    setOrderViewModalVisible(true);
  }

  async function getServices() {
    try {
      const response = await getFilterServicesListRequest();
      setServiceList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar serviços.',
        message: 'tente novamente',
      });
    }
  }

  useEffect(() => {
    getServices();
    getCompanies();
  }, []);

  return (
    <Flex direction="column" w="99%">
      <Group mb={16} position="right">
        <OrderListPageFilter.Button
          isCollapsed={filtersCollapse}
          onClick={() => setFiltersCollapse(!filtersCollapse)}
        />
      </Group>
      <OrderListPageFilter
        companyList={companyList}
        formFilter={formFilter}
        handleClear={handleClear}
        handleSubmit={handleSubmit}
        isCollapsed={filtersCollapse}
        serviceList={serviceList}
        customInputs={() => (
          <>
            <TextInput
              label="Identificador"
              placeholder="código interno do pedido"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <TextInput
              label="Produto"
              placeholder="nome do produto"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('product')}
            />
            <Select
              required
              name="paymentType"
              withAsterisk
              label="Forma de pagamento"
              placeholder="selecione uma forma de pagamento"
              data={orderPaymentTypeList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('paymentType')}
            />
            {companyList.length && (
              <MultiSelect
                clearable
                searchable
                name="company"
                label="Clientes"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                defaultValue={
                  formFilter.values.company &&
                  formFilter.values.company.split(',')
                }
                onChange={(values) => {
                  formFilter.setFieldValue('company', values.toString());
                }}
              />
            )}
            <Select
              searchable
              clearable
              name="service"
              label="Serviço"
              placeholder="selecione um serviço"
              defaultValue={serviceList[formFilter.values.service]?.id}
              data={serviceList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('service')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Data de Retirada"
              lang="br"
              name="receiptForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.receiptForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.receiptForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'receiptForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('receiptForecast', '');
              }}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Prev. de Finalização"
              name="finishForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.finishForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.finishForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'finishForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('finishForecast', '');
              }}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Criado em"
              placeholder="selecione um intervalo"
              mr={8}
              defaultValue={
                formFilter.values.createdAt && [
                  new Date(
                    dayjsPlugins(
                      formFilter.values.createdAt.split(',')[0],
                    ).toDate(),
                  ),
                  new Date(
                    dayjsPlugins(
                      formFilter.values.createdAt.split(',')[1],
                    ).toDate(),
                  ),
                ]
              }
              onChange={(value) => {
                value[0] && value[1]
                  ? formFilter.setFieldValue(
                      'createdAt',
                      `${dayjsPlugins(value[0]).format(
                        'YYYY-MM-DD',
                      )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                    )
                  : formFilter.setFieldValue('createdAt', undefined);
              }}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Faturado em"
              placeholder="selecione um intervalo"
              mr={8}
              defaultValue={
                formFilter.values.issueDate && [
                  new Date(
                    dayjsPlugins(
                      formFilter.values.issueDate.split(',')[0],
                    ).toDate(),
                  ),
                  new Date(
                    dayjsPlugins(
                      formFilter.values.issueDate.split(',')[1],
                    ).toDate(),
                  ),
                ]
              }
              onChange={(value) => {
                value[0] && value[1]
                  ? formFilter.setFieldValue(
                      'issueDate',
                      `${dayjsPlugins(value[0]).format(
                        'YYYY-MM-DD',
                      )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                    )
                  : formFilter.setFieldValue('issueDate', undefined);
              }}
            />
            <MultiSelect
              maw={240}
              clearable
              searchable
              name="status"
              label="Status"
              placeholder="selecione o status do pedido"
              data={statusList}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.status && formFilter.values.status.split(',')
              }
              onChange={(values) => {
                formFilter.setFieldValue('status', values.toString());
              }}
            />
            <MultiSelect
              clearable
              name="financialStatus"
              label="Status Fiscal"
              placeholder="selecione o status de fiscal"
              data={orderFinancialStatusList}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.financialStatus &&
                formFilter.values.financialStatus.split(',')
              }
              onChange={(values) => {
                formFilter.setFieldValue('financialStatus', values.toString());
              }}
            />
          </>
        )}
      />
      <DataTable
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 300]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        height="75vh"
        noRecordsText="Sem pedidos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        recordsPerPage={meta?.itemsPerPage ?? 10}
        onPageChange={handlePaginate}
        records={items}
        columns={[
          {
            accessor: 'types',
            title: '',
            render: () => (
              <ThemeIcon color="green" variant="light">
                <IconShoppingCartPlus size={24} />
              </ThemeIcon>
            ),
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'internalNumber',
            title: 'Identificador',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderStatusColor[status]} mb={6}>
                {orderStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'financialStatus',
            title: 'Status Fiscal',
            render: ({ financialStatus }) =>
              financialStatus && (
                <Badge color={financialStatusColor[financialStatus]} mb={6}>
                  {financialStatusTypeHumanized[financialStatus]}
                </Badge>
              ),
          },
          {
            accessor: 'issueDate',
            title: 'Faturado em',
            render: ({ issueDate }) =>
              issueDate && formatLocale(issueDate, 'DD/MM/YY HH:mm'),
          },
          {
            accessor: 'service.name',
            title: 'Serviço',
          },
          {
            accessor: 'engravingText',
            title: 'Logotipo',
          },
          {
            accessor: 'quantity',
            title: 'Qtde',
            width: 60,
          },
          {
            accessor: 'product',
            title: 'Produto',
          },
          {
            accessor: 'receiptedAt',
            title: 'Recebido em',
            render: ({ receiptedAt }) =>
              receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'finishForecast',
            title: 'Prev. Finalização',
            render: ({ finishForecast }) =>
              finishForecast && formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'createdAt',
            title: 'Criado em',
            render: ({ createdAt }) =>
              createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'paymentType',
            title: 'Forma de Pagamento',
            render: ({ paymentType }) =>
              paymentType && orderPaymentTypeHumanized[paymentType],
            width: 110,
          },
          {
            accessor: 'totalprice',
            title: 'Preço total',
            render: (orderItem) => `${getOrderPrices(orderItem).totalPrice}`,
            width: 110,
          },
          {
            accessor: 'vendor-porter-menu',
            title: '',
            render: (orderItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() => handleViewOrder(orderItem)}
                    icon={<IconEye size={14} />}
                  >
                    Ver
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
      <Group position="right">
        <Text>
          total: {formatBRL(Number(meta?.custom?.totalPrice).toFixed(2))}
        </Text>
      </Group>
    </Flex>
  );
}
