import {
  Badge,
  Button,
  Flex,
  Group,
  Menu,
  Select,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconCirclePlus,
  IconDotsVertical,
  IconEye,
  IconFileSearch,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect } from 'react';
import { PaymentSlipPageModalVisible } from '..';
import CustomPageFilter from '../../../components/CustomPageFilter';
import { PaymentSlipApprovalStatus } from '../../../models/payment-slip';
import {
  paymentSlipStatusColor,
  paymentSlipStatusHumanized,
  paymentSlipStatusList,
} from '../../../utils/constants';
import { formatDateTime } from '../../../utils/helpers';
import { PaymentSlipResponse } from '../../../utils/types/data/services/payment-slip';

interface CompanyPaymentSlipProps {
  filtersCollapse: boolean;
  setFiltersCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  setPageModalVisible: React.Dispatch<
    React.SetStateAction<PaymentSlipPageModalVisible>
  >;
  filterForm: UseFormReturnType<any>;
  handleGetPaymentSlipGroup: (paymentSlipGroup: PaymentSlipResponse) => void;
  items?: PaymentSlipResponse[];
  loading: boolean;
  getPaymentSlipsGroups: () => void;
  handleFilter: () => void;
  handleChangePage: (page: number) => void;
  handleChangePageLimit: (recordRange: number) => void;
  handleClear: () => void;
  currentPage: React.MutableRefObject<number>;
}

export function CompanyPaymentSlip({
  filterForm,
  filtersCollapse,
  setFiltersCollapse,
  setPageModalVisible,
  handleGetPaymentSlipGroup,
  items,
  loading,
  getPaymentSlipsGroups,
  handleFilter,
  handleChangePage,
  handleChangePageLimit,
  handleClear,
  currentPage,
}: CompanyPaymentSlipProps) {
  useEffect(() => {
    getPaymentSlipsGroups();
  }, []);

  return (
    <Flex direction="column" w="99%">
      <Group position="right" mb={16}>
        <CustomPageFilter.Button
          isCollapsed={filtersCollapse}
          onClick={() => setFiltersCollapse(!filtersCollapse)}
        />
        <Button
          color="ltpBlue.9"
          leftIcon={<IconCirclePlus />}
          onClick={() => setPageModalVisible('create')}
        >
          Novo
        </Button>
      </Group>

      <CustomPageFilter
        formFilter={filterForm}
        handleClear={handleClear}
        handleSubmit={handleFilter}
        isCollapsed={filtersCollapse}
        customInputs={() => (
          <>
            <TextInput
              label="Código"
              placeholder="digite o cod."
              mb={16}
              mr={8}
              type="text"
              name="code"
              {...filterForm.getInputProps('code')}
            />
            <Select
              clearable
              searchable
              name="status"
              label="Status"
              placeholder="selecione o status"
              data={paymentSlipStatusList?.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
              mb={16}
              mr={8}
              {...filterForm.getInputProps('status')}
            />
          </>
        )}
      />

      <DataTable
        minHeight={500}
        noRecordsText="Sem comprovantes"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={10}
        recordsPerPage={10}
        onPageChange={(page) => handleChangePage(page)}
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 100, 200]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        fetching={loading}
        records={items ?? []}
        columns={[
          {
            accessor: 'code',
            title: 'Cod.',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={paymentSlipStatusColor[status]} mb={6}>
                {paymentSlipStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'team.name',
            title: 'Equipe',
          },
          {
            accessor: 'createdAt',
            title: 'Data de Criação',
            render: ({ createdAt }) => formatDateTime(createdAt),
          },
          {
            accessor: 'menu',
            title: '',
            render: (paymetSlipGroup) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    icon={<IconEye size={14} />}
                    onClick={() => {
                      handleGetPaymentSlipGroup(paymetSlipGroup);
                      setPageModalVisible('view');
                    }}
                  >
                    Ver
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconFileSearch size={14} />}
                    onClick={() => {
                      handleGetPaymentSlipGroup(paymetSlipGroup);
                      setPageModalVisible('review');
                    }}
                    disabled={[
                      PaymentSlipApprovalStatus.APPROVED,
                      PaymentSlipApprovalStatus.PENDING,
                      PaymentSlipApprovalStatus.CANCELLED,
                    ].includes(paymetSlipGroup.status)}
                  >
                    Revisar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
