import { Loader } from '@mantine/core';
import { Overlay } from './styles';

interface CustomLoaderProps {
  loading: boolean;
}

export function CustomLoader({ loading }: CustomLoaderProps) {
  if (!loading) return null;

  return (
    <Overlay>
      <Loader color="orange" />
    </Overlay>
  );
}
